import React from 'react';
// icons
import {
  StepBackwardOutlined,
  StepForwardOutlined,
  FastBackwardOutlined,
  FastForwardOutlined,
  ShrinkOutlined,
  ArrowsAltOutlined,
  DownOutlined,
  UpOutlined,
  LeftOutlined,
  RightOutlined,
  CaretUpOutlined,
  CaretDownOutlined,
  CaretLeftOutlined,
  CaretRightOutlined,
  UpCircleOutlined,
  DownCircleOutlined,
  LeftCircleOutlined,
  RightCircleOutlined,
  DoubleRightOutlined,
  DoubleLeftOutlined,
  VerticalLeftOutlined,
  VerticalRightOutlined,
  VerticalAlignTopOutlined,
  VerticalAlignMiddleOutlined,
  VerticalAlignBottomOutlined,
  ForwardOutlined,
  BackwardOutlined,
  RollbackOutlined,
  EnterOutlined,
  RetweetOutlined,
  SwapOutlined,
  SwapLeftOutlined,
  SwapRightOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined,
  ArrowLeftOutlined,
  ArrowRightOutlined,
  PlayCircleOutlined,
  UpSquareOutlined,
  DownSquareOutlined,
  LeftSquareOutlined,
  RightSquareOutlined,
  LoginOutlined,
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  BorderBottomOutlined,
  BorderHorizontalOutlined,
  BorderInnerOutlined,
  BorderOuterOutlined,
  BorderLeftOutlined,
  BorderRightOutlined,
  BorderTopOutlined,
  BorderVerticleOutlined,
  PicCenterOutlined,
  PicLeftOutlined,
  PicRightOutlined,
  RadiusBottomleftOutlined,
  RadiusBottomrightOutlined,
  RadiusUpleftOutlined,
  RadiusUprightOutlined,
  FullscreenOutlined,
  FullscreenExitOutlined,
  QuestionOutlined,
  QuestionCircleOutlined,
  PlusOutlined,
  PlusCircleOutlined,
  PauseOutlined,
  PauseCircleOutlined,
  MinusOutlined,
  MinusCircleOutlined,
  PlusSquareOutlined,
  MinusSquareOutlined,
  InfoOutlined,
  InfoCircleOutlined,
  ExclamationOutlined,
  ExclamationCircleOutlined,
  CloseOutlined,
  CloseCircleOutlined,
  CloseSquareOutlined,
  CheckOutlined,
  CheckCircleOutlined,
  CheckSquareOutlined,
  ClockCircleOutlined,
  WarningOutlined,
  IssuesCloseOutlined,
  StopOutlined,
  EditOutlined,
  FormOutlined,
  CopyOutlined,
  ScissorOutlined,
  DeleteOutlined,
  SnippetsOutlined,
  DiffOutlined,
  HighlightOutlined,
  AlignCenterOutlined,
  AlignLeftOutlined,
  AlignRightOutlined,
  BgColorsOutlined,
  BoldOutlined,
  ItalicOutlined,
  UnderlineOutlined,
  StrikethroughOutlined,
  RedoOutlined,
  UndoOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
  FontColorsOutlined,
  FontSizeOutlined,
  LineHeightOutlined,
  DashOutlined,
  SmallDashOutlined,
  SortAscendingOutlined,
  SortDescendingOutlined,
  DragOutlined,
  OrderedListOutlined,
  UnorderedListOutlined,
  RadiusSettingOutlined,
  ColumnWidthOutlined,
  ColumnHeightOutlined,
  AreaChartOutlined,
  PieChartOutlined,
  BarChartOutlined,
  DotChartOutlined,
  LineChartOutlined,
  RadarChartOutlined,
  HeatMapOutlined,
  FallOutlined,
  RiseOutlined,
  StockOutlined,
  BoxPlotOutlined,
  FundOutlined,
  SlidersOutlined,
  AndroidOutlined,
  AppleOutlined,
  WindowsOutlined,
  IeOutlined,
  ChromeOutlined,
  GithubOutlined,
  AliwangwangOutlined,
  DingdingOutlined,
  WeiboSquareOutlined,
  WeiboCircleOutlined,
  TaobaoCircleOutlined,
  Html5Outlined,
  WeiboOutlined,
  TwitterOutlined,
  WechatOutlined,
  YoutubeOutlined,
  AlipayCircleOutlined,
  TaobaoOutlined,
  SkypeOutlined,
  QqOutlined,
  MediumWorkmarkOutlined,
  GitlabOutlined,
  MediumOutlined,
  LinkedinOutlined,
  GooglePlusOutlined,
  DropboxOutlined,
  FacebookOutlined,
  CodepenOutlined,
  CodeSandboxOutlined,
  AmazonOutlined,
  GoogleOutlined,
  CodepenCircleOutlined,
  AlipayOutlined,
  AntDesignOutlined,
  AntCloudOutlined,
  AliyunOutlined,
  ZhihuOutlined,
  SlackOutlined,
  SlackSquareOutlined,
  BehanceOutlined,
  BehanceSquareOutlined,
  DribbbleOutlined,
  DribbbleSquareOutlined,
  InstagramOutlined,
  YuqueOutlined,
  AlibabaOutlined,
  YahooOutlined,
  RedditOutlined,
  SketchOutlined,
  AccountBookOutlined,
  AimOutlined,
  AlertOutlined,
  ApartmentOutlined,
  ApiOutlined,
  AppstoreAddOutlined,
  AppstoreOutlined,
  AudioOutlined,
  AudioMutedOutlined,
  AuditOutlined,
  BankOutlined,
  BarcodeOutlined,
  BarsOutlined,
  BellOutlined,
  BlockOutlined,
  BookOutlined,
  BorderOutlined,
  BorderlessTableOutlined,
  BranchesOutlined,
  BugOutlined,
  BuildOutlined,
  BulbOutlined,
  CalculatorOutlined,
  CalendarOutlined,
  CameraOutlined,
  CarOutlined,
  CarryOutOutlined,
  CiCircleOutlined,
  CiOutlined,
  ClearOutlined,
  CloudDownloadOutlined,
  CloudOutlined,
  CloudServerOutlined,
  CloudSyncOutlined,
  CloudUploadOutlined,
  ClusterOutlined,
  CodeOutlined,
  CoffeeOutlined,
  CommentOutlined,
  CompassOutlined,
  CompressOutlined,
  ConsoleSqlOutlined,
  ContactsOutlined,
  ContainerOutlined,
  ControlOutlined,
  CopyrightOutlined,
  CreditCardOutlined,
  CrownOutlined,
  CustomerServiceOutlined,
  DashboardOutlined,
  DatabaseOutlined,
  DeleteColumnOutlined,
  DeleteRowOutlined,
  DeliveredProcedureOutlined,
  DeploymentUnitOutlined,
  DesktopOutlined,
  DingtalkOutlined,
  DisconnectOutlined,
  DislikeOutlined,
  DollarCircleOutlined,
  DollarOutlined,
  DownloadOutlined,
  EllipsisOutlined,
  EnvironmentOutlined,
  EuroCircleOutlined,
  EuroOutlined,
  ExceptionOutlined,
  ExpandAltOutlined,
  ExpandOutlined,
  ExperimentOutlined,
  ExportOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
  FieldBinaryOutlined,
  FieldNumberOutlined,
  FieldStringOutlined,
  FieldTimeOutlined,
  FileAddOutlined,
  FileDoneOutlined,
  FileExcelOutlined,
  FileExclamationOutlined,
  FileOutlined,
  FileGifOutlined,
  FileImageOutlined,
  FileJpgOutlined,
  FileMarkdownOutlined,
  FilePdfOutlined,
  FilePptOutlined,
  FileProtectOutlined,
  FileSearchOutlined,
  FileSyncOutlined,
  FileTextOutlined,
  FileUnknownOutlined,
  FileWordOutlined,
  FileZipOutlined,
  FilterOutlined,
  FireOutlined,
  FlagOutlined,
  FolderAddOutlined,
  FolderOutlined,
  FolderOpenOutlined,
  FolderViewOutlined,
  ForkOutlined,
  FormatPainterOutlined,
  FrownOutlined,
  FunctionOutlined,
  FundProjectionScreenOutlined,
  FundViewOutlined,
  FunnelPlotOutlined,
  GatewayOutlined,
  GifOutlined,
  GiftOutlined,
  GlobalOutlined,
  GoldOutlined,
  GroupOutlined,
  HddOutlined,
  HeartOutlined,
  HistoryOutlined,
  HomeOutlined,
  HourglassOutlined,
  IdcardOutlined,
  ImportOutlined,
  InboxOutlined,
  InsertRowAboveOutlined,
  InsertRowBelowOutlined,
  InsertRowLeftOutlined,
  InsertRowRightOutlined,
  InsuranceOutlined,
  InteractionOutlined,
  KeyOutlined,
  LaptopOutlined,
  LayoutOutlined,
  LikeOutlined,
  LineOutlined,
  LinkOutlined,
  Loading3QuartersOutlined,
  LoadingOutlined,
  LockOutlined,
  MacCommandOutlined,
  MailOutlined,
  ManOutlined,
  MedicineBoxOutlined,
  MehOutlined,
  MenuOutlined,
  MergeCellsOutlined,
  MessageOutlined,
  MobileOutlined,
  MoneyCollectOutlined,
  MonitorOutlined,
  MoreOutlined,
  NodeCollapseOutlined,
  NodeExpandOutlined,
  NodeIndexOutlined,
  NotificationOutlined,
  NumberOutlined,
  OneToOneOutlined,
  PaperClipOutlined,
  PartitionOutlined,
  PayCircleOutlined,
  PercentageOutlined,
  PhoneOutlined,
  PictureOutlined,
  PlaySquareOutlined,
  PoundCircleOutlined,
  PoundOutlined,
  PoweroffOutlined,
  PrinterOutlined,
  ProfileOutlined,
  ProjectOutlined,
  PropertySafetyOutlined,
  PullRequestOutlined,
  PushpinOutlined,
  QrcodeOutlined,
  ReadOutlined,
  ReconciliationOutlined,
  RedEnvelopeOutlined,
  ReloadOutlined,
  RestOutlined,
  RobotOutlined,
  RocketOutlined,
  RotateLeftOutlined,
  RotateRightOutlined,
  SafetyCertificateOutlined,
  SafetyOutlined,
  SaveOutlined,
  ScanOutlined,
  ScheduleOutlined,
  SearchOutlined,
  SecurityScanOutlined,
  SelectOutlined,
  SendOutlined,
  SettingOutlined,
  ShakeOutlined,
  ShareAltOutlined,
  ShopOutlined,
  ShoppingCartOutlined,
  ShoppingOutlined,
  SisternodeOutlined,
  SkinOutlined,
  SmileOutlined,
  SolutionOutlined,
  SoundOutlined,
  SplitCellsOutlined,
  StarOutlined,
  SubnodeOutlined,
  SwitcherOutlined,
  SyncOutlined,
  TableOutlined,
  TabletOutlined,
  TagOutlined,
  TagsOutlined,
  TeamOutlined,
  ThunderboltOutlined,
  ToTopOutlined,
  ToolOutlined,
  TrademarkCircleOutlined,
  TrademarkOutlined,
  TransactionOutlined,
  TranslationOutlined,
  TrophyOutlined,
  UngroupOutlined,
  UnlockOutlined,
  UploadOutlined,
  UsbOutlined,
  UserAddOutlined,
  UserDeleteOutlined,
  UserOutlined,
  UserSwitchOutlined,
  UsergroupAddOutlined,
  UsergroupDeleteOutlined,
  VerifiedOutlined,
  VideoCameraAddOutlined,
  VideoCameraOutlined,
  WalletOutlined,
  WhatsAppOutlined,
  WifiOutlined,
  WomanOutlined,
  StepBackwardFilled,
  StepForwardFilled,
  FastBackwardFilled,
  FastForwardFilled,
  CaretUpFilled,
  CaretDownFilled,
  CaretLeftFilled,
  CaretRightFilled,
  UpCircleFilled,
  DownCircleFilled,
  LeftCircleFilled,
  RightCircleFilled,
  ForwardFilled,
  BackwardFilled,
  PlayCircleFilled,
  UpSquareFilled,
  DownSquareFilled,
  LeftSquareFilled,
  RightSquareFilled,
  QuestionCircleFilled,
  PlusCircleFilled,
  PauseCircleFilled,
  MinusCircleFilled,
  PlusSquareFilled,
  MinusSquareFilled,
  InfoCircleFilled,
  ExclamationCircleFilled,
  CloseCircleFilled,
  CloseSquareFilled,
  CheckCircleFilled,
  CheckSquareFilled,
  ClockCircleFilled,
  WarningFilled,
  StopFilled,
  EditFilled,
  CopyFilled,
  DeleteFilled,
  SnippetsFilled,
  DiffFilled,
  HighlightFilled,
  PieChartFilled,
  BoxPlotFilled,
  FundFilled,
  SlidersFilled,
  AndroidFilled,
  AppleFilled,
  WindowsFilled,
  ChromeFilled,
  GithubFilled,
  AliwangwangFilled,
  WeiboSquareFilled,
  WeiboCircleFilled,
  TaobaoCircleFilled,
  Html5Filled,
  WechatFilled,
  YoutubeFilled,
  AlipayCircleFilled,
  SkypeFilled,
  GitlabFilled,
  LinkedinFilled,
  FacebookFilled,
  CodeSandboxCircleFilled,
  CodepenCircleFilled,
  SlackSquareFilled,
  BehanceSquareFilled,
  DribbbleSquareFilled,
  InstagramFilled,
  YuqueFilled,
  YahooFilled,
  AccountBookFilled,
  AlertFilled,
  AlipaySquareFilled,
  AmazonCircleFilled,
  AmazonSquareFilled,
  ApiFilled,
  AppstoreFilled,
  AudioFilled,
  BankFilled,
  BehanceCircleFilled,
  BellFilled,
  BookFilled,
  BugFilled,
  BuildFilled,
  BulbFilled,
  CalculatorFilled,
  CalendarFilled,
  CameraFilled,
  CarFilled,
  CarryOutFilled,
  CiCircleFilled,
  CloudFilled,
  CodeFilled,
  CodeSandboxSquareFilled,
  CodepenSquareFilled,
  CompassFilled,
  ContactsFilled,
  ContainerFilled,
  ControlFilled,
  CreditCardFilled,
  CrownFilled,
  CustomerServiceFilled,
  DashboardFilled,
  DatabaseFilled,
  DingtalkCircleFilled,
  DingtalkSquareFilled,
  DislikeFilled,
  DollarCircleFilled,
  DribbbleCircleFilled,
  DropboxCircleFilled,
  DropboxSquareFilled,
  EnvironmentFilled,
  EuroCircleFilled,
  ExperimentFilled,
  EyeFilled,
  EyeInvisibleFilled,
  FileAddFilled,
  FileExcelFilled,
  FileExclamationFilled,
  FileFilled,
  FileImageFilled,
  FileMarkdownFilled,
  FilePdfFilled,
  FilePptFilled,
  FileTextFilled,
  FileUnknownFilled,
  FileWordFilled,
  FileZipFilled,
  FilterFilled,
  FireFilled,
  FlagFilled,
  FolderAddFilled,
  FolderFilled,
  FolderOpenFilled,
  FormatPainterFilled,
  FrownFilled,
  FunnelPlotFilled,
  GiftFilled,
  GoldFilled,
  GoldenFilled,
  GoogleCircleFilled,
  GooglePlusCircleFilled,
  GooglePlusSquareFilled,
  GoogleSquareFilled,
  HddFilled,
  HeartFilled,
  HomeFilled,
  HourglassFilled,
  IdcardFilled,
  IeCircleFilled,
  IeSquareFilled,
  InsuranceFilled,
  InteractionFilled,
  LayoutFilled,
  LikeFilled,
  LockFilled,
  MacCommandFilled,
  MailFilled,
  MedicineBoxFilled,
  MediumCircleFilled,
  MediumSquareFilled,
  MehFilled,
  MessageFilled,
  MobileFilled,
  MoneyCollectFilled,
  NotificationFilled,
  PayCircleFilled,
  PhoneFilled,
  PictureFilled,
  PlaySquareFilled,
  PoundCircleFilled,
  PrinterFilled,
  ProfileFilled,
  ProjectFilled,
  PropertySafetyFilled,
  PushpinFilled,
  QqCircleFilled,
  QqSquareFilled,
  ReadFilled,
  ReconciliationFilled,
  RedEnvelopeFilled,
  RedditCircleFilled,
  RedditSquareFilled,
  RestFilled,
  RobotFilled,
  RocketFilled,
  SafetyCertificateFilled,
  SaveFilled,
  ScheduleFilled,
  SecurityScanFilled,
  SettingFilled,
  ShopFilled,
  ShoppingFilled,
  SignalFilled,
  SketchCircleFilled,
  SketchSquareFilled,
  SkinFilled,
  SlackCircleFilled,
  SmileFilled,
  SoundFilled,
  StarFilled,
  SwitcherFilled,
  TabletFilled,
  TagFilled,
  TagsFilled,
  TaobaoSquareFilled,
  ThunderboltFilled,
  ToolFilled,
  TrademarkCircleFilled,
  TrophyFilled,
  TwitterCircleFilled,
  TwitterSquareFilled,
  UnlockFilled,
  UsbFilled,
  VideoCameraFilled,
  WalletFilled,
  ZhihuCircleFilled,
  ZhihuSquareFilled,
} from '@ant-design/icons';

import moment from 'moment';
import validator from 'validator';
import { extendMoment } from 'moment-range';
import { rem } from 'polished';
import { Link } from 'react-router-dom';
import API from '../API';
import { RouteLinkWraper, IFrame } from '../Components/ReusableComponents';

const Moment = extendMoment(moment);

export const FORMAT_HUMANE_DATETIME = 'DD.MM.YYYY HH:mm';
export const FORMAT_HUMANE_DATE = 'DD.MM.YYYY';
export const FORMAT_HUMANE_TIME = 'HH.mm';
export const SK_PHONE_PREFIX = '+421';
export const CZ_PHONE_PREFIX = '+420';
export const LOCALE = ['en-EN', 'sk-SK', 'cs-CZ'];

// Translation text to DB
async function __textAddToBD(text) {
  console.log('-----++ fetchLayoutTranslationData');
  console.log(text);
  //
  if (typeof text === 'string' && text !== '') {
    const layoutTranslationData = await API.getPortalLayoutTranslationAction(
      '60747711fdef8aa80942f157',
    );
    console.log('-----++ fetchLayoutTranslationData');
    console.log(text);
    console.log(layoutTranslationData);

    if (
      layoutTranslationData.app_settings.types[0].translate.find(
        d => d.valueDefault === text,
      )
    ) {
      // exist
    } else {
      // push text
      layoutTranslationData.app_settings.types[0].translate.push({
        value: '',
        valueDefault: text,
      });
      console.log('-----++ fetchLayoutTranslationData -in');
      const newData = {
        // name: contentName,
        app_settings: layoutTranslationData.app_settings,
      };
      // Put to app_settings
      await API.putPortalLayoutTranslationAction('60747711fdef8aa80942f157', {
        data: newData,
      });
    }
  }
}

// export const __ = text => text;
export const __ = text => {
  //
  if (typeof text === 'string' && text !== '') {
    // __textAddToBD(text);
  }

  return text;
};

export const formatPhoneNumber = (phone, appLang) => {
  if (phone.startsWith(0)) {
    const c = phone.split(' ').join('');
    const formatedPhone = c.replace(/(\d{1})(\d{3})(\d{3})(\d{3})/, '$2 $3 $4');
    switch (appLang) {
      case 'EN':
        return `${SK_PHONE_PREFIX} ${formatedPhone}`;
      case 'SK':
        return `${SK_PHONE_PREFIX} ${formatedPhone}`;
      case 'CZ':
        return `${CZ_PHONE_PREFIX} ${formatedPhone}`;
      default:
        return phone;
    }
  }
  return phone;
};

export const parseHash = hash => {
  let content = hash;
  if (hash.indexOf('#') >= 0) {
    content = hash.substring(hash.indexOf('#') + 1);
  }
  const result = content.split('&').reduce((acum, item) => {
    const [key, value] = item.split('=');
    const r = Object.assign({}, acum);
    r[key] = value;
    return r;
  }, {});

  return result;
};

export const formatNumber = (n, c = 2, d = ',', t = ' ') => {
  const s = n < 0 ? '-' : '';
  const b = Math.abs(+n || 0);
  const i = String(parseInt(b.toFixed(c), 10));
  let j = i.length;
  j = j > 3 ? j % 3 : 0;

  return (
    s +
    (j ? i.substr(0, j) + t : '') +
    i.substr(j).replace(/(\d{3})(?=\d)/g, `$1${t}`) +
    (c
      ? d +
        Math.abs(b - i)
          .toFixed(c)
          .slice(2)
      : '')
  );
};

export const formatPrice = (
  { amount, currency = 'EUR' },
  c = 2,
  d = ',',
  t = '\u00a0',
) => {
  if (currency === 'EUR') {
    return `${formatNumber(amount, c, d, t, c)}\u00a0€`;
  }
  return `${formatNumber(amount, c, d, t, c)}\u00a0${currency}`;
};

export const redirect = redirectUrl => {
  window.location.href = redirectUrl;
};

export const handlePriceForTable = priceData => {
  if (!priceData) {
    return null;
  }
  return formatPrice(priceData);
};

export const formatDate = (date, format = FORMAT_HUMANE_DATE) => {
  return moment.isMoment(date) ? moment(date).format(format) : '';
};

export const getMonthByNumber = number => {
  switch (number) {
    case 1:
      return __('Január');
    case 2:
      return __('Február');
    case 3:
      return __('Marec');
    case 4:
      return __('Apríl');
    case 5:
      return __('Máj');
    case 6:
      return __('Jún');
    case 7:
      return __('Júl');
    case 8:
      return __('August');
    case 9:
      return __('September');
    case 10:
      return __('Október');
    case 11:
      return __('November');
    case 12:
      return __('December');
    default:
      return number;
  }
};

export const toIsoDate = s => {
  return moment(s, FORMAT_HUMANE_DATE).toISOString();
};

export const renderLink = (to, name, noMargin = true, fontSize = rem(11)) => {
  return (
    <RouteLinkWraper fontSize={fontSize} noMargin={noMargin}>
      <Link to={to}>{name}</Link>
    </RouteLinkWraper>
  );
};

export const defaultCustomertype = values => {
  if (values && values.length) {
    return `${values[0].type} - ${values[0].subtype}`;
  }
  return '';
};

export const formatStatus = value => {
  switch (value) {
    case 'active':
      return __('Active');
    case 'inactive':
      return __('Inactive');
    default:
      return value;
  }
};

export const normalizeAddressData = data => {
  if (!data) {
    return [];
  }

  const address = [
    {
      name: __('Country'),
      value: data.country,
    },
    {
      name: __('City'),
      value: data.city,
    },
    {
      name: __('Street'),
      value: data.street,
    },
    {
      name: __('Postal code'),
      value: data.postal_code,
    },
  ];
  return address;
};

export const getAddressLabel = data => {
  if (data) {
    return `${data.street && `${data.street}, `}${data.postal_code &&
      data.postal_code}`;
  }
  return '';
};

export const formatImportance = importance => {
  switch (importance) {
    case 'low':
      return __('Nízka');
    case 'normal':
      return __('Normálna');
    case 'high':
      return __('Vysoká');
    default:
      return importance;
  }
};

export const getTokenFromProps = props => {
  if (props.match.params.token) {
    return props.match.params.token;
  }
  return null;
};

export const translate = (lang, translations, defaultValue) => {
  if (
    translations &&
    translations.find(t => t.lang === lang) &&
    translations.find(t => t.lang === lang).value.length > 0
  ) {
    return translations.find(t => t.lang === lang).value;
  }
  return defaultValue;
};

export const text2ascii = text => {
  return text
    .replace(/[áàãâä]/gi, 'a')
    .replace(/[čç]/gi, 'c')
    .replace(/[ď]/gi, 'd')
    .replace(/[éèê]/gi, 'e')
    .replace(/[íìïî]/gi, 'i')
    .replace(/[ľĺ]/gi, 'l')
    .replace(/[ñň]/gi, 'n')
    .replace(/[óòöôõ]/gi, 'o')
    .replace(/[ř]/gi, 'r')
    .replace(/[š]/gi, 's')
    .replace(/[ť]/gi, 't')
    .replace(/[úùüûů]/gi, 'u')
    .replace(/[ý]/gi, 'y')
    .replace(/[ž]/gi, 'z');
};

export function slugify(text) {
  return text2ascii(text)
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^\w-]+/g, '') // Remove all non-word chars
    .replace(/--+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, '');
}

// Remove Html tags from string
export const stripHtmlTags = text => {
  return text.replace(/(<([^>]+)>)/gi, '');
};

//
// ---
//

export function hexToRgb(hex) {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex.replace(shorthandRegex, (m, r, g, b) => {
    return r + r + g + g + b + b;
  });

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

//
// ---
//

export function layoutBuilderStyleIcons(key, props = {}) {
  //
  // icons
  //
  const iconsAntd = [
    { key: 'StepBackwardOutlined', value: <StepBackwardOutlined {...props} /> },
    { key: 'StepForwardOutlined', value: <StepForwardOutlined {...props} /> },
    { key: 'FastBackwardOutlined', value: <FastBackwardOutlined {...props} /> },
    { key: 'FastForwardOutlined', value: <FastForwardOutlined {...props} /> },
    { key: 'ShrinkOutlined', value: <ShrinkOutlined {...props} /> },
    { key: 'ArrowsAltOutlined', value: <ArrowsAltOutlined {...props} /> },
    { key: 'DownOutlined', value: <DownOutlined {...props} /> },
    { key: 'UpOutlined', value: <UpOutlined {...props} /> },
    { key: 'LeftOutlined', value: <LeftOutlined {...props} /> },
    { key: 'RightOutlined', value: <RightOutlined {...props} /> },
    { key: 'CaretUpOutlined', value: <CaretUpOutlined {...props} /> },
    { key: 'CaretDownOutlined', value: <CaretDownOutlined {...props} /> },
    { key: 'CaretLeftOutlined', value: <CaretLeftOutlined {...props} /> },
    { key: 'CaretRightOutlined', value: <CaretRightOutlined {...props} /> },
    { key: 'UpCircleOutlined', value: <UpCircleOutlined {...props} /> },
    { key: 'DownCircleOutlined', value: <DownCircleOutlined {...props} /> },
    { key: 'LeftCircleOutlined', value: <LeftCircleOutlined {...props} /> },
    { key: 'RightCircleOutlined', value: <RightCircleOutlined {...props} /> },
    { key: 'DoubleRightOutlined', value: <DoubleRightOutlined {...props} /> },
    { key: 'DoubleLeftOutlined', value: <DoubleLeftOutlined {...props} /> },
    { key: 'VerticalLeftOutlined', value: <VerticalLeftOutlined {...props} /> },
    {
      key: 'VerticalRightOutlined',
      value: <VerticalRightOutlined {...props} />,
    },
    {
      key: 'VerticalAlignTopOutlined',
      value: <VerticalAlignTopOutlined {...props} />,
    },
    {
      key: 'VerticalAlignMiddleOutlined',
      value: <VerticalAlignMiddleOutlined {...props} />,
    },
    {
      key: 'VerticalAlignBottomOutlined',
      value: <VerticalAlignBottomOutlined {...props} />,
    },
    { key: 'ForwardOutlined', value: <ForwardOutlined {...props} /> },
    { key: 'BackwardOutlined', value: <BackwardOutlined {...props} /> },
    { key: 'RollbackOutlined', value: <RollbackOutlined {...props} /> },
    { key: 'EnterOutlined', value: <EnterOutlined {...props} /> },
    { key: 'RetweetOutlined', value: <RetweetOutlined {...props} /> },
    { key: 'SwapOutlined', value: <SwapOutlined {...props} /> },
    { key: 'SwapLeftOutlined', value: <SwapLeftOutlined {...props} /> },
    { key: 'SwapRightOutlined', value: <SwapRightOutlined {...props} /> },
    { key: 'ArrowUpOutlined', value: <ArrowUpOutlined {...props} /> },
    { key: 'ArrowDownOutlined', value: <ArrowDownOutlined {...props} /> },
    { key: 'ArrowLeftOutlined', value: <ArrowLeftOutlined {...props} /> },
    { key: 'ArrowRightOutlined', value: <ArrowRightOutlined {...props} /> },
    { key: 'PlayCircleOutlined', value: <PlayCircleOutlined {...props} /> },
    { key: 'UpSquareOutlined', value: <UpSquareOutlined {...props} /> },
    { key: 'DownSquareOutlined', value: <DownSquareOutlined {...props} /> },
    { key: 'LeftSquareOutlined', value: <LeftSquareOutlined {...props} /> },
    { key: 'RightSquareOutlined', value: <RightSquareOutlined {...props} /> },
    { key: 'LoginOutlined', value: <LoginOutlined {...props} /> },
    { key: 'LogoutOutlined', value: <LogoutOutlined {...props} /> },
    { key: 'MenuFoldOutlined', value: <MenuFoldOutlined {...props} /> },
    { key: 'MenuUnfoldOutlined', value: <MenuUnfoldOutlined {...props} /> },
    { key: 'BorderBottomOutlined', value: <BorderBottomOutlined {...props} /> },
    {
      key: 'BorderHorizontalOutlined',
      value: <BorderHorizontalOutlined {...props} />,
    },
    { key: 'BorderInnerOutlined', value: <BorderInnerOutlined {...props} /> },
    { key: 'BorderOuterOutlined', value: <BorderOuterOutlined {...props} /> },
    { key: 'BorderLeftOutlined', value: <BorderLeftOutlined {...props} /> },
    { key: 'BorderRightOutlined', value: <BorderRightOutlined {...props} /> },
    { key: 'BorderTopOutlined', value: <BorderTopOutlined {...props} /> },
    {
      key: 'BorderVerticleOutlined',
      value: <BorderVerticleOutlined {...props} />,
    },
    { key: 'PicCenterOutlined', value: <PicCenterOutlined {...props} /> },
    { key: 'PicLeftOutlined', value: <PicLeftOutlined {...props} /> },
    { key: 'PicRightOutlined', value: <PicRightOutlined {...props} /> },
    {
      key: 'RadiusBottomleftOutlined',
      value: <RadiusBottomleftOutlined {...props} />,
    },
    {
      key: 'RadiusBottomrightOutlined',
      value: <RadiusBottomrightOutlined {...props} />,
    },
    { key: 'RadiusUpleftOutlined', value: <RadiusUpleftOutlined {...props} /> },
    {
      key: 'RadiusUprightOutlined',
      value: <RadiusUprightOutlined {...props} />,
    },
    { key: 'FullscreenOutlined', value: <FullscreenOutlined {...props} /> },
    {
      key: 'FullscreenExitOutlined',
      value: <FullscreenExitOutlined {...props} />,
    },
    { key: 'QuestionOutlined', value: <QuestionOutlined {...props} /> },
    {
      key: 'QuestionCircleOutlined',
      value: <QuestionCircleOutlined {...props} />,
    },
    { key: 'PlusOutlined', value: <PlusOutlined {...props} /> },
    { key: 'PlusCircleOutlined', value: <PlusCircleOutlined {...props} /> },
    { key: 'PauseOutlined', value: <PauseOutlined {...props} /> },
    { key: 'PauseCircleOutlined', value: <PauseCircleOutlined {...props} /> },
    { key: 'MinusOutlined', value: <MinusOutlined {...props} /> },
    { key: 'MinusCircleOutlined', value: <MinusCircleOutlined {...props} /> },
    { key: 'PlusSquareOutlined', value: <PlusSquareOutlined {...props} /> },
    { key: 'MinusSquareOutlined', value: <MinusSquareOutlined {...props} /> },
    { key: 'InfoOutlined', value: <InfoOutlined {...props} /> },
    { key: 'InfoCircleOutlined', value: <InfoCircleOutlined {...props} /> },
    { key: 'ExclamationOutlined', value: <ExclamationOutlined {...props} /> },
    {
      key: 'ExclamationCircleOutlined',
      value: <ExclamationCircleOutlined {...props} />,
    },
    { key: 'CloseOutlined', value: <CloseOutlined {...props} /> },
    { key: 'CloseCircleOutlined', value: <CloseCircleOutlined {...props} /> },
    { key: 'CloseSquareOutlined', value: <CloseSquareOutlined {...props} /> },
    { key: 'CheckOutlined', value: <CheckOutlined {...props} /> },
    { key: 'CheckCircleOutlined', value: <CheckCircleOutlined {...props} /> },
    { key: 'CheckSquareOutlined', value: <CheckSquareOutlined {...props} /> },
    { key: 'ClockCircleOutlined', value: <ClockCircleOutlined {...props} /> },
    { key: 'WarningOutlined', value: <WarningOutlined {...props} /> },
    { key: 'IssuesCloseOutlined', value: <IssuesCloseOutlined {...props} /> },
    { key: 'StopOutlined', value: <StopOutlined {...props} /> },
    { key: 'EditOutlined', value: <EditOutlined {...props} /> },
    { key: 'FormOutlined', value: <FormOutlined {...props} /> },
    { key: 'CopyOutlined', value: <CopyOutlined {...props} /> },
    { key: 'ScissorOutlined', value: <ScissorOutlined {...props} /> },
    { key: 'DeleteOutlined', value: <DeleteOutlined {...props} /> },
    { key: 'SnippetsOutlined', value: <SnippetsOutlined {...props} /> },
    { key: 'DiffOutlined', value: <DiffOutlined {...props} /> },
    { key: 'HighlightOutlined', value: <HighlightOutlined {...props} /> },
    { key: 'AlignCenterOutlined', value: <AlignCenterOutlined {...props} /> },
    { key: 'AlignLeftOutlined', value: <AlignLeftOutlined {...props} /> },
    { key: 'AlignRightOutlined', value: <AlignRightOutlined {...props} /> },
    { key: 'BgColorsOutlined', value: <BgColorsOutlined {...props} /> },
    { key: 'BoldOutlined', value: <BoldOutlined {...props} /> },
    { key: 'ItalicOutlined', value: <ItalicOutlined {...props} /> },
    { key: 'UnderlineOutlined', value: <UnderlineOutlined {...props} /> },
    {
      key: 'StrikethroughOutlined',
      value: <StrikethroughOutlined {...props} />,
    },
    { key: 'RedoOutlined', value: <RedoOutlined {...props} /> },
    { key: 'UndoOutlined', value: <UndoOutlined {...props} /> },
    { key: 'ZoomInOutlined', value: <ZoomInOutlined {...props} /> },
    { key: 'ZoomOutOutlined', value: <ZoomOutOutlined {...props} /> },
    { key: 'FontColorsOutlined', value: <FontColorsOutlined {...props} /> },
    { key: 'FontSizeOutlined', value: <FontSizeOutlined {...props} /> },
    { key: 'LineHeightOutlined', value: <LineHeightOutlined {...props} /> },
    { key: 'DashOutlined', value: <DashOutlined {...props} /> },
    { key: 'SmallDashOutlined', value: <SmallDashOutlined {...props} /> },
    {
      key: 'SortAscendingOutlined',
      value: <SortAscendingOutlined {...props} />,
    },
    {
      key: 'SortDescendingOutlined',
      value: <SortDescendingOutlined {...props} />,
    },
    { key: 'DragOutlined', value: <DragOutlined {...props} /> },
    { key: 'OrderedListOutlined', value: <OrderedListOutlined {...props} /> },
    {
      key: 'UnorderedListOutlined',
      value: <UnorderedListOutlined {...props} />,
    },
    {
      key: 'RadiusSettingOutlined',
      value: <RadiusSettingOutlined {...props} />,
    },
    { key: 'ColumnWidthOutlined', value: <ColumnWidthOutlined {...props} /> },
    { key: 'ColumnHeightOutlined', value: <ColumnHeightOutlined {...props} /> },
    { key: 'AreaChartOutlined', value: <AreaChartOutlined {...props} /> },
    { key: 'PieChartOutlined', value: <PieChartOutlined {...props} /> },
    { key: 'BarChartOutlined', value: <BarChartOutlined {...props} /> },
    { key: 'DotChartOutlined', value: <DotChartOutlined {...props} /> },
    { key: 'LineChartOutlined', value: <LineChartOutlined {...props} /> },
    { key: 'RadarChartOutlined', value: <RadarChartOutlined {...props} /> },
    { key: 'HeatMapOutlined', value: <HeatMapOutlined {...props} /> },
    { key: 'FallOutlined', value: <FallOutlined {...props} /> },
    { key: 'RiseOutlined', value: <RiseOutlined {...props} /> },
    { key: 'StockOutlined', value: <StockOutlined {...props} /> },
    { key: 'BoxPlotOutlined', value: <BoxPlotOutlined {...props} /> },
    { key: 'FundOutlined', value: <FundOutlined {...props} /> },
    { key: 'SlidersOutlined', value: <SlidersOutlined {...props} /> },
    { key: 'AndroidOutlined', value: <AndroidOutlined {...props} /> },
    { key: 'AppleOutlined', value: <AppleOutlined {...props} /> },
    { key: 'WindowsOutlined', value: <WindowsOutlined {...props} /> },
    { key: 'IeOutlined', value: <IeOutlined {...props} /> },
    { key: 'ChromeOutlined', value: <ChromeOutlined {...props} /> },
    { key: 'GithubOutlined', value: <GithubOutlined {...props} /> },
    { key: 'AliwangwangOutlined', value: <AliwangwangOutlined {...props} /> },
    { key: 'DingdingOutlined', value: <DingdingOutlined {...props} /> },
    { key: 'WeiboSquareOutlined', value: <WeiboSquareOutlined {...props} /> },
    { key: 'WeiboCircleOutlined', value: <WeiboCircleOutlined {...props} /> },
    { key: 'TaobaoCircleOutlined', value: <TaobaoCircleOutlined {...props} /> },
    { key: 'Html5Outlined', value: <Html5Outlined {...props} /> },
    { key: 'WeiboOutlined', value: <WeiboOutlined {...props} /> },
    { key: 'TwitterOutlined', value: <TwitterOutlined {...props} /> },
    { key: 'WechatOutlined', value: <WechatOutlined {...props} /> },
    { key: 'YoutubeOutlined', value: <YoutubeOutlined {...props} /> },
    { key: 'AlipayCircleOutlined', value: <AlipayCircleOutlined {...props} /> },
    { key: 'TaobaoOutlined', value: <TaobaoOutlined {...props} /> },
    { key: 'SkypeOutlined', value: <SkypeOutlined {...props} /> },
    { key: 'QqOutlined', value: <QqOutlined {...props} /> },
    {
      key: 'MediumWorkmarkOutlined',
      value: <MediumWorkmarkOutlined {...props} />,
    },
    { key: 'GitlabOutlined', value: <GitlabOutlined {...props} /> },
    { key: 'MediumOutlined', value: <MediumOutlined {...props} /> },
    { key: 'LinkedinOutlined', value: <LinkedinOutlined {...props} /> },
    { key: 'GooglePlusOutlined', value: <GooglePlusOutlined {...props} /> },
    { key: 'DropboxOutlined', value: <DropboxOutlined {...props} /> },
    { key: 'FacebookOutlined', value: <FacebookOutlined {...props} /> },
    { key: 'CodepenOutlined', value: <CodepenOutlined {...props} /> },
    { key: 'CodeSandboxOutlined', value: <CodeSandboxOutlined {...props} /> },
    { key: 'AmazonOutlined', value: <AmazonOutlined {...props} /> },
    { key: 'GoogleOutlined', value: <GoogleOutlined {...props} /> },
    {
      key: 'CodepenCircleOutlined',
      value: <CodepenCircleOutlined {...props} />,
    },
    { key: 'AlipayOutlined', value: <AlipayOutlined {...props} /> },
    { key: 'AntDesignOutlined', value: <AntDesignOutlined {...props} /> },
    { key: 'AntCloudOutlined', value: <AntCloudOutlined {...props} /> },
    { key: 'AliyunOutlined', value: <AliyunOutlined {...props} /> },
    { key: 'ZhihuOutlined', value: <ZhihuOutlined {...props} /> },
    { key: 'SlackOutlined', value: <SlackOutlined {...props} /> },
    { key: 'SlackSquareOutlined', value: <SlackSquareOutlined {...props} /> },
    { key: 'BehanceOutlined', value: <BehanceOutlined {...props} /> },
    {
      key: 'BehanceSquareOutlined',
      value: <BehanceSquareOutlined {...props} />,
    },
    { key: 'DribbbleOutlined', value: <DribbbleOutlined {...props} /> },
    {
      key: 'DribbbleSquareOutlined',
      value: <DribbbleSquareOutlined {...props} />,
    },
    { key: 'InstagramOutlined', value: <InstagramOutlined {...props} /> },
    { key: 'YuqueOutlined', value: <YuqueOutlined {...props} /> },
    { key: 'AlibabaOutlined', value: <AlibabaOutlined {...props} /> },
    { key: 'YahooOutlined', value: <YahooOutlined {...props} /> },
    { key: 'RedditOutlined', value: <RedditOutlined {...props} /> },
    { key: 'SketchOutlined', value: <SketchOutlined {...props} /> },
    { key: 'AccountBookOutlined', value: <AccountBookOutlined {...props} /> },
    { key: 'AimOutlined', value: <AimOutlined {...props} /> },
    { key: 'AlertOutlined', value: <AlertOutlined {...props} /> },
    { key: 'ApartmentOutlined', value: <ApartmentOutlined {...props} /> },
    { key: 'ApiOutlined', value: <ApiOutlined {...props} /> },
    { key: 'AppstoreAddOutlined', value: <AppstoreAddOutlined {...props} /> },
    { key: 'AppstoreOutlined', value: <AppstoreOutlined {...props} /> },
    { key: 'AudioOutlined', value: <AudioOutlined {...props} /> },
    { key: 'AudioMutedOutlined', value: <AudioMutedOutlined {...props} /> },
    { key: 'AuditOutlined', value: <AuditOutlined {...props} /> },
    { key: 'BankOutlined', value: <BankOutlined {...props} /> },
    { key: 'BarcodeOutlined', value: <BarcodeOutlined {...props} /> },
    { key: 'BarsOutlined', value: <BarsOutlined {...props} /> },
    { key: 'BellOutlined', value: <BellOutlined {...props} /> },
    { key: 'BlockOutlined', value: <BlockOutlined {...props} /> },
    { key: 'BookOutlined', value: <BookOutlined {...props} /> },
    { key: 'BorderOutlined', value: <BorderOutlined {...props} /> },
    {
      key: 'BorderlessTableOutlined',
      value: <BorderlessTableOutlined {...props} />,
    },
    { key: 'BranchesOutlined', value: <BranchesOutlined {...props} /> },
    { key: 'BugOutlined', value: <BugOutlined {...props} /> },
    { key: 'BuildOutlined', value: <BuildOutlined {...props} /> },
    { key: 'BulbOutlined', value: <BulbOutlined {...props} /> },
    { key: 'CalculatorOutlined', value: <CalculatorOutlined {...props} /> },
    { key: 'CalendarOutlined', value: <CalendarOutlined {...props} /> },
    { key: 'CameraOutlined', value: <CameraOutlined {...props} /> },
    { key: 'CarOutlined', value: <CarOutlined {...props} /> },
    { key: 'CarryOutOutlined', value: <CarryOutOutlined {...props} /> },
    { key: 'CiCircleOutlined', value: <CiCircleOutlined {...props} /> },
    { key: 'CiOutlined', value: <CiOutlined {...props} /> },
    { key: 'ClearOutlined', value: <ClearOutlined {...props} /> },
    {
      key: 'CloudDownloadOutlined',
      value: <CloudDownloadOutlined {...props} />,
    },
    { key: 'CloudOutlined', value: <CloudOutlined {...props} /> },
    { key: 'CloudServerOutlined', value: <CloudServerOutlined {...props} /> },
    { key: 'CloudSyncOutlined', value: <CloudSyncOutlined {...props} /> },
    { key: 'CloudUploadOutlined', value: <CloudUploadOutlined {...props} /> },
    { key: 'ClusterOutlined', value: <ClusterOutlined {...props} /> },
    { key: 'CodeOutlined', value: <CodeOutlined {...props} /> },
    { key: 'CoffeeOutlined', value: <CoffeeOutlined {...props} /> },
    { key: 'CommentOutlined', value: <CommentOutlined {...props} /> },
    { key: 'CompassOutlined', value: <CompassOutlined {...props} /> },
    { key: 'CompressOutlined', value: <CompressOutlined {...props} /> },
    { key: 'ConsoleSqlOutlined', value: <ConsoleSqlOutlined {...props} /> },
    { key: 'ContactsOutlined', value: <ContactsOutlined {...props} /> },
    { key: 'ContainerOutlined', value: <ContainerOutlined {...props} /> },
    { key: 'ControlOutlined', value: <ControlOutlined {...props} /> },
    { key: 'CopyrightOutlined', value: <CopyrightOutlined {...props} /> },
    { key: 'CreditCardOutlined', value: <CreditCardOutlined {...props} /> },
    { key: 'CrownOutlined', value: <CrownOutlined {...props} /> },
    {
      key: 'CustomerServiceOutlined',
      value: <CustomerServiceOutlined {...props} />,
    },
    { key: 'DashboardOutlined', value: <DashboardOutlined {...props} /> },
    { key: 'DatabaseOutlined', value: <DatabaseOutlined {...props} /> },
    { key: 'DeleteColumnOutlined', value: <DeleteColumnOutlined {...props} /> },
    { key: 'DeleteRowOutlined', value: <DeleteRowOutlined {...props} /> },
    {
      key: 'DeliveredProcedureOutlined',
      value: <DeliveredProcedureOutlined {...props} />,
    },
    {
      key: 'DeploymentUnitOutlined',
      value: <DeploymentUnitOutlined {...props} />,
    },
    { key: 'DesktopOutlined', value: <DesktopOutlined {...props} /> },
    { key: 'DingtalkOutlined', value: <DingtalkOutlined {...props} /> },
    { key: 'DisconnectOutlined', value: <DisconnectOutlined {...props} /> },
    { key: 'DislikeOutlined', value: <DislikeOutlined {...props} /> },
    { key: 'DollarCircleOutlined', value: <DollarCircleOutlined {...props} /> },
    { key: 'DollarOutlined', value: <DollarOutlined {...props} /> },
    { key: 'DownloadOutlined', value: <DownloadOutlined {...props} /> },
    { key: 'EllipsisOutlined', value: <EllipsisOutlined {...props} /> },
    { key: 'EnvironmentOutlined', value: <EnvironmentOutlined {...props} /> },
    { key: 'EuroCircleOutlined', value: <EuroCircleOutlined {...props} /> },
    { key: 'EuroOutlined', value: <EuroOutlined {...props} /> },
    { key: 'ExceptionOutlined', value: <ExceptionOutlined {...props} /> },
    { key: 'ExpandAltOutlined', value: <ExpandAltOutlined {...props} /> },
    { key: 'ExpandOutlined', value: <ExpandOutlined {...props} /> },
    { key: 'ExperimentOutlined', value: <ExperimentOutlined {...props} /> },
    { key: 'ExportOutlined', value: <ExportOutlined {...props} /> },
    { key: 'EyeOutlined', value: <EyeOutlined {...props} /> },
    { key: 'EyeInvisibleOutlined', value: <EyeInvisibleOutlined {...props} /> },
    { key: 'FieldBinaryOutlined', value: <FieldBinaryOutlined {...props} /> },
    { key: 'FieldNumberOutlined', value: <FieldNumberOutlined {...props} /> },
    { key: 'FieldStringOutlined', value: <FieldStringOutlined {...props} /> },
    { key: 'FieldTimeOutlined', value: <FieldTimeOutlined {...props} /> },
    { key: 'FileAddOutlined', value: <FileAddOutlined {...props} /> },
    { key: 'FileDoneOutlined', value: <FileDoneOutlined {...props} /> },
    { key: 'FileExcelOutlined', value: <FileExcelOutlined {...props} /> },
    {
      key: 'FileExclamationOutlined',
      value: <FileExclamationOutlined {...props} />,
    },
    { key: 'FileOutlined', value: <FileOutlined {...props} /> },
    { key: 'FileGifOutlined', value: <FileGifOutlined {...props} /> },
    { key: 'FileImageOutlined', value: <FileImageOutlined {...props} /> },
    { key: 'FileJpgOutlined', value: <FileJpgOutlined {...props} /> },
    { key: 'FileMarkdownOutlined', value: <FileMarkdownOutlined {...props} /> },
    { key: 'FilePdfOutlined', value: <FilePdfOutlined {...props} /> },
    { key: 'FilePptOutlined', value: <FilePptOutlined {...props} /> },
    { key: 'FileProtectOutlined', value: <FileProtectOutlined {...props} /> },
    { key: 'FileSearchOutlined', value: <FileSearchOutlined {...props} /> },
    { key: 'FileSyncOutlined', value: <FileSyncOutlined {...props} /> },
    { key: 'FileTextOutlined', value: <FileTextOutlined {...props} /> },
    { key: 'FileUnknownOutlined', value: <FileUnknownOutlined {...props} /> },
    { key: 'FileWordOutlined', value: <FileWordOutlined {...props} /> },
    { key: 'FileZipOutlined', value: <FileZipOutlined {...props} /> },
    { key: 'FilterOutlined', value: <FilterOutlined {...props} /> },
    { key: 'FireOutlined', value: <FireOutlined {...props} /> },
    { key: 'FlagOutlined', value: <FlagOutlined {...props} /> },
    { key: 'FolderAddOutlined', value: <FolderAddOutlined {...props} /> },
    { key: 'FolderOutlined', value: <FolderOutlined {...props} /> },
    { key: 'FolderOpenOutlined', value: <FolderOpenOutlined {...props} /> },
    { key: 'FolderViewOutlined', value: <FolderViewOutlined {...props} /> },
    { key: 'ForkOutlined', value: <ForkOutlined {...props} /> },
    {
      key: 'FormatPainterOutlined',
      value: <FormatPainterOutlined {...props} />,
    },
    { key: 'FrownOutlined', value: <FrownOutlined {...props} /> },
    { key: 'FunctionOutlined', value: <FunctionOutlined {...props} /> },
    {
      key: 'FundProjectionScreenOutlined',
      value: <FundProjectionScreenOutlined {...props} />,
    },
    { key: 'FundViewOutlined', value: <FundViewOutlined {...props} /> },
    { key: 'FunnelPlotOutlined', value: <FunnelPlotOutlined {...props} /> },
    { key: 'GatewayOutlined', value: <GatewayOutlined {...props} /> },
    { key: 'GifOutlined', value: <GifOutlined {...props} /> },
    { key: 'GiftOutlined', value: <GiftOutlined {...props} /> },
    { key: 'GlobalOutlined', value: <GlobalOutlined {...props} /> },
    { key: 'GoldOutlined', value: <GoldOutlined {...props} /> },
    { key: 'GroupOutlined', value: <GroupOutlined {...props} /> },
    { key: 'HddOutlined', value: <HddOutlined {...props} /> },
    { key: 'HeartOutlined', value: <HeartOutlined {...props} /> },
    { key: 'HistoryOutlined', value: <HistoryOutlined {...props} /> },
    { key: 'HomeOutlined', value: <HomeOutlined {...props} /> },
    { key: 'HourglassOutlined', value: <HourglassOutlined {...props} /> },
    { key: 'IdcardOutlined', value: <IdcardOutlined {...props} /> },
    { key: 'ImportOutlined', value: <ImportOutlined {...props} /> },
    { key: 'InboxOutlined', value: <InboxOutlined {...props} /> },
    {
      key: 'InsertRowAboveOutlined',
      value: <InsertRowAboveOutlined {...props} />,
    },
    {
      key: 'InsertRowBelowOutlined',
      value: <InsertRowBelowOutlined {...props} />,
    },
    {
      key: 'InsertRowLeftOutlined',
      value: <InsertRowLeftOutlined {...props} />,
    },
    {
      key: 'InsertRowRightOutlined',
      value: <InsertRowRightOutlined {...props} />,
    },
    { key: 'InsuranceOutlined', value: <InsuranceOutlined {...props} /> },
    { key: 'InteractionOutlined', value: <InteractionOutlined {...props} /> },
    { key: 'KeyOutlined', value: <KeyOutlined {...props} /> },
    { key: 'LaptopOutlined', value: <LaptopOutlined {...props} /> },
    { key: 'LayoutOutlined', value: <LayoutOutlined {...props} /> },
    { key: 'LikeOutlined', value: <LikeOutlined {...props} /> },
    { key: 'LineOutlined', value: <LineOutlined {...props} /> },
    { key: 'LinkOutlined', value: <LinkOutlined {...props} /> },
    {
      key: 'Loading3QuartersOutlined',
      value: <Loading3QuartersOutlined {...props} />,
    },
    { key: 'LoadingOutlined', value: <LoadingOutlined {...props} /> },
    { key: 'LockOutlined', value: <LockOutlined {...props} /> },
    { key: 'MacCommandOutlined', value: <MacCommandOutlined {...props} /> },
    { key: 'MailOutlined', value: <MailOutlined {...props} /> },
    { key: 'ManOutlined', value: <ManOutlined {...props} /> },
    { key: 'MedicineBoxOutlined', value: <MedicineBoxOutlined {...props} /> },
    { key: 'MehOutlined', value: <MehOutlined {...props} /> },
    { key: 'MenuOutlined', value: <MenuOutlined {...props} /> },
    { key: 'MergeCellsOutlined', value: <MergeCellsOutlined {...props} /> },
    { key: 'MessageOutlined', value: <MessageOutlined {...props} /> },
    { key: 'MobileOutlined', value: <MobileOutlined {...props} /> },
    { key: 'MoneyCollectOutlined', value: <MoneyCollectOutlined {...props} /> },
    { key: 'MonitorOutlined', value: <MonitorOutlined {...props} /> },
    { key: 'MoreOutlined', value: <MoreOutlined {...props} /> },
    { key: 'NodeCollapseOutlined', value: <NodeCollapseOutlined {...props} /> },
    { key: 'NodeExpandOutlined', value: <NodeExpandOutlined {...props} /> },
    { key: 'NodeIndexOutlined', value: <NodeIndexOutlined {...props} /> },
    { key: 'NotificationOutlined', value: <NotificationOutlined {...props} /> },
    { key: 'NumberOutlined', value: <NumberOutlined {...props} /> },
    { key: 'OneToOneOutlined', value: <OneToOneOutlined {...props} /> },
    { key: 'PaperClipOutlined', value: <PaperClipOutlined {...props} /> },
    { key: 'PartitionOutlined', value: <PartitionOutlined {...props} /> },
    { key: 'PayCircleOutlined', value: <PayCircleOutlined {...props} /> },
    { key: 'PercentageOutlined', value: <PercentageOutlined {...props} /> },
    { key: 'PhoneOutlined', value: <PhoneOutlined {...props} /> },
    { key: 'PictureOutlined', value: <PictureOutlined {...props} /> },
    { key: 'PlaySquareOutlined', value: <PlaySquareOutlined {...props} /> },
    { key: 'PoundCircleOutlined', value: <PoundCircleOutlined {...props} /> },
    { key: 'PoundOutlined', value: <PoundOutlined {...props} /> },
    { key: 'PoweroffOutlined', value: <PoweroffOutlined {...props} /> },
    { key: 'PrinterOutlined', value: <PrinterOutlined {...props} /> },
    { key: 'ProfileOutlined', value: <ProfileOutlined {...props} /> },
    { key: 'ProjectOutlined', value: <ProjectOutlined {...props} /> },
    {
      key: 'PropertySafetyOutlined',
      value: <PropertySafetyOutlined {...props} />,
    },
    { key: 'PullRequestOutlined', value: <PullRequestOutlined {...props} /> },
    { key: 'PushpinOutlined', value: <PushpinOutlined {...props} /> },
    { key: 'QrcodeOutlined', value: <QrcodeOutlined {...props} /> },
    { key: 'ReadOutlined', value: <ReadOutlined {...props} /> },
    {
      key: 'ReconciliationOutlined',
      value: <ReconciliationOutlined {...props} />,
    },
    { key: 'RedEnvelopeOutlined', value: <RedEnvelopeOutlined {...props} /> },
    { key: 'ReloadOutlined', value: <ReloadOutlined {...props} /> },
    { key: 'RestOutlined', value: <RestOutlined {...props} /> },
    { key: 'RobotOutlined', value: <RobotOutlined {...props} /> },
    { key: 'RocketOutlined', value: <RocketOutlined {...props} /> },
    { key: 'RotateLeftOutlined', value: <RotateLeftOutlined {...props} /> },
    { key: 'RotateRightOutlined', value: <RotateRightOutlined {...props} /> },
    {
      key: 'SafetyCertificateOutlined',
      value: <SafetyCertificateOutlined {...props} />,
    },
    { key: 'SafetyOutlined', value: <SafetyOutlined {...props} /> },
    { key: 'SaveOutlined', value: <SaveOutlined {...props} /> },
    { key: 'ScanOutlined', value: <ScanOutlined {...props} /> },
    { key: 'ScheduleOutlined', value: <ScheduleOutlined {...props} /> },
    { key: 'SearchOutlined', value: <SearchOutlined {...props} /> },
    { key: 'SecurityScanOutlined', value: <SecurityScanOutlined {...props} /> },
    { key: 'SelectOutlined', value: <SelectOutlined {...props} /> },
    { key: 'SendOutlined', value: <SendOutlined {...props} /> },
    { key: 'SettingOutlined', value: <SettingOutlined {...props} /> },
    { key: 'ShakeOutlined', value: <ShakeOutlined {...props} /> },
    { key: 'ShareAltOutlined', value: <ShareAltOutlined {...props} /> },
    { key: 'ShopOutlined', value: <ShopOutlined {...props} /> },
    { key: 'ShoppingCartOutlined', value: <ShoppingCartOutlined {...props} /> },
    { key: 'ShoppingOutlined', value: <ShoppingOutlined {...props} /> },
    { key: 'SisternodeOutlined', value: <SisternodeOutlined {...props} /> },
    { key: 'SkinOutlined', value: <SkinOutlined {...props} /> },
    { key: 'SmileOutlined', value: <SmileOutlined {...props} /> },
    { key: 'SolutionOutlined', value: <SolutionOutlined {...props} /> },
    { key: 'SoundOutlined', value: <SoundOutlined {...props} /> },
    { key: 'SplitCellsOutlined', value: <SplitCellsOutlined {...props} /> },
    { key: 'StarOutlined', value: <StarOutlined {...props} /> },
    { key: 'SubnodeOutlined', value: <SubnodeOutlined {...props} /> },
    { key: 'SwitcherOutlined', value: <SwitcherOutlined {...props} /> },
    { key: 'SyncOutlined', value: <SyncOutlined {...props} /> },
    { key: 'TableOutlined', value: <TableOutlined {...props} /> },
    { key: 'TabletOutlined', value: <TabletOutlined {...props} /> },
    { key: 'TagOutlined', value: <TagOutlined {...props} /> },
    { key: 'TagsOutlined', value: <TagsOutlined {...props} /> },
    { key: 'TeamOutlined', value: <TeamOutlined {...props} /> },
    { key: 'ThunderboltOutlined', value: <ThunderboltOutlined {...props} /> },
    { key: 'ToTopOutlined', value: <ToTopOutlined {...props} /> },
    { key: 'ToolOutlined', value: <ToolOutlined {...props} /> },
    {
      key: 'TrademarkCircleOutlined',
      value: <TrademarkCircleOutlined {...props} />,
    },
    { key: 'TrademarkOutlined', value: <TrademarkOutlined {...props} /> },
    { key: 'TransactionOutlined', value: <TransactionOutlined {...props} /> },
    { key: 'TranslationOutlined', value: <TranslationOutlined {...props} /> },
    { key: 'TrophyOutlined', value: <TrophyOutlined {...props} /> },
    { key: 'UngroupOutlined', value: <UngroupOutlined {...props} /> },
    { key: 'UnlockOutlined', value: <UnlockOutlined {...props} /> },
    { key: 'UploadOutlined', value: <UploadOutlined {...props} /> },
    { key: 'UsbOutlined', value: <UsbOutlined {...props} /> },
    { key: 'UserAddOutlined', value: <UserAddOutlined {...props} /> },
    { key: 'UserDeleteOutlined', value: <UserDeleteOutlined {...props} /> },
    { key: 'UserOutlined', value: <UserOutlined {...props} /> },
    { key: 'UserSwitchOutlined', value: <UserSwitchOutlined {...props} /> },
    { key: 'UsergroupAddOutlined', value: <UsergroupAddOutlined {...props} /> },
    {
      key: 'UsergroupDeleteOutlined',
      value: <UsergroupDeleteOutlined {...props} />,
    },
    { key: 'VerifiedOutlined', value: <VerifiedOutlined {...props} /> },
    {
      key: 'VideoCameraAddOutlined',
      value: <VideoCameraAddOutlined {...props} />,
    },
    { key: 'VideoCameraOutlined', value: <VideoCameraOutlined {...props} /> },
    { key: 'WalletOutlined', value: <WalletOutlined {...props} /> },
    { key: 'WhatsAppOutlined', value: <WhatsAppOutlined {...props} /> },
    { key: 'WifiOutlined', value: <WifiOutlined {...props} /> },
    { key: 'WomanOutlined', value: <WomanOutlined {...props} /> },
    { key: 'StepBackwardFilled', value: <StepBackwardFilled {...props} /> },
    { key: 'StepForwardFilled', value: <StepForwardFilled {...props} /> },
    { key: 'FastBackwardFilled', value: <FastBackwardFilled {...props} /> },
    { key: 'FastForwardFilled', value: <FastForwardFilled {...props} /> },
    { key: 'CaretUpFilled', value: <CaretUpFilled {...props} /> },
    { key: 'CaretDownFilled', value: <CaretDownFilled {...props} /> },
    { key: 'CaretLeftFilled', value: <CaretLeftFilled {...props} /> },
    { key: 'CaretRightFilled', value: <CaretRightFilled {...props} /> },
    { key: 'UpCircleFilled', value: <UpCircleFilled {...props} /> },
    { key: 'DownCircleFilled', value: <DownCircleFilled {...props} /> },
    { key: 'LeftCircleFilled', value: <LeftCircleFilled {...props} /> },
    { key: 'RightCircleFilled', value: <RightCircleFilled {...props} /> },
    { key: 'ForwardFilled', value: <ForwardFilled {...props} /> },
    { key: 'BackwardFilled', value: <BackwardFilled {...props} /> },
    { key: 'PlayCircleFilled', value: <PlayCircleFilled {...props} /> },
    { key: 'UpSquareFilled', value: <UpSquareFilled {...props} /> },
    { key: 'DownSquareFilled', value: <DownSquareFilled {...props} /> },
    { key: 'LeftSquareFilled', value: <LeftSquareFilled {...props} /> },
    { key: 'RightSquareFilled', value: <RightSquareFilled {...props} /> },
    { key: 'QuestionCircleFilled', value: <QuestionCircleFilled {...props} /> },
    { key: 'PlusCircleFilled', value: <PlusCircleFilled {...props} /> },
    { key: 'PauseCircleFilled', value: <PauseCircleFilled {...props} /> },
    { key: 'MinusCircleFilled', value: <MinusCircleFilled {...props} /> },
    { key: 'PlusSquareFilled', value: <PlusSquareFilled {...props} /> },
    { key: 'MinusSquareFilled', value: <MinusSquareFilled {...props} /> },
    { key: 'InfoCircleFilled', value: <InfoCircleFilled {...props} /> },
    {
      key: 'ExclamationCircleFilled',
      value: <ExclamationCircleFilled {...props} />,
    },
    { key: 'CloseCircleFilled', value: <CloseCircleFilled {...props} /> },
    { key: 'CloseSquareFilled', value: <CloseSquareFilled {...props} /> },
    { key: 'CheckCircleFilled', value: <CheckCircleFilled {...props} /> },
    { key: 'CheckSquareFilled', value: <CheckSquareFilled {...props} /> },
    { key: 'ClockCircleFilled', value: <ClockCircleFilled {...props} /> },
    { key: 'WarningFilled', value: <WarningFilled {...props} /> },
    { key: 'StopFilled', value: <StopFilled {...props} /> },
    { key: 'EditFilled', value: <EditFilled {...props} /> },
    { key: 'CopyFilled', value: <CopyFilled {...props} /> },
    { key: 'DeleteFilled', value: <DeleteFilled {...props} /> },
    { key: 'SnippetsFilled', value: <SnippetsFilled {...props} /> },
    { key: 'DiffFilled', value: <DiffFilled {...props} /> },
    { key: 'HighlightFilled', value: <HighlightFilled {...props} /> },
    { key: 'PieChartFilled', value: <PieChartFilled {...props} /> },
    { key: 'BoxPlotFilled', value: <BoxPlotFilled {...props} /> },
    { key: 'FundFilled', value: <FundFilled {...props} /> },
    { key: 'SlidersFilled', value: <SlidersFilled {...props} /> },
    { key: 'AndroidFilled', value: <AndroidFilled {...props} /> },
    { key: 'AppleFilled', value: <AppleFilled {...props} /> },
    { key: 'WindowsFilled', value: <WindowsFilled {...props} /> },
    { key: 'ChromeFilled', value: <ChromeFilled {...props} /> },
    { key: 'GithubFilled', value: <GithubFilled {...props} /> },
    { key: 'AliwangwangFilled', value: <AliwangwangFilled {...props} /> },
    { key: 'WeiboSquareFilled', value: <WeiboSquareFilled {...props} /> },
    { key: 'WeiboCircleFilled', value: <WeiboCircleFilled {...props} /> },
    { key: 'TaobaoCircleFilled', value: <TaobaoCircleFilled {...props} /> },
    { key: 'Html5Filled', value: <Html5Filled {...props} /> },
    { key: 'WechatFilled', value: <WechatFilled {...props} /> },
    { key: 'YoutubeFilled', value: <YoutubeFilled {...props} /> },
    { key: 'AlipayCircleFilled', value: <AlipayCircleFilled {...props} /> },
    { key: 'SkypeFilled', value: <SkypeFilled {...props} /> },
    { key: 'GitlabFilled', value: <GitlabFilled {...props} /> },
    { key: 'LinkedinFilled', value: <LinkedinFilled {...props} /> },
    { key: 'FacebookFilled', value: <FacebookFilled {...props} /> },
    {
      key: 'CodeSandboxCircleFilled',
      value: <CodeSandboxCircleFilled {...props} />,
    },
    { key: 'CodepenCircleFilled', value: <CodepenCircleFilled {...props} /> },
    { key: 'SlackSquareFilled', value: <SlackSquareFilled {...props} /> },
    { key: 'BehanceSquareFilled', value: <BehanceSquareFilled {...props} /> },
    { key: 'DribbbleSquareFilled', value: <DribbbleSquareFilled {...props} /> },
    { key: 'InstagramFilled', value: <InstagramFilled {...props} /> },
    { key: 'YuqueFilled', value: <YuqueFilled {...props} /> },
    { key: 'YahooFilled', value: <YahooFilled {...props} /> },
    { key: 'AccountBookFilled', value: <AccountBookFilled {...props} /> },
    { key: 'AlertFilled', value: <AlertFilled {...props} /> },
    { key: 'AlipaySquareFilled', value: <AlipaySquareFilled {...props} /> },
    { key: 'AmazonCircleFilled', value: <AmazonCircleFilled {...props} /> },
    { key: 'AmazonSquareFilled', value: <AmazonSquareFilled {...props} /> },
    { key: 'ApiFilled', value: <ApiFilled {...props} /> },
    { key: 'AppstoreFilled', value: <AppstoreFilled {...props} /> },
    { key: 'AudioFilled', value: <AudioFilled {...props} /> },
    { key: 'BankFilled', value: <BankFilled {...props} /> },
    { key: 'BehanceCircleFilled', value: <BehanceCircleFilled {...props} /> },
    { key: 'BellFilled', value: <BellFilled {...props} /> },
    { key: 'BookFilled', value: <BookFilled {...props} /> },
    { key: 'BugFilled', value: <BugFilled {...props} /> },
    { key: 'BuildFilled', value: <BuildFilled {...props} /> },
    { key: 'BulbFilled', value: <BulbFilled {...props} /> },
    { key: 'CalculatorFilled', value: <CalculatorFilled {...props} /> },
    { key: 'CalendarFilled', value: <CalendarFilled {...props} /> },
    { key: 'CameraFilled', value: <CameraFilled {...props} /> },
    { key: 'CarFilled', value: <CarFilled {...props} /> },
    { key: 'CarryOutFilled', value: <CarryOutFilled {...props} /> },
    { key: 'CiCircleFilled', value: <CiCircleFilled {...props} /> },
    { key: 'CloudFilled', value: <CloudFilled {...props} /> },
    { key: 'CodeFilled', value: <CodeFilled {...props} /> },
    {
      key: 'CodeSandboxSquareFilled',
      value: <CodeSandboxSquareFilled {...props} />,
    },
    { key: 'CodepenSquareFilled', value: <CodepenSquareFilled {...props} /> },
    { key: 'CompassFilled', value: <CompassFilled {...props} /> },
    { key: 'ContactsFilled', value: <ContactsFilled {...props} /> },
    { key: 'ContainerFilled', value: <ContainerFilled {...props} /> },
    { key: 'ControlFilled', value: <ControlFilled {...props} /> },
    { key: 'CreditCardFilled', value: <CreditCardFilled {...props} /> },
    { key: 'CrownFilled', value: <CrownFilled {...props} /> },
    {
      key: 'CustomerServiceFilled',
      value: <CustomerServiceFilled {...props} />,
    },
    { key: 'DashboardFilled', value: <DashboardFilled {...props} /> },
    { key: 'DatabaseFilled', value: <DatabaseFilled {...props} /> },
    { key: 'DingtalkCircleFilled', value: <DingtalkCircleFilled {...props} /> },
    { key: 'DingtalkSquareFilled', value: <DingtalkSquareFilled {...props} /> },
    { key: 'DislikeFilled', value: <DislikeFilled {...props} /> },
    { key: 'DollarCircleFilled', value: <DollarCircleFilled {...props} /> },
    { key: 'DribbbleCircleFilled', value: <DribbbleCircleFilled {...props} /> },
    { key: 'DropboxCircleFilled', value: <DropboxCircleFilled {...props} /> },
    { key: 'DropboxSquareFilled', value: <DropboxSquareFilled {...props} /> },
    { key: 'EnvironmentFilled', value: <EnvironmentFilled {...props} /> },
    { key: 'EuroCircleFilled', value: <EuroCircleFilled {...props} /> },
    { key: 'ExperimentFilled', value: <ExperimentFilled {...props} /> },
    { key: 'EyeFilled', value: <EyeFilled {...props} /> },
    { key: 'EyeInvisibleFilled', value: <EyeInvisibleFilled {...props} /> },
    { key: 'FileAddFilled', value: <FileAddFilled {...props} /> },
    { key: 'FileExcelFilled', value: <FileExcelFilled {...props} /> },
    {
      key: 'FileExclamationFilled',
      value: <FileExclamationFilled {...props} />,
    },
    { key: 'FileFilled', value: <FileFilled {...props} /> },
    { key: 'FileImageFilled', value: <FileImageFilled {...props} /> },
    { key: 'FileMarkdownFilled', value: <FileMarkdownFilled {...props} /> },
    { key: 'FilePdfFilled', value: <FilePdfFilled {...props} /> },
    { key: 'FilePptFilled', value: <FilePptFilled {...props} /> },
    { key: 'FileTextFilled', value: <FileTextFilled {...props} /> },
    { key: 'FileUnknownFilled', value: <FileUnknownFilled {...props} /> },
    { key: 'FileWordFilled', value: <FileWordFilled {...props} /> },
    { key: 'FileZipFilled', value: <FileZipFilled {...props} /> },
    { key: 'FilterFilled', value: <FilterFilled {...props} /> },
    { key: 'FireFilled', value: <FireFilled {...props} /> },
    { key: 'FlagFilled', value: <FlagFilled {...props} /> },
    { key: 'FolderAddFilled', value: <FolderAddFilled {...props} /> },
    { key: 'FolderFilled', value: <FolderFilled {...props} /> },
    { key: 'FolderOpenFilled', value: <FolderOpenFilled {...props} /> },
    { key: 'FormatPainterFilled', value: <FormatPainterFilled {...props} /> },
    { key: 'FrownFilled', value: <FrownFilled {...props} /> },
    { key: 'FunnelPlotFilled', value: <FunnelPlotFilled {...props} /> },
    { key: 'GiftFilled', value: <GiftFilled {...props} /> },
    { key: 'GoldFilled', value: <GoldFilled {...props} /> },
    { key: 'GoldenFilled', value: <GoldenFilled {...props} /> },
    { key: 'GoogleCircleFilled', value: <GoogleCircleFilled {...props} /> },
    {
      key: 'GooglePlusCircleFilled',
      value: <GooglePlusCircleFilled {...props} />,
    },
    {
      key: 'GooglePlusSquareFilled',
      value: <GooglePlusSquareFilled {...props} />,
    },
    { key: 'GoogleSquareFilled', value: <GoogleSquareFilled {...props} /> },
    { key: 'HddFilled', value: <HddFilled {...props} /> },
    { key: 'HeartFilled', value: <HeartFilled {...props} /> },
    { key: 'HomeFilled', value: <HomeFilled {...props} /> },
    { key: 'HourglassFilled', value: <HourglassFilled {...props} /> },
    { key: 'IdcardFilled', value: <IdcardFilled {...props} /> },
    { key: 'IeCircleFilled', value: <IeCircleFilled {...props} /> },
    { key: 'IeSquareFilled', value: <IeSquareFilled {...props} /> },
    { key: 'InsuranceFilled', value: <InsuranceFilled {...props} /> },
    { key: 'InteractionFilled', value: <InteractionFilled {...props} /> },
    { key: 'LayoutFilled', value: <LayoutFilled {...props} /> },
    { key: 'LikeFilled', value: <LikeFilled {...props} /> },
    { key: 'LockFilled', value: <LockFilled {...props} /> },
    { key: 'MacCommandFilled', value: <MacCommandFilled {...props} /> },
    { key: 'MailFilled', value: <MailFilled {...props} /> },
    { key: 'MedicineBoxFilled', value: <MedicineBoxFilled {...props} /> },
    { key: 'MediumCircleFilled', value: <MediumCircleFilled {...props} /> },
    { key: 'MediumSquareFilled', value: <MediumSquareFilled {...props} /> },
    { key: 'MehFilled', value: <MehFilled {...props} /> },
    { key: 'MessageFilled', value: <MessageFilled {...props} /> },
    { key: 'MobileFilled', value: <MobileFilled {...props} /> },
    { key: 'MoneyCollectFilled', value: <MoneyCollectFilled {...props} /> },
    { key: 'NotificationFilled', value: <NotificationFilled {...props} /> },
    { key: 'PayCircleFilled', value: <PayCircleFilled {...props} /> },
    { key: 'PhoneFilled', value: <PhoneFilled {...props} /> },
    { key: 'PictureFilled', value: <PictureFilled {...props} /> },
    { key: 'PlaySquareFilled', value: <PlaySquareFilled {...props} /> },
    { key: 'PoundCircleFilled', value: <PoundCircleFilled {...props} /> },
    { key: 'PrinterFilled', value: <PrinterFilled {...props} /> },
    { key: 'ProfileFilled', value: <ProfileFilled {...props} /> },
    { key: 'ProjectFilled', value: <ProjectFilled {...props} /> },
    { key: 'PropertySafetyFilled', value: <PropertySafetyFilled {...props} /> },
    { key: 'PushpinFilled', value: <PushpinFilled {...props} /> },
    { key: 'QqCircleFilled', value: <QqCircleFilled {...props} /> },
    { key: 'QqSquareFilled', value: <QqSquareFilled {...props} /> },
    { key: 'ReadFilled', value: <ReadFilled {...props} /> },
    { key: 'ReconciliationFilled', value: <ReconciliationFilled {...props} /> },
    { key: 'RedEnvelopeFilled', value: <RedEnvelopeFilled {...props} /> },
    { key: 'RedditCircleFilled', value: <RedditCircleFilled {...props} /> },
    { key: 'RedditSquareFilled', value: <RedditSquareFilled {...props} /> },
    { key: 'RestFilled', value: <RestFilled {...props} /> },
    { key: 'RobotFilled', value: <RobotFilled {...props} /> },
    { key: 'RocketFilled', value: <RocketFilled {...props} /> },
    {
      key: 'SafetyCertificateFilled',
      value: <SafetyCertificateFilled {...props} />,
    },
    { key: 'SaveFilled', value: <SaveFilled {...props} /> },
    { key: 'ScheduleFilled', value: <ScheduleFilled {...props} /> },
    { key: 'SecurityScanFilled', value: <SecurityScanFilled {...props} /> },
    { key: 'SettingFilled', value: <SettingFilled {...props} /> },
    { key: 'ShopFilled', value: <ShopFilled {...props} /> },
    { key: 'ShoppingFilled', value: <ShoppingFilled {...props} /> },
    { key: 'SignalFilled', value: <SignalFilled {...props} /> },
    { key: 'SketchCircleFilled', value: <SketchCircleFilled {...props} /> },
    { key: 'SketchSquareFilled', value: <SketchSquareFilled {...props} /> },
    { key: 'SkinFilled', value: <SkinFilled {...props} /> },
    { key: 'SlackCircleFilled', value: <SlackCircleFilled {...props} /> },
    { key: 'SmileFilled', value: <SmileFilled {...props} /> },
    { key: 'SoundFilled', value: <SoundFilled {...props} /> },
    { key: 'StarFilled', value: <StarFilled {...props} /> },
    { key: 'SwitcherFilled', value: <SwitcherFilled {...props} /> },
    { key: 'TabletFilled', value: <TabletFilled {...props} /> },
    { key: 'TagFilled', value: <TagFilled {...props} /> },
    { key: 'TagsFilled', value: <TagsFilled {...props} /> },
    { key: 'TaobaoSquareFilled', value: <TaobaoSquareFilled {...props} /> },
    { key: 'ThunderboltFilled', value: <ThunderboltFilled {...props} /> },
    { key: 'ToolFilled', value: <ToolFilled {...props} /> },
    {
      key: 'TrademarkCircleFilled',
      value: <TrademarkCircleFilled {...props} />,
    },
    { key: 'TrophyFilled', value: <TrophyFilled {...props} /> },
    { key: 'TwitterCircleFilled', value: <TwitterCircleFilled {...props} /> },
    { key: 'TwitterSquareFilled', value: <TwitterSquareFilled {...props} /> },
    { key: 'UnlockFilled', value: <UnlockFilled {...props} /> },
    { key: 'UsbFilled', value: <UsbFilled {...props} /> },
    { key: 'VideoCameraFilled', value: <VideoCameraFilled {...props} /> },
    { key: 'WalletFilled', value: <WalletFilled {...props} /> },
    { key: 'ZhihuCircleFilled', value: <ZhihuCircleFilled {...props} /> },
    { key: 'ZhihuSquareFilled', value: <ZhihuSquareFilled {...props} /> },
  ];

  // icon by key
  return iconsAntd.find(d => d.key === key)
    ? iconsAntd.find(d => d.key === key).value
    : '';
}
