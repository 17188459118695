import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

// Antd
import Divider from 'antd/es/divider';

//
import { __ } from '../../../Utils';
import { connect, actions } from '../../../Store';

class SectionHeading extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      types: ['page', 'card'], // types
      colorTypes: ['success', 'blockchain', 'delete', 'info'], // color types
    };
  }

  componentDidMount() {
    //
  }

  componentDidUpdate() {
    //
  }

  render() {
    const { type, indicator, text, blockchainHighlighting, colorType, infoIcon } = this.props;
    const { types, colorTypes } = this.state;

    const resType = type && types.includes(type) ? type : 'card';
    const resColorType = colorType && colorTypes.includes(colorType) ? colorType : 'success';

    return (
      <div className="ST-section-heading">
        <small className={`indicator ${resType}-indicator ${resColorType}-indicator`}>
          {indicator || __('01')}
        </small>
        <Divider type="vertical" className={`divider ${resType}-divider ${resColorType}-divider`} />
        <h4 className={`${resType}-txt`}>{__(text) || __('Heading')}</h4>
        {infoIcon && (
          <img
            className="info-icon"
            src="/theme/red/images/layout/icons/info-grey-icon.svg"
            alt="info icon"
          />
        )}
      </div>
    );
  }
}

SectionHeading.propTypes = {
  history: PropTypes.objectOf({}).isRequired,
  location: PropTypes.objectOf({}).isRequired,
  match: PropTypes.objectOf({}).isRequired,
  data: PropTypes.objectOf({}).isRequired,
  // close: PropTypes.bool,
};

SectionHeading.defaultProps = {
  // close: false,
};

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(SectionHeading);
