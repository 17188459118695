import React from 'react';
// Antd
import Grid from 'antd/es/grid';
import Space from 'antd/es/space';
import Tag from 'antd/es/tag';

const { useBreakpoint } = Grid;

const UseBreakpoint = () => {
  const screens = useBreakpoint();

  return (
    <Space useBreakpointVal={screens}>
      Current break point:{' '}
      {Object.entries(screens)
        .filter(screen => !!screen[1])
        .map(screen => (
          <Tag color="blue" key={screen[0]}>
            {screen[0]}
          </Tag>
        ))}
    </Space>
  );
};

export default UseBreakpoint;
