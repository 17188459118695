import styled from 'styled-components';
import { rem } from 'polished';

export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: ${rem(50)} auto 0;
  min-height: 20vh;
`;

export const AnimatedFormMessageWrapper = styled.div`
  overflow: ${({ display }) => (display ? 'unset' : 'hidden')};
  position: ${({ success }) => success && 'sticky'};
  top: ${({ success }) => success && rem(50)};
  z-index: ${({ success }) => success && '2'};
  max-height: ${({ display }) => (display ? 'unset' : '0px')};
  opacity: ${({ display }) => (display ? '1' : '0')};
  transition: opacity 0.5s, max-height 1s;
  width: ${({ width }) => width || 'unset'};
`;

export const RouteLinkWraper = styled.li`
  list-style: none;
  a {
    text-decoration: none;
    margin: ${({ noMargin }) => (noMargin ? '0' : `${rem(10)}`)};
    padding: 0;
    display: block;
    cursor: pointer;
    white-space: nowrap;
  }
  a:visited {
  }
  a:hover {
    text-decoration: underline;
  }
`;

export const IFrame = styled.iframe`
  border: none;
  outline: none;
  width: 100%;
  height: ${rem(60)};
`;

export const StyledLink = styled.div`
  cursor: pointer;
`;

export const FlexBox = styled.div`
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent || 'center'};
  align-items: ${({ alignItems }) => alignItems || 'center'};
  flex-wrap: ${({ flexWrap }) => flexWrap || 'no-wrap'};
`;