import React from 'react';
import PropTypes from 'prop-types';

import cookie from 'react-cookies';
import { connect } from '../../Store';

const authorizeUrl = process.env.REACT_APP_ANONYMOUS_AUTHORIZE;
const anonymousUrl = process.env.REACT_APP_ANONYMOUS_REDIRECT;
const anonymousID = process.env.REACT_APP_ANONYMOUS_ID;

class LoginAnonymous extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      login: '',
      password: '',
    };
  }

  componentDidMount() {
    const login = cookie.load('login');
    const anonymousAccessToken = cookie.load('anonymousAccessToken');

    if (login) {
      this.setState({
        login,
      });
    }

    if (!anonymousAccessToken) {
      document.getElementById('loginAnonymousForm').submit();
    }
  }

  validateForm() {
    let isValid = true;
    const { login, password } = this.state;

    if (login.length < 1) {
      isValid = false;
    }

    if (password.length < 1) {
      isValid = false;
    }

    cookie.save('login', login);

    return isValid;
  }

  render() {
    return (
      <form
        style={{ display: 'none' }}
        id="loginAnonymousForm"
        method="post"
        action={authorizeUrl}
        onSubmit={() => this.validateForm()}
      >
        {/* anonymous_credentials */}
        <input type="hidden" name="username" value="anonymous" />
        <input type="hidden" name="password" value="ssssssss" />

        <input type="hidden" name="redirect_uri" value={anonymousUrl} />
        <input type="hidden" name="app_id" value={anonymousID} />
        <input type="hidden" name="scopes" value="test" />
      </form>
    );
  }
}

LoginAnonymous.propTypes = {
  history: PropTypes.object.isRequired, //eslint-disable-line
  match: PropTypes.object.isRequired, //eslint-disable-line
  location: PropTypes.shape({ campaign: PropTypes.string }).isRequired,
};

LoginAnonymous.defaultProps = {};

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(LoginAnonymous);
