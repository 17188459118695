//
import React from 'react';
import { useThemeSwitcher } from 'react-css-theme-switcher';
// Antd
import Space from 'antd/es/space';
import Typography from 'antd/es/typography';
import Select from 'antd/es/select';
import { __ } from '../../Utils';

const { Title, Text, Paragraph } = Typography;
const { Option } = Select;

// options
const themeOpts = [
  {
    value: 'light_st',
    label: 'Light : SmartTrust',
  },
  {
    value: 'dark_st',
    label: 'Dark : SmartTrust',
  },
];

const CssThemeSwitcher = props => {
  const { themeModeVal, switchThemeMode } = props;
  const { switcher, themes, currentTheme, status } = useThemeSwitcher();
  const [isDarkMode, setIsDarkMode] = React.useState(false);
  const [selectedThemeMode, setSelectedThemeMode] = React.useState(false);

  React.useEffect(() => {
    // props
    if (currentTheme !== themeModeVal) {
      switchThemeMode(currentTheme, themeOpts);
    }
  });

  if (status === 'loading') {
    return <div>{__('Loading styles ...')}</div>;
  }

  const toggleDarkMode = () => {
    setIsDarkMode(previous => {
      switcher({ theme: previous ? themes.light : themes.dark });
      return !previous;
    });
  };

  function handleContentTypeChange(value) {
    // state
    setSelectedThemeMode(value);
    // set
    switcher({ theme: value });
    // props
    switchThemeMode(value, themeOpts);
  }

  return (
    <Space>
    <Text>Current theme: {currentTheme}</Text>
      <Select
        // allowClear
        size="large"
        value={currentTheme}
        style={{ width: '200px' }}
        placeholder={__('Select theme')}
        onChange={handleContentTypeChange}
        options={themeOpts}
      />
    </Space>
  );
};

export default CssThemeSwitcher;
