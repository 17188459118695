import React from 'react';
import styled from 'styled-components';
// import uniqid from 'uniqid';
import validator from 'validator';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
// Antd
import Input from 'antd/es/input';
import Tooltip from 'antd/es/tooltip';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Alert from 'antd/es/alert';
import Typography from 'antd/es/typography';
import Button from 'antd/es/button';
import Select from 'antd/es/select';
import Switch from 'antd/es/switch';
import Result from 'antd/es/result';
import Space from 'antd/es/space';
import InputNumber from 'antd/es/input-number';
import Radio from 'antd/es/radio';
import Form from 'antd/es/form';
import Divider from 'antd/es/divider';

// validator
import isAlpha from 'validator/lib/isAlpha';

// Icon
// import Icon from '@ant-design/icons/es/components/Icon';
// icons
import {
  BankOutlined,
  ClockCircleOutlined,
  CloseOutlined,
  ContactsOutlined,
  EnvironmentOutlined,
  GlobalOutlined,
  GoldOutlined,
  HomeOutlined,
  CheckCircleOutlined,
  CheckOutlined,
  IdcardOutlined,
  InfoOutlined,
  InfoCircleOutlined,
  LockOutlined,
  MailOutlined,
  MobileOutlined,
  ProfileOutlined,
  SafetyOutlined,
  SolutionOutlined,
  ShoppingOutlined,
  UserOutlined,
  CheckCircleFilled,
  ConsoleSqlOutlined,
} from '@ant-design/icons';

import cookie from 'react-cookies';
import { border, rem } from 'polished';
import { __, parseHash, toIsoDate, formatNumber, translate } from '../../Utils';
import { StyledLink, AnimatedFormMessageWrapper } from '../../Components/ReusableComponents';
import API from '../../API';
import { connect, actions } from '../../Store';
import { textChangeRangeIsUnchanged } from 'typescript';

// const icons = require(`@ant-design/icons`);

const { Title, Text } = Typography;
const { TextArea } = Input;
const { Option } = Select;

const authorizeUrl = process.env.REACT_APP_AUTHORIZE;
const portalUrl = process.env.REACT_APP_PORTAL_REDIRECT;
const portalID = process.env.REACT_APP_PORTAL_ID;
const portalScopes = 'test';

const landingDataSource = {
  props: {
    className: 'landing',
  },
  content: {
    className: 'landing-content',
  },
  contentWrapper: {
    className: 'landing-wrapper',
  },
  logo: {
    className: 'ST-logo',
    src: '/theme/red/images/layout/login/ST_logo_with_heading.svg',
    alt: 'ST logo',
    wrapper: {
      className: 'ST-logo-wrapper',
    },
  },
  form: {
    wrapper: {
      className: 'landing-form-wrapper',
    },
    content: {
      className: 'landing-form-content',
    },
    icon: {
      className: 'landing-form-icon',
      alt: 'landing form icon',
    },
    heading: {
      plain: true,
      orientation: 'center',
      className: 'landing-form-heading',
    },
  },
  footer: {
    className: 'footer-wrapper',
  },
  login: {
    heading: 'user login',
    icon: '/theme/red/images/layout/login/user_login.svg',
    form: {
      props: {
        action: 'http://52.28.204.123:35189/v1/oauth/authorize/portal',
        method: 'POST',
        className: 'landing-form',
      },
      items: [
        {
          className: 'form-item',
          type: 'text',
          label: 'username',
          input: {
            name: 'username',
            className: 'input-text',
          },
          rules: [
            {
              required: true,
              message: 'Please input your username!',
            },
            {
              pattern: /^[a-zA-Z0-9]+$/,
              message: 'Name can only include letters and numbers.',
            },
          ],
        },
        {
          className: 'form-item',
          type: 'password',
          label: 'password',
          input: {
            name: 'password',
            className: 'input-pass',
          },
          rules: [{ required: true, message: 'Please input your username!' }],
        },
      ],
      submitButton: {
        text: 'login',
        type: 'primary',
        htmlType: 'button',
        wrapper: {
          className: 'form-item form-item-submit',
        },
      },
      actionsButtons: {
        props: {
          className: 'actions-buttons',
        },
        left: {
          text: '', // 'create account X',
          href: '', // '/register',
        },
        right: {
          text: '', // 'forgotten password X',
          href: '', // '/forgotten-password',
        },
      },
      additionalHiddenInputs: [
        {
          name: 'redirect_uri',
          value: portalUrl,
        },
        {
          name: 'app_id',
          value: portalID,
        },
        {
          name: 'scopes',
          value: portalScopes,
        },
      ],
    },
  },
  register: {
    heading: 'create account',
    icon: '/theme/red/images/layout/login/user_create.svg',
    form: {
      props: {
        className: 'landing-form',
      },
      items: [
        {
          className: 'form-item',
          type: 'text',
          label: 'enter username',
          input: {
            name: 'username',
            className: 'input-text',
          },
          rules: [{ required: true, message: 'Please input your username!' }],
        },
        {
          className: 'form-item',
          type: 'password',
          label: 'enter password',
          input: {
            name: 'password',
            className: 'input-pass',
          },
          rules: [{ required: true, message: 'Please input your username!' }],
        },
      ],
      submitButton: {
        text: 'create account',
        type: 'primary',
        htmlType: 'button',
        wrapper: {
          className: 'form-item form-item-submit',
        },
      },
      actionsButtons: {
        props: {
          className: 'actions-buttons',
        },
        left: {
          text: '',
          href: '',
        },
        right: {
          text: 'login',
          href: '/login',
        },
      },
      additionalHiddenInputs: [],
    },
  },
  forgottenPassword: {
    heading: 'forgotten password',
    icon: '/theme/red/images/layout/login/user_forgotten_password.svg',
    form: {
      props: {
        className: 'landing-form',
      },
      note: {
        className: 'note',
        text: `To recover your password please enter your email address and follow the
               instructions found in the email.`,
      },
      items: [
        {
          className: 'form-item',
          type: 'email',
          label: '',
          input: {
            name: 'email',
            className: 'input-text',
          },
          rules: [{ required: true, message: 'Please input your username!' }],
        },
      ],
      submitButton: {
        text: 'recover my password',
        type: 'primary',
        htmlType: 'button',
        wrapper: {
          className: 'form-item form-item-submit',
        },
      },
      actionsButtons: {
        props: {
          className: 'actions-buttons',
        },
        left: {
          text: '',
          href: '',
        },
        right: {
          text: '',
          href: '',
        },
      },
      additionalHiddenInputs: [],
    },
  },
  restorePassword: {
    heading: 'create new password',
    icon: '/theme/red/images/layout/login/user_create.svg',
    form: {
      props: {
        className: 'landing-form',
      },
      items: [
        {
          className: 'form-item',
          type: 'password',
          label: 'enter new password',
          input: {
            name: 'password',
            className: 'input-pass',
          },
          rules: [{ required: true, message: 'Please input your username!' }],
        },
        {
          className: 'form-item',
          type: 'password',
          label: 'repeat new password',
          input: {
            name: 'repeatPassword',
            className: 'input-pass',
          },
          rules: [{ required: true, message: 'Please input your username!' }],
        },
      ],
      submitButton: {
        text: 'create new password',
        type: 'primary',
        htmlType: 'button',
        wrapper: {
          className: 'form-item form-item-submit',
        },
      },
      actionsButtons: {
        props: {
          className: 'actions-buttons',
        },
        left: {
          text: 'create account',
          href: '/register',
        },
        right: {
          text: 'login',
          href: '/login',
        },
      },
      additionalHiddenInputs: [],
    },
  },
};

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      helmetTitleLog: 'Login',
      helmetTitleReg: 'Registration',
      helmetTitleFor: 'Forgot password? / Change password',
      helmetTitleRes: 'Change password',
      //
      userLogin: true,
      userRegister: false,
      userForgottenPassword: false,
      userRestorePassword: false,
      //
      username: '',
      email: '',
      password: '',
      repeatPassword: '',
    };

    // refs
    this.form = React.createRef();
  }

  componentDidMount() {
    if (this.props.match && this.props.match.url) {
      const { url } = this.props.match;

      const action = url.replace('/', '');

      // lister on enter, then submit login form
      document.body.addEventListener('keyup', (event) => {
        if (event.keyCode === 13) {
          this.onSubmit();
        }
      });

      // more routes are set up on this component
      if (action.includes('register')) {
        this.setState({
          userLogin: false,
          userRegister: true,
        });
      } else if (action.includes('forgotten-password')) {
        this.setState({
          userLogin: false,
          userForgottenPassword: true,
        });
      } else if (action.includes('restore-password')) {
        this.setState({
          userLogin: false,
          userRestorePassword: true,
        });
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {}

  async onSubmit() {
    console.log('states: ', this.state);
    const {
      userLogin,
      userRegister,
      userForgottenPassword,
      userRestorePassword,
      username,
      password,
      name,
      surname,
    } = this.state;

    if (userLogin) {
      if (this.validateData()) this.form.current.submit();
      else console.log('not valid');
    } else if (userRegister) {
      try {
        const newData = {
          email: username || '',
          username: username || '',
          password: password || '',
          name: name || '',
          surname: surname || '',
          phone: '',
          mobile: '',
          web: '',
          acceptConditions: false,
          acceptGdpr: true,
          position: '',
          sex: 'F',
          birthdate: '',
          contactType: 'customer',
          registeredFrom: 'web',
          address: {
            city: '',
            street: '',
            postal_code: '',
            country: '',
          },
        };

        const result = await API.postPortalCustomerContactAction('5d89b8848427d56064772091', {
          data: newData,
        });
        console.log('-----++ result');
        console.log(result);
      } catch (err) {
        console.log(err);
      }
    } else if (userForgottenPassword) {
      console.log('forgotten pass');
      // this.userForgottenPassword();
    } else if (userRestorePassword) {
      console.log('restore pass');
      // this.userRestorePassword();
    }
  }

  // ---

  getActionData() {
    const { userRegister, userForgottenPassword, userRestorePassword } = this.state;
    const { login, register, forgottenPassword, restorePassword } = landingDataSource;

    if (userRegister) {
      return register;
    } else if (userForgottenPassword) {
      return forgottenPassword;
    } else if (userRestorePassword) {
      return restorePassword;
    }

    return login;
  }

  fillHelmetTitle() {
    const { userLogin, userRegister, userForgottenPassword, userRestorePassword } = this.state;
    const { helmetTitleLog, helmetTitleReg, helmetTitleFor, helmetTitleRes } = this.state;

    if (userRegister) {
      return `${helmetTitleReg}`;
    } else if (userForgottenPassword) {
      return `${helmetTitleFor}`;
    } else if (userRestorePassword) {
      return `${helmetTitleRes}`;
    }

    return `${helmetTitleLog}`;
  }

  fillActionIcon() {
    const { userRegister, userForgottenPassword, userRestorePassword } = this.state;
    const { login, register, forgottenPassword, restorePassword } = landingDataSource;

    if (userRegister) {
      return register.icon;
    } else if (userForgottenPassword) {
      return forgottenPassword.icon;
    } else if (userRestorePassword) {
      return restorePassword.icon;
    }

    return login.icon;
  }

  fillActionHeading() {
    const { userRegister, userForgottenPassword, userRestorePassword } = this.state;
    const { login, register, forgottenPassword, restorePassword } = landingDataSource;

    if (userRegister) {
      return register.heading;
    } else if (userForgottenPassword) {
      return forgottenPassword.heading;
    } else if (userRestorePassword) {
      return restorePassword.heading;
    }

    return login.heading;
  }

  formLeftActionButton(e) {
    e.preventDefault();
    const { userLogin, userRegister, userForgottenPassword, userRestorePassword } = this.state;
    const { login, register, forgottenPassword, restorePassword } = landingDataSource;

    if (userLogin) {
      this.setState({
        userLogin: false,
        userRegister: true,
        userForgottenPassword: false,
        userRestorePassword: false,
        //
        username: '',
        email: '',
        password: '',
        repeatPassword: '',
      });
    } else if (userRestorePassword) {
      this.setState({
        userLogin: false,
        userRegister: true,
        userForgottenPassword: false,
        userRestorePassword: false,
        //
        username: '',
        email: '',
        password: '',
        repeatPassword: '',
      });
    }
  }

  formRightActionButton(e) {
    e.preventDefault();
    const { userLogin, userRegister, userForgottenPassword, userRestorePassword } = this.state;
    const { login, register, forgottenPassword, restorePassword } = landingDataSource;

    if (userLogin) {
      this.setState({
        userLogin: false,
        userRegister: false,
        userForgottenPassword: true,
        userRestorePassword: false,
        //
        username: '',
        email: '',
        password: '',
        repeatPassword: '',
      });
    } else if (userRegister) {
      this.setState({
        userLogin: true,
        userRegister: false,
        userForgottenPassword: false,
        userRestorePassword: false,
        //
        username: '',
        email: '',
        password: '',
        repeatPassword: '',
      });
    } else if (userRestorePassword) {
      this.setState({
        userLogin: true,
        userRegister: false,
        userForgottenPassword: false,
        userRestorePassword: false,
        //
        username: '',
        email: '',
        password: '',
        repeatPassword: '',
      });
    }
  }

  validateData() {
    const { username, password } = this.state;
    // if (!isAlpha(username, 'en-US', { ignore: '.' })) return false;
    if (username.length === 0) return false;
    if (password.length === 0) return false;

    return true;
  }

  renderForm() {
    const data = this.getActionData();
    const { form } = data;
    const { actionsButtons, submitButton, note } = form;

    // form inputs
    const formItems = form.items.map((item, index) => {
      let formInput = (
        <Input
          {...item.input}
          value={this.state[item.input.name]}
          onChange={(e) => {
            this.setState({ [item.input.name]: e.target.value });
          }}
        />
      );
      if (item.type === 'password') {
        formInput = (
          <Input.Password
            value={this.state[item.input.name]}
            {...item.input}
            onChange={(e) => {
              this.setState({ [item.input.name]: e.target.value });
            }}
          />
        );
      }

      return (
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <div className={item.className}>
            <label htmlFor="">{item.label}</label>
            {formInput}
          </div>
        </Col>
      );
    });

    // form additional hidden inputs
    let additionalHiddenInputs;
    if (form.additionalHiddenInputs) {
      additionalHiddenInputs = form.additionalHiddenInputs.map((input, index) => {
        return <input type="hidden" name={input.name} value={input.value} />;
      });
    }

    return (
      <form ref={this.form} {...form.props} action={authorizeUrl}>
        <Row gutter={[0, 10]} justify="center" align="middle">
          {form.note && (
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <div className={note.className}>
                <p>{note.text}</p>
              </div>
            </Col>
          )}

          {formItems}

          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <div {...submitButton.wrapper}>
              <Button
                type={submitButton.type}
                htmlType={submitButton.htmlType}
                className="ST-btn ST-primary-btn action-btn"
                onClick={() => this.onSubmit()}
              >
                {submitButton.text}
              </Button>
            </div>
            <div {...actionsButtons.props}>
              <Button href={actionsButtons.left.href} onClick={(e) => this.formLeftActionButton(e)}>
                {actionsButtons.left.text}
              </Button>
              <Button
                href={actionsButtons.right.href}
                onClick={(e) => this.formRightActionButton(e)}
              >
                {actionsButtons.right.text}
              </Button>
            </div>
          </Col>
        </Row>

        {additionalHiddenInputs}
      </form>
    );
  }

  render() {
    const { props, content, contentWrapper, logo, form, footer } = landingDataSource;
    return (
      <React.Fragment>
        <Helmet>
          <title>{this.fillHelmetTitle()}</title>
        </Helmet>

        <div {...props}>
          <div {...content}>
            <div {...contentWrapper}>
              <Row gutter={[0, 40]} justify="center" align="middle">
                {/* LOGO */}
                <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                  <div {...logo.wrapper}>
                    <div className={logo.className}>
                      <img src={logo.src} alt={logo.alt} />
                    </div>
                  </div>
                </Col>

                {/* FORM */}
                <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                  <div {...form.wrapper}>
                    <div {...form.content}>
                      {/* FORM HEADER */}
                      <Row gutter={[0, 17]} justify="center" align="middle">
                        <Col
                          xs={24}
                          sm={24}
                          md={24}
                          lg={24}
                          xl={24}
                          xxl={24}
                          style={{ textAlign: 'center' }}
                        >
                          <img {...form.icon} src={this.fillActionIcon()} />
                        </Col>
                        <Col
                          xs={24}
                          sm={24}
                          md={24}
                          lg={24}
                          xl={24}
                          xxl={24}
                          style={{ textAlign: 'center' }}
                        >
                          <Divider {...form.heading}>{this.fillActionHeading()}</Divider>
                        </Col>
                      </Row>

                      {/* FORM */}
                      {this.renderForm()}
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>

          {/* FOOTER */}
          {/* <div {...footer} /> */}
        </div>
      </React.Fragment>
    );
  }
}

Login.propTypes = {
  history: PropTypes.object.isRequired, //eslint-disable-line
  match: PropTypes.object.isRequired, //eslint-disable-line
  location: PropTypes.shape({ campaign: PropTypes.string }).isRequired,
  carts: PropTypes.arrayOf({}).isRequired,

  //
};

Login.defaultProps = {};

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(Login);
