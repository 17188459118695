import React from 'react';
import styled from 'styled-components';
// import uniqid from 'uniqid';
import validator from 'validator';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
// Antd
import Input from 'antd/es/input';
import Tooltip from 'antd/es/tooltip';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Alert from 'antd/es/alert';
import Typography from 'antd/es/typography';
import Button from 'antd/es/button';
import Select from 'antd/es/select';
import Switch from 'antd/es/switch';
import Result from 'antd/es/result';
import Space from 'antd/es/space';
import InputNumber from 'antd/es/input-number';
import Radio from 'antd/es/radio';
// icons
import {
  BankOutlined,
  ClockCircleOutlined,
  CloseOutlined,
  ContactsOutlined,
  EnvironmentOutlined,
  GlobalOutlined,
  GoldOutlined,
  HomeOutlined,
  CheckCircleOutlined,
  CheckOutlined,
  IdcardOutlined,
  InfoCircleOutlined,
  LockOutlined,
  MailOutlined,
  MobileOutlined,
  SafetyOutlined,
  SolutionOutlined,
  ShoppingOutlined,
  UserOutlined,
  CheckCircleFilled,
} from '@ant-design/icons';

import cookie from 'react-cookies';
import { rem } from 'polished';
import { __, parseHash, toIsoDate, formatNumber, translate } from '../../Utils';
import { StyledLink, AnimatedFormMessageWrapper } from '../../Components/ReusableComponents';
import API from '../../API';
import { connect } from '../../Store';

const { Title, Text } = Typography;
const { TextArea } = Input;
const { Option } = Select;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const Link = styled(StyledLink)`
  margin-left: ${rem(2)};
  color: #23231f;
  font-size: ${rem(12)};
  :hover {
    text-decoration: underline;
  }
`;

const Content = styled.div`
  width: 100%;
  max-width: ${rem(600)};
  padding: ${rem(50)} ${rem(25)};
`;

const Wrapper = styled.div`
  width: 100%;
  margin: auto;
`;

const TitleWrapper = styled.div`
  width: 100%;
  margin-bottom: ${rem(0)};
  padding: 5px 0;
  background: #f5f5f5;
  border-radius: 30px;
`;

const authorizeUrl = process.env.REACT_APP_AUTHORIZE;
const portalUrl = process.env.REACT_APP_PORTAL_REDIRECT;
const portalID = process.env.REACT_APP_PORTAL_ID;

class LoginBackup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: {
        open: false,
        type: 'info',
        message: '',
        description: '',
      },
      registerUserSuccessAlert: undefined,
      hasError: {},
      login: '',
      password: '',
      error: '',
      restorePassword: false,
      nameError: undefined,
      passwordError: undefined,
      registerUser: false,
      registerUserError: undefined,
      name: '',
      surname: '',
      email: '',
      phone: '',
      mobile: '',
      web: '',
      position: '',
      companyAddress: '',
      city: '',
      street: '',
      postalCode: '',
      sex: '',
      isDefault: '',
      birthday: '',
      description: '',
      showStartFrom: false,
      saveBtnLoading: false,
      contactType: 'customer',
      conatctBirthDay: '',
      conatctBirthMonth: '',
      conatctBirthYear: '',
      country: '',
      acceptConditions: false,
      acceptGdpr: false,
      errors: [],
      valueCustomer: [],
      valueBookingService: [],
      active: true,
      sorter: 'surname',
      limit: 100,
      total: null,
      offset: 0,
      predvolbaType: '+421',
      predvolbaItems: [
        { key: '+380', label: '+380' },
        { key: '+420', label: '+420' },
        { key: '+421', label: '+421' },
        { key: '+1', label: '+1' },
        { key: '+31', label: '+31' },
        { key: '+33', label: '+33' },
        { key: '+36', label: '+36' },
        { key: '+41', label: '+41' },
        { key: '+43', label: '+43' },
        { key: '+44', label: '+44' },
        { key: '+45', label: '+45' },
        { key: '+47', label: '+47' },
        { key: '+48', label: '+48' },
        { key: '+49', label: '+49' },
      ],
      userName: '',
      userPassword: '',
      userNameExist: false,
      emailExist: false,
      helmetTitle: 'Prihlásenie',
      helmetTitleReg: 'Registrácia',
      helmetTitleFor: 'Zabudli ste heslo? / Zmena hesla',
      tabLangActiveKey: 'EN',
    };
  }

  componentDidMount() {
    // history && history.location.pathname.search('register') === -1 ? null : this.toRegister();

    const login = cookie.load('login');

    if (login) {
      this.setState({
        login,
      });
    }
    const query = parseHash(window.location.hash);
    if (query.error) {
      if (query.error === 'INVALID_CREDENTIALS') {
        this.setState({
          error: __('Nesprávne prihlásovacie údaje'),
        });
      }
    }

    // App Fields
    this.fetchAppFieldContactData();
  }

  componentDidUpdate(prevProps, prevState) {
    const { userNameExist, emailExist } = this.state;
    if (userNameExist !== prevState.userNameExist || emailExist !== prevState.emailExist) {
      // scroll to Top
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
      this.checkData(userNameExist, emailExist);
    }
  }

  onInputChange(property, event) {
    if (property === 'login') {
      this.setState({
        login: event.target.value,
        nameError: '',
        error: undefined,
      });
    } else if (property === 'password') {
      this.setState({
        password: event.target.value,
        passwordError: '',
        error: undefined,
      });
    }
  }

  async onSubmit() {
    const {
      name,
      surname,
      email,
      phone,
      mobile,
      web,
      position,
      companyAddress,
      city,
      street,
      postalCode,
      country,
      sex,
      isDefault,
      birthday,
      description,
      conatctBirthDay,
      conatctBirthMonth,
      conatctBirthYear,
      duration,
      price,
      showStartFrom,
      valueBookingService,
      valueCustomer,
      contactType,
      valueVirtualDeposit,
      acceptConditions,
      acceptGdpr,
      userName,
      userPassword,
      predvolbaType,
    } = this.state;
    const { contactId, close, onSuccessfulCallback, onCloseSend, data, user } = this.props;

    if (this.checkData()) {
      this.setState({
        alert: {
          open: false,
          type: 'info',
          message: '',
          description: '',
        },
        saveBtnLoading: true,
        userNameExist: false,
        emailExist: false,
      });
      try {
        // let result;

        const newData = {
          email: email || '',
          userName: email || '',
          userPassword: userPassword || '',
          name: name || '',
          surname: surname || '',
          phone: phone || '',
          mobile: predvolbaType && mobile ? predvolbaType + mobile : '',
          web: web || '',
          acceptConditions: acceptConditions || false,
          acceptGdpr: acceptGdpr || false,
          position: position || '',
          sex: sex === 'male' ? 'M' : 'F',
          birthdate:
            conatctBirthDay && conatctBirthMonth && conatctBirthYear
              ? toIsoDate(`${conatctBirthDay}.${conatctBirthMonth}.${conatctBirthYear}`)
              : '',
          contactType: 'customer',
          registeredFrom: 'web',
          address: {
            city: city || '',
            street: street || '',
            postal_code: postalCode || '',
            country: country || '',
          },
        };
        console.log('-----++ Newdata');
        console.log(newData);

        /* if (!contactId) {
          // s
        } else { */
        const result = await API.postPortalCustomerContactAction('5d89b8848427d56064772091', {
          data: newData,
        });
        console.log('-----++ result');
        console.log(result);
        if (result.statusCode === 404) {
          console.log('-----++ statusCode');
          this.setState({
            alert: {
              open: true,
              type: 'error',
              message: __('Niečo sa stalo'),
              description: `meno: ${result.emailExist}`,
            },
            saveBtnLoading: false,
            userNameExist: result.userNameExist,
            emailExist: result.emailExist,
          });
        } else {
          this.setState({
            registerUserSuccessAlert: {
              open: true,
              type: 'success',
              message: __('Účet bol vytvorený'),
              description: `Vytvorili ste účet a potvrdovadzí link na prihlásenie bol zaslaný na Vašu emailovú adresu: `,
            },
            registerUserSuccess: __(
              'Vytvorili ste účet a potvrdovadzí link na prihlásenie bol zaslaný na Vašu emailovú adresu',
            ),
          });
        }
        // }

        this.setState({
          saveBtnLoading: false,
          // data: newData,
        });

        return true;
      } catch (e) {
        if (e.response) {
          switch (e.response.status) {
            case 404:
              console.log('---e');
              console.log(e.response);
              this.setState({
                alert: {
                  open: true,
                  type: 'error',
                  message: __('Niečo sa stalo'),
                  description: `meno: `,
                },
                saveBtnLoading: false,
              });
              break;
            case 403:
              this.setState({
                errors: [__('Na zvolenú akciu nemáte potrebné práva')],
                loader: false,
              });
              break;
            case 409:
              this.setState({
                showConflictDialog: true,
              });
              console.log(e);
              console.log(e.payload);
              break;
            default:
              throw e;
          }
        }
      }
    }
    return false;
  }

  toRegister() {
    this.setState({
      restorePassword: false,
      registerUser: true,
      password: '',
      error: '',
      nameError: undefined,
      registerUserError: undefined,
    });
  }

  toRestorePassword() {
    this.setState({
      registerUser: false,
      restorePassword: true,
      password: '',
      error: '',
      nameError: undefined,
      passwordError: undefined,
    });
  }

  sendRestorePassword() {
    this.setState({
      restoreSuccess: undefined,
      restorePasswordError: undefined,
    });
    try {
      console.log('Send mail');
      this.setState({
        restoreSuccess: __('Link na obnovu hesla bol zaslaný na Vašu emailovú adresu'),
      });
      // generate
      this.portalPasswordGenerateOnSubmit();
    } catch (e) {
      this.setState({
        restoreSuccess: __('Nepodarilo sa zaslať email'),
      });
    }
  }

  sendRegisterUser() {
    this.setState({
      registerUserSuccess: undefined,
      registerUserError: undefined,
      registerUserSuccessAlert: undefined,
    });
    try {
      console.log('Send mail');
      this.setState({
        registerUserSuccess: __('Účet bol vytvorený'),
        registerUserSuccessAlert: {
          open: true,
          type: 'success',
          message: __('Účet bol vytvorený'),
          description: '',
        },
      });
      // generate
      this.portalPasswordGenerateOnSubmit();
    } catch (e) {
      this.setState({
        registerUserSuccess: __('Nepodarilo sa zaslať email'),
        registerUserSuccessAlert: {
          open: true,
          type: 'success',
          message: __('Nepodarilo sa zaslať email'),
          description: '',
        },
      });
    }
  }

  backToLogin() {
    this.setState({
      registerUser: false,
      restorePassword: false,
      restoreSuccess: undefined,
      restorePasswordError: undefined,
    });
  }

  async portalPasswordGenerateOnSubmit() {
    const { login } = this.state;
    try {
      await API.oauthPortalRestorePasswordRequest({
        data: { username: login },
      });
    } catch (e) {
      //
    }
  }

  validateForm() {
    let isValid = true;
    const { login, password } = this.state;

    if (login.length < 1) {
      isValid = false;
      this.setState({
        nameError: true,
      });
    }

    if (password.length < 1) {
      isValid = false;
      this.setState({
        passwordError: true,
      });
    }

    cookie.save('login', login);

    return isValid;
  }

  async fetchAppFieldContactData() {
    const { id } = this.props;
    const { tabLangActiveKey } = this.state;
    try {
      const appFieldsContactRegister = await API.getPortalAppFieldAction(
        '60a3f0b5d267d2fbb2aa2fd1',
      );
      console.log('-----++ fetchAppFieldContactData');
      console.log(appFieldsContactRegister);

      this.setState({
        appFieldsContactRegisterId: id,
        appFieldsContactRegister: appFieldsContactRegister.app_settings || [],
        appFieldsContactRegisterTranslated: appFieldsContactRegister.app_settings.translations.find(
          (b) => b.lang === tabLangActiveKey,
        )
          ? translate(
              tabLangActiveKey,
              appFieldsContactRegister.app_settings.translations,
              appFieldsContactRegister.app_settings.translations[0].value,
            ).types[0].setting
          : {},
      });
    } catch (e) {
      //
    }
  }

  fetchContacts = (value = '', params = {}) => {
    const { sorter, limit } = this.state;
    this.setState({
      onSearch: value,
      dataContacts: [],
      fetchingContact: true,
    });
    API.getPortalContactsAction({
      limit,
      offset: params.offset,
      sorter,
      q: value,
    }).then((res) => {
      const dataContacts = res.items.map((item) => ({
        surname: item.surname,
        name: item.name,
        _id: item._id,
      }));
      this.setState({
        // contentTable,
        limit: res.limit,
        total: res.total,
        offset: res.offset,
        dataContacts,
        fetchingContact: false,
        nameTotal: res.total,
      });
    });
  };

  fetchContactsEmail = (value = '', params = {}) => {
    const { sorter, limit } = this.state;
    this.setState({
      emailTotal: 0,
      onSearch: value,
      dataContacts: [],
      fetchingContact: true,
    });
    API.getPortalContactsAction({
      // contactType: 'customer',
      limit,
      offset: params.offset,
      sorter,
      q: value,
    }).then((res) => {
      const dataContacts = res.items.map((item) => ({
        surname: item.surname,
        name: item.name,
        _id: item._id,
      }));
      this.setState({
        // contentTable,
        limit: res.limit,
        total: res.total,
        offset: res.offset,
        dataContacts,
        fetchingContact: false,
        emailTotal: res.total,
      });
    });
  };

  checkData() {
    const {
      hasError,
      name,
      surname,
      email,
      mobile,
      acceptConditions,
      acceptGdpr,
      userName,
      userPassword,
      userNameExist,
      emailExist,
      appFieldsContactRegisterTranslated,
    } = this.state;
    let isValid = true;

    /* if (userName.length < 1) {
      isValid = false;
      hasError.userName = {
        open: true,
        message: 'Je nutné vyplniť Prihlasovací email',
      };
    }
    if (userNameExist) {
      isValid = false;
      hasError.userName = {
        open: true,
        message: 'Prihlasovací email už existuje',
      };
    } */

    if (userPassword.length < 6) {
      isValid = false;
      hasError.userPassword = {
        open: true,
        message: 'Je nutné vyplniť Heslo (min. 6 písmen)',
      };
    }

    if (email.length < 1) {
      isValid = false;
      hasError.email = {
        open: true,
        message: 'Je nutné vyplniť Email',
      };
    }
    if (email.length > 0 && !validator.isEmail(email)) {
      isValid = false;
      hasError.email = {
        open: true,
        message: 'Je nutné použiť správny formát pre Email',
      };
    }
    // if (emailTotal > 0) {
    if (emailExist) {
      isValid = false;
      hasError.email = {
        open: true,
        message: 'Email už existuje',
      };
    }

    // Fields Settings
    appFieldsContactRegisterTranslated.map((field) => {
      const fieldKey = field.row.find((d) => d.key === 'key').value;
      const fieldType = field.row.find((d) => d.key === 'type').value;
      const fieldName = field.row.find((d) => d.key === 'name').value;
      const fieldState = field.row.find((d) => d.key === 'state').value;
      const fieldValue = field.row.find((d) => d.key === 'value').value;
      const fieldProps = field.row.find((d) => d.key === 'props').value;
      const fieldRequired = field.row.find((d) => d.key === 'required').value;
      const fieldDescription = field.row.find((d) => d.key === 'description').value;
      const fieldPosition = field.row.find((d) => d.key === 'position').value;
      const fieldSubPosition = field.row.find((d) => d.key === 'subPosition').value;

      if (fieldKey !== 'email' && fieldKey !== 'userPassword') {
        //
        if (
          fieldKey === 'mobile' &&
          fieldState &&
          fieldRequired &&
          this.state[fieldKey].length < 9
        ) {
          // mobile
          isValid = false;
          hasError[fieldKey] = {
            open: true,
            message: `Je nutné vyplniť ${fieldName}`,
          };
        } else if (fieldState && fieldRequired && this.state[fieldKey].length < 1) {
          // other
          isValid = false;
          hasError[fieldKey] = {
            open: true,
            message: `Je nutné vyplniť ${fieldName}`,
          };
        } else if (fieldState && fieldRequired && fieldType === 'Switch' && !this.state[fieldKey]) {
          // Switch
          isValid = false;
          hasError[fieldKey] = {
            open: true,
            message: `Je nutné vyplniť ${fieldName}`,
          };
        }
      }
    });

    this.setState({
      alert: {
        open: !isValid,
        type: 'error',
        message: __('Niečo sa stalo!'),
        description: __('Zle vyplenené povinné hodnoty'),
      },
      hasError,
    });

    return isValid;
  }

  handleChange(type, value) {
    let {
      name,
      surname,
      description,
      email,
      phone,
      mobile,
      web,
      position,
      companyAddress,
      sex,
      isDefault,
      conatctBirthDay,
      conatctBirthMonth,
      conatctBirthYear,
      contactType,
      city,
      street,
      postalCode,
      country,
      valueVirtualDeposit,
      acceptConditions,
      acceptGdpr,
      predvolbaType,
      userName,
      userPassword,
    } = this.state;

    this.setState({
      alert: {
        open: false,
        type: 'info',
        message: '',
        description: '',
      },
      hasError: {},
      userNameExist: false,
      emailExist: false,
    });

    console.log('---handleChange');
    console.log(value);

    if (type === 'name') {
      name = value;
    } else if (type === 'surname') {
      surname = value;
    } else if (type === 'description') {
      description = value;
    } else if (type === 'email') {
      // this.fetchContactsEmail(value);
      email = value;
    } else if (type === 'phone') {
      phone = value;
    } else if (type === 'mobile' && value.length <= 9) {
      const mobileVal = formatNumber(value, 0, '', '');
      mobile = mobileVal.length === 1 && mobileVal === '0' ? '' : mobileVal;
    } else if (type === 'web') {
      web = value;
    } else if (type === 'position') {
      position = value;
    } else if (type === 'companyAddress') {
      companyAddress = value;
    } else if (type === 'sex') {
      sex = value;
    } else if (type === 'isDefault') {
      isDefault = value;
    } else if (type === 'conatctBirthDay') {
      conatctBirthDay = value;
    } else if (type === 'conatctBirthMonth') {
      conatctBirthMonth = value;
    } else if (type === 'conatctBirthYear') {
      conatctBirthYear = value;
    } else if (type === 'contactType') {
      contactType = value;
    } else if (type === 'city') {
      city = value;
    } else if (type === 'street') {
      street = value;
    } else if (type === 'postalCode') {
      postalCode = value;
    } else if (type === 'country') {
      country = value;
    } else if (type === 'valueVirtualDeposit') {
      valueVirtualDeposit = value;
    } else if (type === 'acceptConditions') {
      acceptConditions = value;
    } else if (type === 'acceptGdpr') {
      acceptGdpr = value;
    } else if (type === 'predvolbaType') {
      predvolbaType = value;
    } else if (type === 'userName') {
      userName = value;
    } else if (type === 'userPassword') {
      userPassword = value;
    }

    this.setState({
      userName,
      userPassword,
      name,
      surname,
      description,
      email,
      phone,
      mobile,
      web,
      position,
      companyAddress,
      sex,
      isDefault,
      conatctBirthDay,
      conatctBirthMonth,
      conatctBirthYear,
      contactType,
      city,
      street,
      postalCode,
      country,
      valueVirtualDeposit,
      acceptConditions,
      acceptGdpr,
      predvolbaType,
    });
  }

  // iCONS from SETTiNGS
  IconComponent = (iconName) => {
    let iconComp = '';
    const styles = {
      fontSize: '20px',
      color: 'rgba(0,0,0,.45)',
    };

    if (iconName === 'MailOutlined') {
      iconComp = <MailOutlined style={styles} />;
    } else if (iconName === 'LockOutlined') {
      iconComp = <LockOutlined style={styles} />;
    } else if (iconName === 'SafetyOutlined') {
      iconComp = <SafetyOutlined style={styles} />;
    } else if (iconName === 'IdcardOutlined') {
      iconComp = <IdcardOutlined style={styles} />;
    } else if (iconName === 'UserOutlined') {
      iconComp = <UserOutlined style={styles} />;
    } else if (iconName === 'ContactsOutlined') {
      iconComp = <ContactsOutlined style={styles} />;
    } else if (iconName === 'MobileOutlined') {
      iconComp = <MobileOutlined style={styles} />;
    } else if (iconName === 'contactSexItems') {
      iconComp = <contactSexItems style={styles} />;
    } else if (iconName === 'HomeOutlined') {
      iconComp = <HomeOutlined style={styles} />;
    } else if (iconName === 'EnvironmentOutlined') {
      iconComp = <EnvironmentOutlined style={styles} />;
    } else if (iconName === 'GlobalOutlined') {
      iconComp = <GlobalOutlined style={styles} />;
    } else if (iconName === 'BankOutlined') {
      iconComp = <BankOutlined style={styles} />;
    }

    return iconComp;
  };

  // FiELDS from SETTiNGS

  renderAppFields(renderPosition) {
    const { hasError, contactTypeItems, appFieldsContactRegisterTranslated } = this.state;

    return (
      <React.Fragment>
        <Row gutter={[20, 20]}>
          {appFieldsContactRegisterTranslated.map((field) => {
            const fieldKey = field.row.find((d) => d.key === 'key').value;
            const fieldType = field.row.find((d) => d.key === 'type').value;
            const fieldName = field.row.find((d) => d.key === 'name').value;
            const fieldState = field.row.find((d) => d.key === 'state').value;
            const fieldValue = field.row.find((d) => d.key === 'value').value;
            const fieldProps = field.row.find((d) => d.key === 'props').value;
            const fieldDescription = field.row.find((d) => d.key === 'description').value;
            const fieldPosition = field.row.find((d) => d.key === 'position').value;
            /* const fieldSubPosition = field.row.find(
              d => d.key === 'subPosition',
            ).value; */

            console.log('---fieldProps');
            console.log(fieldProps);
            // console.log(JSON.parse(fieldProps));

            return (
              <React.Fragment>
                {/* TiTLE WRAPPER */}

                {fieldType === 'TitleWrapper' && fieldPosition === renderPosition && fieldState ? (
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={24}
                    xxl={24}
                    // Anchor
                    id="components-anchor-stripe"
                  >
                    <TitleWrapper>
                      <Title level={4} style={{ marginBottom: 0, fontSize: '17px' }}>
                        <ShoppingOutlined style={{ marginRight: '5px' }} />
                        {fieldName}
                      </Title>
                    </TitleWrapper>
                  </Col>
                ) : null}

                {/* FiELDS SELECT */}

                {fieldType === 'Select' && fieldPosition === renderPosition && fieldState ? (
                  <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                    <div
                      className={
                        this.state[fieldKey] !== '' ? 'widget-prefix filled' : 'widget-prefix'
                      }
                    >
                      <Select
                        // allowClear
                        size="large"
                        value={this.state[fieldKey]}
                        style={{ width: '100%' }}
                        placeholder={fieldName}
                        onChange={(value) => this.handleChange(fieldKey, value)}
                      >
                        {this.state[fieldProps]
                          ? this.state[fieldProps].map((item) => (
                              <Option key={item.key}>{item.label}</Option>
                            ))
                          : null}
                      </Select>
                      <div className="floating-icon">
                        <GoldOutlined
                          style={{
                            fontSize: '20px',
                            color: 'rgba(0,0,0,.45)',
                          }}
                        />
                      </div>
                      <div className="floating-label">
                        <CheckCircleFilled
                          theme="filled"
                          style={{
                            color: '#FF6699',
                            fontSize: '16px',
                            marginRight: '5px',
                          }}
                        />
                        {fieldName}
                      </div>
                    </div>
                  </Col>
                ) : null}

                {/* FiELDS RADiO */}

                {fieldType === 'Radio' && fieldPosition === renderPosition && fieldState ? (
                  <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                    <div
                      style={{
                        fontSize: '14px',
                        fontWeight: 'bold',
                      }}
                    >
                      {fieldName}
                    </div>
                    <Radio.Group
                      value={this.state[fieldKey]}
                      size="large"
                      buttonStyle="solid"
                      onChange={(e) => {
                        this.handleChange(fieldKey, e.target.value);
                      }}
                    >
                      {this.state[fieldProps]
                        ? this.state[fieldProps].map((item) => (
                            <Radio.Button value={item.key}>
                              <div>{item.label}</div>
                            </Radio.Button>
                          ))
                        : null}
                      {/* <Radio.Button value="male">
                        <div>
                          {__('Muž')} <ManOutlined />
                        </div>
                      </Radio.Button>
                      <Radio.Button value="female">
                        <div>
                          {__('Žena')} <WomanOutlined />
                        </div>
                      </Radio.Button> */}
                    </Radio.Group>
                  </Col>
                ) : null}

                {/* FiELDS SWiTCH */}

                {fieldType === 'Switch' && fieldPosition === renderPosition && fieldState ? (
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={24}
                    xxl={24}
                    style={{
                      border:
                        hasError[fieldKey] && hasError[fieldKey].open ? '0px solid #f5222d' : '',
                    }}
                  >
                    <Space>
                      <Switch
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        checked={this.state[fieldKey]}
                        defaultChecked={this.state[fieldKey]}
                        onChange={(checked) => {
                          this.handleChange(fieldKey, checked);
                        }}
                      />
                      <div
                        style={{
                          fontSize: '14px',
                          fontWeight: 'bold',
                        }}
                      >
                        {fieldDescription}{' '}
                        <Tooltip placement="right" title={fieldName}>
                          <InfoCircleOutlined />
                        </Tooltip>
                      </div>
                    </Space>
                    <div>
                      {hasError[fieldKey] &&
                      hasError[fieldKey].open &&
                      hasError[fieldKey].message !== '' ? (
                        <p style={{ color: '#f5222d' }}>{hasError[fieldKey].message}</p>
                      ) : (
                        ''
                      )}
                    </div>
                  </Col>
                ) : null}

                {/* FiELDS TEXTAREA */}

                {fieldType === 'TextArea' && fieldPosition === renderPosition && fieldState ? (
                  <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                    <div
                      className={
                        this.state[fieldKey] !== '' ? 'widget-prefix filled' : 'widget-prefix'
                      }
                    >
                      <TextArea
                        size="large"
                        value={this.state[fieldKey]}
                        onChange={(e) => this.handleChange(fieldKey, e.target.value)}
                        placeholder={fieldName}
                        autosize={{ minRows: 3, maxRows: 5 }}
                        style={{
                          fontSize: '16px',
                          borderColor:
                            hasError[fieldKey] && hasError[fieldKey].open ? '#f5222d' : '',
                        }}
                      />
                      {hasError[fieldKey] &&
                      hasError[fieldKey].open &&
                      hasError[fieldKey].message !== '' ? (
                        <p style={{ color: '#f5222d' }}>{hasError[fieldKey].message}</p>
                      ) : (
                        ''
                      )}
                      <div className="floating-icon">
                        <SolutionOutlined
                          style={{
                            fontSize: '20px',
                            color: 'rgba(0,0,0,.45)',
                          }}
                        />
                      </div>
                      <div className="floating-label">
                        <CheckCircleFilled
                          theme="filled"
                          style={{
                            color: '#FF6699',
                            fontSize: '16px',
                            marginRight: '5px',
                          }}
                        />
                        {fieldName}
                      </div>
                    </div>
                  </Col>
                ) : null}

                {/* FiELDS iNPUT NUMBER */}

                {fieldType === 'InputNumber' && fieldPosition === renderPosition && fieldState ? (
                  <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                    <div
                      className={
                        this.state[fieldKey] !== '' ? 'widget-prefix filled' : 'widget-prefix'
                      }
                    >
                      <InputNumber
                        className={hasError[fieldKey] && hasError[fieldKey].open ? 'has-error' : ''}
                        size="large"
                        defaultValue={30}
                        value={this.state[fieldKey]}
                        step={1}
                        min={15}
                        onChange={(e) => this.handleChange(fieldKey, e)}
                      />
                      {hasError[fieldKey] &&
                      hasError[fieldKey].open &&
                      hasError[fieldKey].message !== '' ? (
                        <p style={{ color: '#f5222d' }}>{hasError[fieldKey].message}</p>
                      ) : (
                        ''
                      )}
                      <div className="floating-icon">
                        <ClockCircleOutlined
                          style={{
                            fontSize: '20px',
                            color: 'rgba(0,0,0,.45)',
                          }}
                        />
                      </div>
                      <div className="floating-label">
                        <CheckCircleFilled
                          theme="filled"
                          style={{
                            color: '#FF6699',
                            fontSize: '16px',
                            marginRight: '5px',
                          }}
                        />
                        {fieldName}
                      </div>
                    </div>
                  </Col>
                ) : null}

                {/* FiELDS iNPUT */}

                {fieldType === 'Input' && fieldPosition === renderPosition && fieldState ? (
                  <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                    <div
                      className={
                        this.state[fieldKey] !== '' ? 'widget-prefix filled' : 'widget-prefix'
                      }
                    >
                      <Input
                        className={hasError[fieldKey] && hasError[fieldKey].open ? 'has-error' : ''}
                        size="large"
                        placeholder={fieldName}
                        value={this.state[fieldKey]}
                        allowClear
                        onChange={(e) => this.handleChange(fieldKey, e.target.value)}
                        prefix={
                          fieldProps !== '' ? (
                            <React.Fragment>{this.IconComponent(fieldProps)}</React.Fragment>
                          ) : null
                        }
                      />
                      {hasError[fieldKey] &&
                      hasError[fieldKey].open &&
                      hasError[fieldKey].message !== '' ? (
                        <p style={{ color: '#f5222d' }}>{hasError[fieldKey].message}</p>
                      ) : (
                        ''
                      )}
                      <div className="floating-label">
                        <CheckCircleFilled
                          theme="filled"
                          style={{
                            color: '#FF6699',
                            fontSize: '16px',
                            marginRight: '5px',
                          }}
                        />
                        {fieldName}
                      </div>
                    </div>
                  </Col>
                ) : null}
              </React.Fragment>
            );
          })}
        </Row>
      </React.Fragment>
    );
  }

  render() {
    const {
      alert,
      hasError,
      nameError,
      passwordError,
      login,
      password,
      error,
      restorePasswordError,
      restorePassword,
      restoreSuccess,
      registerUser,
      name,
      surname,
      email,
      mobile,
      city,
      street,
      postalCode,
      saveBtnLoading,
      country,
      acceptConditions,
      acceptGdpr,
      registerUserSuccess,
      predvolbaItems,
      predvolbaType,
      userName,
      userPassword,
      helmetTitle,
      helmetTitleReg,
      helmetTitleFor,
      registerUserSuccessAlert,
    } = this.state;

    // const height = window.innerHeight;

    if (restorePassword) {
      return (
        <React.Fragment>
          <Helmet>
            <title>{`${helmetTitleFor}` /* must be String */}</title>
          </Helmet>

          <Wrapper>
            <Content style={{ margin: '10px auto' }}>
              <Row gutter={[20, 20]}>
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  xxl={24}
                  style={{ textAlign: 'center' }}
                >
                  <img src="/theme/red/images/layout/login/ST_logo_with_heading.svg" alt="logo" />
                </Col>

                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  xxl={24}
                  style={{ textAlign: 'center' }}
                >
                  <Text>{__('Administrácia')}</Text>
                </Col>

                {/* RESTORE :ERROR */}

                {restorePasswordError && (
                  <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                    {/* <ErrorInfo>{restorePasswordError}</ErrorInfo> */}
                    <Alert
                      message={__('Niečo sa stalo')}
                      description={restorePasswordError}
                      type="info"
                      showIcon
                    />
                  </Col>
                )}

                {/* RESTORE :SUCCESS */}

                {restoreSuccess && (
                  <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                    {/* <SuccessInfo>{restoreSuccess}</SuccessInfo> */}
                    <Result
                      status="success"
                      title="Úspech"
                      subTitle={restoreSuccess}
                      extra={[
                        <Button type="primary" key="login" onClick={() => this.backToLogin()}>
                          {__('Späť na prihlásenie')}
                        </Button>,
                      ]}
                    />
                  </Col>
                )}

                {/* RESTORE :SUCCESS */}

                {!restoreSuccess && (
                  <React.Fragment>
                    {/* <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                      <Input
                        allowClear
                        size="large"
                        name="username"
                        error={nameError || error}
                        long
                        placeholder={__('Prihlasovacie meno')}
                        value={login}
                        onChange={e => this.onInputChange('login', e)}
                        prefix={
                          <ProfileOutlined
                            style={{ color: 'rgba(0,0,0,.25)' }}
                          />
                        }
                      />
                    </Col> */}

                    {/* EMAiL */}

                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <div className={email !== '' ? 'widget-prefix filled' : 'widget-prefix'}>
                        <Input
                          className={hasError.email && hasError.email.open ? 'has-error' : ''}
                          name="username"
                          size="large"
                          placeholder={__('Email *')}
                          value={email}
                          allowClear
                          onChange={(e) => this.handleChange('email', e.target.value)}
                          prefix={
                            <MailOutlined
                              style={{
                                fontSize: '20px',
                                color: 'rgba(0,0,0,.45)',
                              }}
                            />
                          }
                        />
                        {hasError.email && hasError.email.open && hasError.email.message !== '' ? (
                          <p style={{ color: '#f5222d' }}>{hasError.email.message}</p>
                        ) : (
                          ''
                        )}
                        <div className="floating-label">
                          <CheckCircleFilled
                            style={{
                              color: '#FF6699',
                              fontSize: '16px',
                              marginRight: '5px',
                            }}
                          />
                          {__('Email *')}
                        </div>
                      </div>
                    </Col>

                    {/* iNFO */}

                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                      <Alert
                        message={__('Čo treba urobiť')}
                        description={__(
                          'Pre zaslanie nového hesla je nutné zadať prihlasovacie meno, ktore ste zvolili pri svojej registrácii',
                        )}
                        type="info"
                        showIcon
                      />
                      {/* <Info>
                        <IconWrapper>
                          <InfoOutlined style={{ color: '#4f97e1' }} />
                        </IconWrapper>
                        <InfoText>
                          {__(
                            'Pre zaslanie nového hesla je nutné zadať prihlasovacie meno, ktore ste zvolili pri svojej registrácii',
                          )}
                        </InfoText>
                      </Info> */}
                    </Col>
                  </React.Fragment>
                )}

                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                  <ButtonWrapper className="form-group">
                    <Link onClick={() => this.backToLogin()}>{__('Späť na prihlásenie')}</Link>

                    {!restoreSuccess && (
                      <Button
                        type="primary"
                        size="large"
                        onClick={() => this.sendRestorePassword()}
                      >
                        {__('Obnoviť heslo')}
                      </Button>
                    )}
                  </ButtonWrapper>
                </Col>
                {/* <StyledFooter>{__('Copyright © 2019')}</StyledFooter> */}
              </Row>
            </Content>
          </Wrapper>
        </React.Fragment>
      );
    }

    /* REGiSTER */

    if (registerUser) {
      return (
        <React.Fragment>
          <Helmet>
            <title>{`${helmetTitleReg}` /* must be String */}</title>
          </Helmet>

          <Wrapper>
            <AnimatedFormMessageWrapper display={alert.open}>
              {alert.open ? (
                <Alert
                  message={alert.message}
                  description={alert.description}
                  // success, info, warning, error
                  type={alert.type}
                  showIcon
                  closable
                  afterClose={() => this.setState({ alert: { open: false } })}
                />
              ) : null}
            </AnimatedFormMessageWrapper>

            <Content style={{ margin: '10px auto' }}>
              {registerUserSuccess && (
                <React.Fragment>
                  {/* <SuccessInfo>{registerUserSuccess}</SuccessInfo> */}
                  <Alert
                    message={registerUserSuccessAlert.message}
                    description={registerUserSuccessAlert.description}
                    type={registerUserSuccessAlert.type}
                    showIcon
                  />
                </React.Fragment>
              )}
              {!registerUserSuccess && (
                <Row gutter={[20, 20]}>
                  {/* LOGO */}

                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={24}
                    xxl={24}
                    style={{ textAlign: 'center' }}
                  >
                    <img src="/theme/red/images/layout/login/ST_logo_with_heading.svg" alt="logo" />
                  </Col>

                  {/* NADPIS */}

                  <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: 'center' }}>
                    <Text>{__('Administrácia')}</Text>
                  </Col>

                  {/* restorePasswordError && (
                    <ErrorInfo>{restorePasswordError}</ErrorInfo>
                  ) */}
                  {/* restoreSuccess && <SuccessInfo>{restoreSuccess}</SuccessInfo> */}

                  {!registerUserSuccess && (
                    <React.Fragment>
                      {/* FiELDS FROM SETTiNGS : MAiN */}

                      <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                        {this.renderAppFields('main')}
                      </Col>
                    </React.Fragment>
                  )}

                  {/* FOOTER */}

                  <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                    <ButtonWrapper className="form-group">
                      <Link onClick={() => this.backToLogin()}>{__('Späť na prihlásenie')}</Link>

                      {!registerUserSuccess && (
                        <Button
                          type="primary"
                          size="large"
                          loading={saveBtnLoading}
                          onClick={() => this.onSubmit()}
                        >
                          {__('Vytvoriť účet')}
                        </Button>
                      )}
                    </ButtonWrapper>
                  </Col>
                </Row>
              )}
            </Content>
          </Wrapper>
        </React.Fragment>
      );
    }

    /* LOGiN */

    return (
      <React.Fragment>
        <Helmet>
          <title>{`${helmetTitle}` /* must be String */}</title>
        </Helmet>

        <form method="post" action={authorizeUrl} onSubmit={() => this.validateForm()}>
          <Wrapper>
            <Content style={{ margin: '10px auto' }}>
              <Row gutter={[20, 20]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                  <Row gutter={[20, 20]}>
                    {/* LOGO */}

                    <Col
                      xs={24}
                      sm={24}
                      md={24}
                      lg={24}
                      xl={24}
                      xxl={24}
                      style={{ textAlign: 'center' }}
                    >
                      <img src="/theme/red/images/layout/login/ST_logo_with_heading.svg" alt="logo" />
                    </Col>

                    {/* TiTLE */}

                    <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: 'center' }}>
                      <Text>{__('Administrácia')}</Text>
                    </Col>

                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      {/* <Input
                        allowClear
                        size="large"
                        name="username"
                        error={nameError}
                        long
                        placeholder={__('Prihlasovacie meno')}
                        value={login}
                        onChange={e => this.onInputChange('login', e)}
                        prefix={
                          <ProfileOutlined
                            style={{ color: 'rgba(0,0,0,.25)' }}
                          />
                        }
                      /> */}
                    </Col>

                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      {/* <Input.Password
                        allowClear
                        size="large"
                        name="password"
                        error={passwordError}
                        borderError={error}
                        long
                        placeholder={__('Heslo')}
                        // type="password"
                        value={password}
                        onChange={e => this.onInputChange('password', e)}
                        prefix={
                          <LockOutlined
                            style={{ color: error ? 'red' : '#ccc' }}
                          />
                        }
                      /> */}
                    </Col>

                    {/* EMAiL */}

                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <div className={email !== '' ? 'widget-prefix filled' : 'widget-prefix'}>
                        <Input
                          className={hasError.email && hasError.email.open ? 'has-error' : ''}
                          size="large"
                          name="username"
                          placeholder={__('Email *')}
                          value={email}
                          allowClear
                          onChange={(e) => this.handleChange('email', e.target.value)}
                          prefix={
                            <MailOutlined
                              style={{
                                fontSize: '20px',
                                color: 'rgba(0,0,0,.45)',
                              }}
                            />
                          }
                        />
                        {hasError.email && hasError.email.open && hasError.email.message !== '' ? (
                          <p style={{ color: '#f5222d' }}>{hasError.email.message}</p>
                        ) : (
                          ''
                        )}
                        <div className="floating-label">
                          <CheckCircleFilled
                            style={{
                              color: '#FF6699',
                              fontSize: '16px',
                              marginRight: '5px',
                            }}
                          />
                          {__('Email *')}
                        </div>
                      </div>
                    </Col>

                    {/* HESLO */}

                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <div
                        className={userPassword !== '' ? 'widget-prefix filled' : 'widget-prefix'}
                      >
                        <Input.Password
                          className={
                            hasError.userPassword && hasError.userPassword.open ? 'has-error' : ''
                          }
                          size="large"
                          name="password"
                          placeholder={__('Heslo *')}
                          value={userPassword}
                          autoComplete="new-password"
                          allowClear
                          onChange={(e) => this.handleChange('userPassword', e.target.value)}
                          prefix={
                            <LockOutlined
                              style={{
                                fontSize: '20px',
                                color: 'rgba(0,0,0,.45)',
                              }}
                            />
                          }
                        />
                        {hasError.userPassword &&
                        hasError.userPassword.open &&
                        hasError.userPassword.message !== '' ? (
                          <p style={{ color: '#f5222d' }}>{hasError.userPassword.message}</p>
                        ) : (
                          ''
                        )}
                        <div className="floating-label">
                          <CheckCircleOutlined
                            theme="filled"
                            style={{
                              color: '#FF6699',
                              fontSize: '16px',
                              marginRight: '5px',
                            }}
                          />
                          {__('Heslo *')}
                        </div>
                      </div>
                    </Col>

                    {/* <ButtonWrapper className="form-group">
                    <LabeledCheckbox labelText={__('Zapamätať si prihlásenie')} />
                  </ButtonWrapper> */}

                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <ButtonWrapper className="form-group">
                        <Link onClick={() => this.toRestorePassword()}>
                          {__('Zabudli ste heslo?')}
                        </Link>

                        <Button
                          type="default"
                          size="large"
                          // href="/register"
                          onClick={() => this.toRegister()}
                        >
                          {__('Zaregistrovať')}
                        </Button>

                        <Button type="primary" size="large" htmlType="submit">
                          {__('Prihlásiť sa')}
                        </Button>
                      </ButtonWrapper>
                    </Col>
                  </Row>
                  {/* <StyledFooter>{__('Copyright © 2019')}</StyledFooter> */}
                </Col>
              </Row>
            </Content>
          </Wrapper>

          <input type="hidden" name="redirect_uri" value={portalUrl} />
          <input type="hidden" name="app_id" value={portalID} />
          <input type="hidden" name="scopes" value="test" />
        </form>
      </React.Fragment>
    );
  }
}

LoginBackup.propTypes = {
  history: PropTypes.object.isRequired, //eslint-disable-line
  match: PropTypes.object.isRequired, //eslint-disable-line
  location: PropTypes.shape({ campaign: PropTypes.string }).isRequired,
};

LoginBackup.defaultProps = {};

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(LoginBackup);
