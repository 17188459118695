import React from 'react';
import cookie from 'react-cookies';
import { Spin } from 'antd';
import styled from 'styled-components';
import Login from '../Containers/Login';
import LoginAnonymous from '../Containers/LoginAnonymous';
import API from '../API';
import { actions } from '../Store';
import { parseHash, __ } from '../Utils';
import cookieConfigAnonymous from '../ContentConfig/cookieAnonymous';
import AclList from '../ACL';

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 29% 0;
`;

API.setBaseUrl(process.env.REACT_APP_DOMAIN);

API.onResponseUnauthorized = () => {
  window.location.href = '/';
  this.removeAnonymousCookie();
};

const authorize = (Component) => {
  return class Auth extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        isLoading: false,
        authorized: false,
        loadedMe: false,
        userId: '',
        // Login Detect
        loadAnonymousUser: true,
        /* loadAnonymousUser: !!(
          (!cookie.load('anonymousAccessToken') && !cookie.load('portalAccessToken')) ||
          (cookie.load('anonymousAccessToken') && !cookie.load('portalAccessToken'))
        ), */
        // Login Detect
        loadEshopUser: !!cookie.load('portalAccessToken'),
      };
    }

    componentDidMount() {
      const { loadAnonymousUser } = this.state;
      const query = parseHash(window.location.hash);
      console.log('ccc');
      console.log(loadAnonymousUser);
      // Alert
      if (query.error) {
        alert(decodeURIComponent(query.error_description));
      }
      // Cookie
      // if (loadAnonymousUser) {
      console.log('loadAnonymousUser');
      // Anonymous Cookie
      if (query.token && query.token !== '') {
        this.removeAnonymousCookie();
        this.setAnonymousCookie(query.token, query.expires_at, query.expires_in);
      }
      const accessToken = this.getAnonymousCookie();
      console.log('--- accessToken');
      console.log(accessToken);
      if (accessToken && accessToken !== 'null') {
        this.login(accessToken);
      } else {
        this.setState({ isLoading: false, authorized: false });
      }
      // }
    }

    // Anonymous
    setAnonymousCookie(token, expirationDate = Date.now() + 3600, maxAge = 3600) {
      cookie.save(cookieConfigAnonymous.name, token, {
        path: '/',
        expires: new Date(expirationDate),
        maxAge,
      });
    }

    getAnonymousCookie() {
      return cookie.load(cookieConfigAnonymous.name);
    }

    // Anonymous
    removeAnonymousCookie() {
      cookie.remove(cookieConfigAnonymous.name, {
        path: '/',
      });
    }

    async login(token) {
      const { loadAnonymousUser } = this.state;

      try {
        this.setState({ isLoading: true });
        API.setToken(token);
        let user;
        if (loadAnonymousUser) {
          console.log('--- token');
          console.log(token);
          // Anonymous
          user = await API.anonymousMeAction();
          console.log('--- user');
          console.log(user);
          // this.setState({ loadedMe: true, userId: user._id });
        }
        actions.setUser(user);

        // Local Storage
        if (localStorage.getItem('appLang') && localStorage.getItem('appLang') !== '') {
          // ...
        } else {
          // app lang
          localStorage.setItem('appLang', 'EN');
        }

        //
        // cookie
        //
        actions.setYear(Number(cookie.load('year')) || new Date().getFullYear());
        window.location.hash = '';

        this.setState({ isLoading: false, authorized: true });
      } catch (e) {
        console.log('--- login e');
        console.log(e);
        window.location.hash = '';
        this.removeAnonymousCookie();
      }
    }

    render() {
      const { loadAnonymousUser, loadEshopUser, authorized, isLoading } = this.state;

      if (!authorized) {
        if (isLoading) {
          return (
            <LoaderWrapper>
              <Spin size="large" />
            </LoaderWrapper>
          );
        }

        return (
          <React.Fragment>
            {loadEshopUser ? <Login /> : null}
            {loadAnonymousUser ? <LoginAnonymous /> : null}
          </React.Fragment>
        );
      }

      if (authorized) {
        return <AclList component={Component} />;
      }
      return (
        <LoaderWrapper>
          <Spin size="large" />
        </LoaderWrapper>
      );
    }
  };
};

export default authorize;
