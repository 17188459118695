/* ----------------------------------------
     Old Browsers
   ----------------------------------------
*/
import './polyfill';
/* eslint-disable import/first */

import React from 'react';
import ReactDOM from 'react-dom';

import { ThemeSwitcherProvider } from 'react-css-theme-switcher';

import registerServiceWorker from './registerServiceWorker';
import ErrorBoundary from './ErrorBoundary';
import authorize from './Auth';
import { Provider } from './Store/index';
import App from './App';

// replace console.* for disable log on production
/* if (process.env.NODE_ENV === 'production') {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
} */

// const Application = authorize(App);
const Application = App;

const themes = {
  light_st: '/less-theme-styles/theme-light___smarttrust.css',
  dark_st: '/less-theme-styles/theme-dark___smarttrust.css',
};

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <Provider>
        <ThemeSwitcherProvider
          insertionPoint={document.getElementById('inject-styles-here')}
          defaultTheme="light_st"
          themeMap={themes}
        >
          <Application />
        </ThemeSwitcherProvider>
      </Provider>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root'),
);
//
// for cashing files
// never see changes imidiatle
// registerServiceWorker();
