import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { rem } from 'polished';
import { enquireScreen } from 'enquire-js';
// anim
import TweenOne from 'rc-tween-one';
// Antd
import Alert from 'antd/es/alert';
import Button from 'antd/es/button';
import Result from 'antd/es/select';
import Space from 'antd/es/space';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import List from 'antd/es/list';
import Badge from 'antd/es/badge';
import Pagination from 'antd/es/pagination';
import Typography from 'antd/es/typography';
import Input from 'antd/es/input';
import Divider from 'antd/es/divider';
import Table from 'antd/es/table';
import Checkbox from 'antd/es/checkbox';
import Modal from 'antd/es/modal';
import Progress from 'antd/es/progress';
import InputNumber from 'antd/es/input-number';
import Slider from 'antd/es/slider';
import Tabs from 'antd/es/tabs';
import Tooltip from 'antd/es/tooltip';
import Empty from 'antd/es/empty';

// icons
import { DeleteOutlined, DeleteTwoTone, SaveTwoTone, PlusSquareTwoTone } from '@ant-design/icons';
//
import API from '../../../API';
import { __ } from '../../../Utils';
import { connect, actions } from '../../../Store';
import SectionHeadingComponent from '../SectionHeadingComponent';

const { Title, Text, Paragraph } = Typography;
const { TextArea, Search } = Input;

class NotFoundPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      //
    };
  }
  componentDidMount() {
    console.log('----------- NotFoundPage props');
    console.log(this.props);
  }

  render() {
    return (
      <React.Fragment>
        <div className="ST-not-found-page">
          <SectionHeadingComponent
            type="page"
            colorType="primary"
            text={__('...OOPS!')}
            indicator="404"
          />
          <div className="ST-not-found-page-content">
            <div className="ST-not-found-page-txt">
              <span>{__(`Looks like the page you are trying to access does not exist.`)}</span>
              <span>{__(`Check the URL, or try again later.`)}</span>
            </div>

            <Link to="/">
              <Button
                htmlType="button"
                type="default"
                className="ST-btn ST-primary-btn ST-not-found-page-btn"
              >
                {__(`Back`)}
              </Button>
            </Link>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

NotFoundPage.propTypes = {
  //
};

NotFoundPage.defaultProps = {
  //
};

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(NotFoundPage);
