import React from 'react';
import PropTypes from 'prop-types';
import { actions } from '../Store';

class StoreSynchorizer extends React.Component {
  componentDidMount() {
    // actions.setAppLang(this.props.appLang);
    // actions.setYear(this.props.year);
  }
  render() {
    return this.props.children;
  }
}

StoreSynchorizer.propTypes = {
  appLang: PropTypes.string,
  // year: PropTypes.number.isRequired,
};

StoreSynchorizer.defaultProps = {
  appLang: 'EN',
};

export default StoreSynchorizer;
