import React from 'react';
// import { Link } from 'react-router-dom';
// import ReactExport from 'react-export-excel';
// import InfiniteScroll from 'react-infinite-scroller';
import { Helmet } from 'react-helmet';
// Antd
import Input from 'antd/es/input';
import Spin from 'antd/es/spin';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Alert from 'antd/es/alert';
import List from 'antd/es/list';
import Badge from 'antd/es/badge';
import Typography from 'antd/es/typography';
import Pagination from 'antd/es/pagination';
import Carousel from 'antd/es/carousel';
import Image from 'antd/es/image';
import Menu from 'antd/es/menu';
import Anchor from 'antd/es/anchor';

import { enquireScreen } from 'enquire-js';

import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rem } from 'polished';
import { __, translate } from '../../Utils';
import PageInfo from '../../Components/PageInfo';
import { AnimatedFormMessageWrapper, LoaderWrapper } from '../../Components/ReusableComponents';
import { connect, actions } from '../../Store';
import API from '../../API';

//
// Layout
import LayoutContent from '../../Layout/LayoutContent';

const { Title, Text, Paragraph } = Typography;
const { Search } = Input;
const { Link } = Anchor;
const { SubMenu } = Menu;

let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

const RowWrapper = styled.div`
  cursor: pointer;
  padding: 10px 30px;
  padding-right: 20px;
  text-align: left;
  background: #fff;
  border: 1px solid #eee;
  &:hover {
    border-left: 3px solid #ff6699;
    background: #fafafa;
    transition: all 0.1s ease-out;
    // box-shadow: 0 0 2px 2px ${({ theme }) => theme.separatorColor};
  }
`;

const PageWrapper = styled.div`
  /* position: fixed;
  bottom: 0;
  left: 0;
  right: 0; */
  background-color: #fff;
  // box-shadow: 0 0 2px 2px ${({ theme }) => theme.separatorColor};
  justify-content: space-between;
  padding: ${rem(10)} ${rem(20)};
  min-height: ${rem(15)};
  // max-width: 1980px;
  margin: auto;
  font-size: ${({ theme }) => theme.fontSize || rem(11)};
`;

const Content = styled.div`
  margin: ${rem(3)} auto;
`;

const Wrapper = styled.div`
  // overflow: hidden;
  width: 100%;
  // max-width: 1340px;
  margin: auto;
  padding-left: 0;
  padding-right: 0;
`;

class ContentPageCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: {
        open: false,
        type: 'info',
        message: '',
        description: '',
      },
      showPager: true,
      loading: false,
      limit: 20,
      offset: 0,
      total: 0,
      dataContentPages: [],
      dataContentPage: [],
      helmetTitle: 'App',
      contentLayoutTranslated: [],
      contentLayout: {},
      isMobile,
      selectedContentType: 'content_page',
      tabLangActiveKey: 'EN',
    };

    actions.setHeader(__('Pages'));
    this.mainWrapper = {};
  }

  componentDidMount() {
    const { match, location } = this.props;
    console.log('-----------props');
    console.log(this.props);

    // isMobile
    enquireScreen((b) => {
      this.setState({
        isMobile: !!b,
      });
    });

    // fields
    this.fetchAppFieldContentPageData();

    // Pages
    this.fetchContentPages();

    //
    //
    if (match.url === '/') {
      // page
      // home : O
      this.fetchContentPageData('6168a40a4283175e01b58136');
      //
      this.setState({
        showContentPages: false,
      });
    } else if (match.params.id) {
      // page
      this.fetchContentPageData(match.params.id);
      this.setState({
        showContentPages: false,
      });
    } else if (match.url === '/pages') {
      this.setState({
        showContentPages: true,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { location, match } = this.props;
    const { helmetTitle } = this.state;
    // console.log('--------- = did Upd');
    // console.log(this.props);
    // console.log(location);
    // console.log(prevProps.location);
    // console.log(window);

    // If Route has changed, update state (Ensures consistency between location state and Component state).
    if (location !== prevProps.location) {
      // console.log('--------- = did Upd Lo');
      // console.log(this.props);

      // page
      // this.fetchContentPageData(match.params.id);

      // scroll to Top
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });

      if (location && location.state && location.state.helmetTitle) {
        this.setState({ helmetTitle: location.state.helmetTitle });
      }
    }
  }

  onChangeContentPage(item) {
    const { history, location } = this.props;

    // history.replace(url, state)
    history.replace(`/pages/${item.seoSlugTranslated}/${item._id}`, {
      helmetTitle: item.nameTranslated,
    });

    // window.history.pushState(state, title, url)
    window.history.pushState(
      { helmetTitle: item.nameTranslated },
      item.nameTranslated,
      `/pages/${item.seoSlugTranslated}/${item._id}`,
    );

    // Page
    this.fetchContentPageData(item._id);
  }

  async fetchAppFieldContentPageData() {
    const { id } = this.props;
    try {
      const appFieldContentPage = await API.getPortalAppFieldAction('60ae4d14cf8fafc054f596d3');
      console.log('-----------appFieldContentPage');
      console.log(appFieldContentPage);
      //
      const contentTypes = appFieldContentPage
        ? appFieldContentPage.app_settings.translations[0].value.types[0].setting
            .filter((d) => d.row.find((o) => o.key === 'key').value === 'contentType')[0]
            .row.find((d) => d.key === 'type')
            .props.find((d) => d.key === 'options').value
        : [];
      // console.log('-----------contentTypes');
      // console.log(contentTypes);

      this.setState({
        appFieldContentPageId: id,
        appFieldContentPage,
      });
    } catch (e) {
      //
    }
  }

  async fetchContentPageData(id) {
    try {
      const { tabLangActiveKey } = this.state;
      let { helmetTitle } = this.state;

      this.setState({
        dataContentPageLoading: true,
        // showContentPages: false,
      });

      const item = await API.getPortalContentPageAction(id);
      // console.log('-----------contentPage');
      // console.log(item);

      const dataContentPage = [
        {
          // text: `${item.name} | ${item.description}`,
          _id: item._id,
          contentType: item.contentType,
          typeByYear: item.typeByYear,
          loadContentType: item.loadContentType,
          published: item.published[0],
          name: item.name || '',
          nameTranslated: translate(
            item.published[0],
            item.name.translations,
            item.name.translations[0].value,
          ),
          perex: item.perex || '',
          perexTranslated: item.perex
            ? translate(
                item.published[0],
                item.perex.translations,
                item.perex.translations[0].value,
              )
            : '',
          text: item.text || '',
          textTranslated: item.text
            ? translate(item.published[0], item.text.translations, item.text.translations[0].value)
            : '',
          // category: item.category ? item.category : '',
          // thumbnail: item.thumbnail,
          images: item.images || [],
          videos: item.videos || '',
          // videoLabel: item.video_label,
          priority: item.priority,
          contentAnchorMenu: item.contentAnchorMenu || '',
          // seo
          seoSlugTranslated: translate(
            item.published[0],
            item.seoSlug.translations,
            item.seoSlug.translations[0].value,
          ),
          // Layout
          contentLayout: item.contentLayout,
          // Layout Grid
          app_layout: item.app_layout,
          app_layoutGridKey: item.app_layout
            ? item.app_layout.find((layout) => layout.key === 'grid').value[0].value
            : 'grid_FULL_W',
        },
      ];
      // console.log('-----------dataContentPage');
      // console.log(dataContentPage);

      //
      // Layout
      // from content data.
      let contentLayoutData = {};
      if (
        // check
        dataContentPage[0].contentLayout &&
        dataContentPage[0].contentLayout.translations.length > 0
      ) {
        //
        contentLayoutData = { translations: [] };
        // JSON parse to all Translations
        dataContentPage[0].contentLayout.translations.map((d, i) => {
          if (d.lang && d.value.length > 0) {
            contentLayoutData.translations.push({
              lang: d.lang,
              value: JSON.parse(d.value),
            });
          }
        });
      }

      // helmetTitle
      helmetTitle = dataContentPage[0].nameTranslated;

      // state
      this.setState({
        dataContentPage,
        dataContentPageLoading: false,
        // Anchor menu
        contentAnchorMenu: dataContentPage[0].contentAnchorMenu,
        // helmet
        helmetTitle,
        // Layout
        //
        contentLayout: contentLayoutData,
        contentLayoutTranslated:
          contentLayoutData &&
          contentLayoutData.translations &&
          contentLayoutData.translations.length > 0 &&
          contentLayoutData.translations.find((b) => b.lang === tabLangActiveKey)
            ? // JSON.parse(
              translate(
                tabLangActiveKey,
                contentLayoutData.translations,
                contentLayoutData.translations[0].value,
              )
            : // )
              [],
      });
    } catch (e) {
      if (e.response) {
        switch (e.response.status) {
          case 403:
            this.setState({
              error: __('Na zobrazenie aktivity nemáte potrebné práva.'),
            });
            break;
          case 404:
            this.setState({
              error: __('Aktivita sa nenašla'),
            });
            break;
          default:
            this.setState({
              error: __('Pri načítavaní dát sa vyskytla chyba'),
            });
            break;
        }
      }
    } finally {
      this.setState({
        loading: false,
      });
    }
  }

  async fetchContentPages(value = '', params = {}) {
    const { selectedContentType } = this.state;
    this.setState({
      // onSearch: value,
      dataContentPages: [],
      dataContentPagesLoading: true,
      // showContentPages: true,
    });
    API.getPortalContentPageListAction({
      filter: 'published_en',
      contentType: params && params.selected ? params.selected : selectedContentType,
      q: value,
    }).then((res) => {
      // console.log('-----------fetchContentPages res');
      // console.log(res);

      const dataContentPages = res.items.map((item) => ({
        _id: item._id,
        contentType: item.contentType,
        loadContentType: item.loadContentType,
        published: item.published[0],
        name: item.name.translations[0].value,
        nameTranslated: translate(
          item.published[0],
          item.name.translations,
          item.name.translations[0].value,
        ),
        perex: item.perex ? item.perex.translations[0].value : '',
        perexTranslated: item.perex
          ? translate(item.published[0], item.perex.translations, item.perex.translations[0].value)
          : '',
        text: item.text ? item.text.translations[0].value : '',
        textTranslated: item.text
          ? translate(item.published[0], item.text.translations, item.text.translations[0].value)
          : '',
        published_from: item.published_from,
        published_to: item.published_to,
        nameColor: item.color,
        thumbnail: item.thumbnail,
        images: item.images ? item.images : [],
        videos: item.videos,
        videoLabel: item.video_label,
        priority: item.priority,
        // seo
        seoSlugTranslated: translate(
          item.published[0],
          item.seoSlug.translations,
          item.seoSlug.translations[0].value,
        ),
      }));
      // console.log('-----------fetchContentPages');
      // console.log(dataContentPages);

      this.setState({
        // contentTable,
        limit: res.limit,
        total: res.total,
        offset: res.offset,
        dataContentPages,
        dataContentPagesLoading: false,
      });
    });
  }

  renderPageNavigationTable(limit, offset, total) {
    return (
      <PageWrapper>
        <PageInfo offset={offset} limit={limit} total={total} />
        <Pagination
          showQuickJumper
          defaultCurrent={offset / limit + 1}
          pageSize={limit}
          total={total}
          onChange={(pageNumber) => this.onPageChange(pageNumber)}
        />
      </PageWrapper>
    );
  }

  renderContentLayoutForTranslate() {
    const { contentLayoutTranslated, contentLayout, tabLangActiveKey, dataContentPage } =
      this.state;

    const { match } = this.props;

    return (
      <React.Fragment>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <div
              // ref={val => this.dragulaStageLowRef(val)}
              id="stage-container-low"
              className="stage-container edit-stage"
            >
              {contentLayoutTranslated && contentLayoutTranslated.length > 0
                ? contentLayoutTranslated.map((item, i) => {
                    const gi = i;
                    return (
                      <div
                        // !!! data key must by in Stage Same as Cloned
                        data-key={item._id}
                        key={`${item.key ? item._id : item._id}_${gi}`}
                        id={`${item._id}_${gi}`}
                        className="RowWrapper overlay-elem"
                        style={{
                          width: '100%',
                          padding: '0',
                        }}
                      >
                        {item._id.search('LayoutContent') !== -1 ? (
                          <LayoutContent
                            {...this.props}
                            // dragRef={this.dragulaDecorator}
                            id="LayoutContent_0"
                            key="LayoutContent_0"
                            dataSource={JSON.parse(
                              translate(
                                tabLangActiveKey,
                                contentLayout.translations,
                                contentLayout.translations[0].value,
                              ).filter((d) => d._id === item._id)[0].dataSourceStr,
                            )}
                            appCategories={{}}
                            data={dataContentPage[0]}
                            tabLangActiveKey={tabLangActiveKey}
                            // content id
                            appContentDataId={match.params.id}
                            // fields
                            appFieldContentTranslated={contentLayoutTranslated}
                            // all states
                            contentData={dataContentPage[0]}
                          />
                        ) : null}
                      </div>
                    );
                  })
                : null}
            </div>
          </Col>
        </Row>
      </React.Fragment>
    );
  }

  render() {
    const {
      alert,
      limit,
      offset,
      total,
      loading,
      showPager,
      dataContentPagesLoading,
      dataContentPages,
      helmetTitle,
      showContentPages,
      dataContentPage,
      contentLayoutTranslated,
    } = this.state;

    return (
      <React.Fragment>
        <Helmet>
          <title>{`${helmetTitle}` /* must be String */}</title>
        </Helmet>

        {/* Menu */}

        <div
          ref={(ref) => {
            this.mainWrapper = ref;
          }}
        >
          <AnimatedFormMessageWrapper display={alert.open}>
            <Alert
              message={alert.message}
              description={alert.description}
              type={alert.type}
              showIcon
              closable
            />
          </AnimatedFormMessageWrapper>

          {/* CONTENT */}

          <Wrapper>
            <Content
              // Anchor
              id="components-anchor-content"
            >
              <Row gutter={[0, 0]}>
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  xxl={24}
                  // style={{ background: '#ddd' }}
                >
                  <Row gutter={[0, 0]}>
                    {/* PAGE */}

                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                      {dataContentPage[0] && dataContentPage[0].app_layout ? (
                        <Row
                          // style={{ maxWidth: '1340px', margin: '0 auto' }}
                          gutter={[
                            dataContentPage[0].app_layout.find((layout) => layout.key === 'gutters')
                              .value,
                            dataContentPage[0].app_layout.find(
                              (layout) => layout.key === 'vgutters',
                            ).value,
                          ]}
                        >
                          {contentLayoutTranslated &&
                          contentLayoutTranslated.length > 0 &&
                          dataContentPage[0].app_layout
                            ? dataContentPage[0].app_layout
                                .find((layout) => layout.key === 'grid')
                                .value[0].grid.map((grid) =>
                                  grid.key === 'main' ? (
                                    <React.Fragment>
                                      {/* LAYOUT */}
                                      <Col
                                        xs={24}
                                        sm={24}
                                        md={24}
                                        lg={grid.size}
                                        xl={grid.size}
                                        xxl={grid.size}
                                      >
                                        <div
                                          style={{
                                            // minHeight: '700px',
                                            marginBottom: '36px',
                                          }}
                                        >
                                          {/* Must by alone to not rerender is state change */}
                                          {this.renderContentLayoutForTranslate()}
                                        </div>
                                      </Col>
                                    </React.Fragment>
                                  ) : null,
                                )
                            : null}
                        </Row>
                      ) : null}
                    </Col>

                    {/* PAGES */}

                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                      <Row gutter={[0, 0]}>
                        {showContentPages ? (
                          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                            <div
                              style={{
                                padding: '10px 0',
                              }}
                            >
                              <div
                                style={{
                                  minHeight: '700px',
                                  padding: '8px 24px',
                                  border: '0px solid #e8e8e8',
                                  borderRadius: '4px',
                                }}
                              >
                                <Badge
                                  count={dataContentPages.length}
                                  style={{
                                    zIndex: 1,
                                    backgroundColor: '#FF6699',
                                  }}
                                  offset={[10, 5]}
                                >
                                  <Title level={4} style={{ fontSize: '17px' }}>
                                    {__('Pages')}
                                  </Title>
                                </Badge>

                                <List
                                  grid={{
                                    gutter: 16,
                                    column: 2,
                                    xs: 1,
                                    sm: 1,
                                    md: 2,
                                    lg: 2,
                                    xl: 2,
                                    xxl: 2,
                                  }}
                                  loading={dataContentPagesLoading}
                                  itemLayout="horizontal"
                                  dataSource={dataContentPages}
                                  renderItem={(item) => (
                                    <List.Item style={{ marginBottom: '16px' }}>
                                      <List.Item.Meta
                                        description={
                                          <RowWrapper
                                            gutter={[0, 0]}
                                            style={{
                                              zIndex: 0,
                                              position: 'relative',
                                              padding: 0,
                                            }}
                                            onClick={() => this.onChangeContentPage(item)}
                                          >
                                            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                                              <Row gutter={[0, 0]}>
                                                <Col
                                                  xs={24}
                                                  sm={24}
                                                  md={24}
                                                  lg={24}
                                                  xl={24}
                                                  xxl={24}
                                                  style={{
                                                    padding: '0.5rem',
                                                    textAlign: 'left',
                                                  }}
                                                >
                                                  <Paragraph ellipsis>
                                                    <Text
                                                      style={{
                                                        fontSize: '16px',
                                                        fontWeight: '400',
                                                        color: '#555',
                                                      }}
                                                    >
                                                      {item.nameTranslated}
                                                    </Text>
                                                  </Paragraph>
                                                </Col>
                                              </Row>
                                            </Col>
                                          </RowWrapper>
                                        }
                                      />
                                    </List.Item>
                                  )}
                                />
                              </div>
                            </div>
                            <div>
                              {showPager && total > 0
                                ? this.renderPageNavigationTable(limit, offset, total)
                                : ''}
                            </div>
                          </Col>
                        ) : null}
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Content>
          </Wrapper>

          {loading && (
            <LoaderWrapper>
              <Spin size="large" />
            </LoaderWrapper>
          )}
        </div>
      </React.Fragment>
    );
  }
}

ContentPageCard.propTypes = {
  history: PropTypes.objectOf({}).isRequired,
  location: PropTypes.objectOf({}).isRequired,
  match: PropTypes.objectOf({}).isRequired,
  // contentPageId: PropTypes.string,
  // close: PropTypes.bool,
};

ContentPageCard.defaultProps = {
  // contentPageId: null,
  // close: false,
};

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(ContentPageCard);
