import React from 'react';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
// Antd
import Divider from 'antd/es/divider';

import { enquireScreen } from 'enquire-js';
import actions from '../Store/actions';

import Login from '../Containers/Login';
import LoginBackup from '../Containers/Login/index__backup';
import RestorePassword from '../Containers/RestorePassword';
import ContentPageCard from '../Containers/ContentPageCard';
// Nav
import LayoutHeader from '../Layout/LayoutHeader';
//
import NotFoundPage from '../Layout/Containers/NotFoundPageComponent';
//
let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

class Router extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mounted: false,
      isMobile,
      activeMenus: { height: { total: 145 } },
    };
  }

  //
  // ---
  //

  componentDidMount() {
    this.setState({ mounted: true });

    enquireScreen((b) => {
      this.setState({ isMobile: !!b });
    });
  }

  //
  // ---
  //

  // Menu Category :Click
  onCategoriesMenuClicked(item) {
    const { history, location } = this.props;

    // console.log('selected i', item);

    // history.replace(url, state)
    history.replace(location.pathname, { helmetTitle: item.title });

    window.history.pushState({ helmetTitle: item.title }, item.title, `/categories/${item.key}`);
  }

  //
  // ---
  //

  render() {
    const { redirect, match, history, location } = this.props;
    const { activeMenus } = this.state;

    return (
      <React.Fragment>
        <div
          style={{
            position: 'relative',
            // height: `${activeMenus.height.total}px`,
            height: '100%',
            width: '100%',
          }}
        >
          {/* <LayoutHeader
            // dragRef={this.dragulaDecorator}
            id="Nav3_0"
            key="Nav3_0"
            dataSource=""
            isMobile={this.state.isMobile}
            history={history}
            location={location}
            loadEshopUser
            categoriesMenuClicked={(val) => this.onCategoriesMenuClicked(val)}
            doLogout={() => actions.logout()}
            activeMenusData={(opts) =>
              this.setState({
                activeMenus: opts,
              })
            }
          />
          <Divider
            style={{
              marginTop: '0px',
              marginBottom: `${activeMenus.height.total}px`,
            }}
          /> */}

          <BrowserRouter>
            <Switch>
              {redirect && <Route render={() => <Redirect to={redirect} />} />}
              <Route exact path="/" component={Login} history={history} match={match} />
              <Route exact path="/login" component={Login} history={history} match={match} />
              <Route
                exact
                path="/login-old"
                component={LoginBackup}
                history={history}
                match={match}
              />
              {/* <Route exact path="/register" component={Login} history={history} match={match} /> */}
              <Route exact path="/register-me" component={Login} history={history} match={match} />
              <Route
                path="/setPassword/:token"
                component={RestorePassword}
                history={history}
                match={match}
              />
              <Route
                exact
                path="/pages"
                component={ContentPageCard}
                history={history}
                match={match}
              />
              <Route
                exact
                path="/pages/:slug/:id"
                component={ContentPageCard}
                history={history}
                match={match}
              />
              <Route path="*" component={NotFoundPage} history={history} match={match} />
            </Switch>
          </BrowserRouter>
        </div>
      </React.Fragment>
    );
  }
}

Router.propTypes = {
  history: PropTypes.object.isRequired, //eslint-disable-line
  match: PropTypes.object.isRequired, //eslint-disable-line
  redirect: PropTypes.string,
};

Router.defaultProps = {
  redirect: undefined,
};

export default Router;
