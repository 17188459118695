/* eslint-disable */
import 'isomorphic-fetch';

class API {
  constructor() {
    this.baseUrl = null;
    this.token = null;
  }

  serializeQueryParams(parameters) {
    const str = [];
    for (let p in parameters) {
      if (parameters.hasOwnProperty(p)) {
        str.push(
          `${encodeURIComponent(p)}=${encodeURIComponent(parameters[p])}`,
        );
      }
    }
    return str.join('&');
  }

  paramsToObject(params) {
    let query = params.substr(1);
    let result = {};
    query.split('&').forEach(function(part) {
      let item = part.split('=');
      result[item[0]] = decodeURIComponent(item[1]);
    });
    return result;
  }

  mergeQueryParams(parameters, queryParameters) {
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        const parameter = parameters.$queryParameters[parameterName];
        queryParameters[parameterName] = parameter;
      });
    }
    return queryParameters;
  }

  /**
   * HTTP Request
   * @method
   * @param {string} method - http method
   * @param {string} url - url to do request
   * @param {object} body - body parameters / object
   * @param {object} headers - header parameters
   * @param {object} queryParameters - querystring parameters
   */
  request(
    method,
    url,
    body,
    headers,
    queryParameters,
    form,
    checkFor401 = true,
  ) {
    const queryParams =
      queryParameters && Object.keys(queryParameters).length
        ? this.serializeQueryParams(queryParameters)
        : null;
    const urlWithParams = url + (queryParams ? '?' + queryParams : '');

    // ugly hack, we need to delete Content-Type header with multipart/form-data
    // that way, browser will calculate form specific headers on it's own
    // contentTypeHeader[0] because nearly every header's value is set using array
    const contentTypeHeader = headers['Content-Type'];
    if (contentTypeHeader && contentTypeHeader[0] === 'multipart/form-data') {
      delete headers['Content-Type'];
    }

    if (body && !Object.keys(body).length) {
      body = undefined;
    } else {
      body = JSON.stringify(body);
    }

    if (form && Object.keys(form).length) {
      body = new FormData();
      for (let k in form) {
        body.append(k, form[k]);
      }
    }

    return fetch(urlWithParams, {
      method,
      headers,
      body,
    })
      .then(response => {
        if (checkFor401) {
          if (response.status === 401) {
            if (typeof this._onResponseUnauthorized === 'function') {
              this._onResponseUnauthorized();
            } else {
              let error = new Error(response.statusText);
              error.response = response;
              throw error;
            }
          }
        }

        if (response.ok) {
          if (
            response.headers.get('Content-Type').includes('application/json')
          ) {
            return response.json();
          } else if (
            response.headers.get('Content-Type').includes('application/pdf')
          ) {
            return response.blob();
          }
          return {};
        } else {
          let error = new Error(response.statusText);
          error.response = response;
          throw error;
        }
      })
      .catch(error => {
        return error.response.json().then(error_details => {
          error.details = error_details;
          throw error;
        });
      });
  }

  /**
   * Set base url
   * @method
   * @param {string} baseUrl
   */
  setBaseUrl(baseUrl) {
    this.baseUrl = baseUrl;
  }

  set onResponseUnauthorized(callback) {
    this._onResponseUnauthorized = callback;
  }

  /**
   * Redirects a user to a given url
   * @method
   * @param {string} url
   */
  redirect(url) {
    window.location = url;
  }

  /**
   * Set Token
   * @method
   * @param {string} token - token's value
   */
  setToken(token) {
    this.token = token;
  }

  /**
   * Set Auth headers
   * @method
   * @param {object} headerParams - headers object
   */
  setAuthHeaders(headerParams) {
    let headers = headerParams ? headerParams : {};
    if (this.token) {
      headers['Authorization'] = 'Bearer ' + this.token;
    }
    return headers;
  }

  /**
 * Get token informations.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {} parameters.data - 
 */
  oauthTokenInfoAction(parameters = {}) {
    let path = '/oauth/tokeninfo';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    if (parameters['data'] === undefined) {
      throw Error('Missing required  parameter: data');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Revoke the access token.

 * @method
 * @param {object} parameters - method options and parameters
   * @param {} parameters.data - 
 */
  oauthRevokeAction(parameters = {}) {
    let path = '/oauth/revoke';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    if (parameters['data'] === undefined) {
      throw Error('Missing required  parameter: data');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Send request for email restoration.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data -
   */
  oauthPortalRestorePasswordRequest(parameters = {}) {
    let path = '/oauth/portal/restore-password';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    if (parameters['data'] === undefined) {
      throw Error('Missing required  parameter: data');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Get password reset token info.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} passwordToken -
   */
  oauthPortalRestorePasswordInfo(passwordToken, parameters = {}) {
    let path = '/oauth/portal/restore-password/{passwordToken}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{passwordToken}', passwordToken);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Send request for email restoration.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} passwordToken -
   * @param {} parameters.data -
   */
  oauthPortalRestorePassword(passwordToken, parameters = {}) {
    let path = '/oauth/portal/restore-password/{passwordToken}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{passwordToken}', passwordToken);

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    if (parameters['data'] === undefined) {
      throw Error('Missing required  parameter: data');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Send request for email kyc.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data -
   */
  oauthPortalKycRequest(parameters = {}) {
    let path = '/oauth/portal/kyc';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    if (parameters['data'] === undefined) {
      throw Error('Missing required  parameter: data');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Get kyc token info.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} passwordToken -
   */
  oauthPortalKycInfo(passwordToken, parameters = {}) {
    let path = '/oauth/portal/kyc/{passwordToken}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{passwordToken}', passwordToken);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Send request for email kyc.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} passwordToken -
   * @param {} parameters.data -
   */
  oauthPortalKyc(passwordToken, parameters = {}) {
    let path = '/oauth/portal/kyc/{passwordToken}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{passwordToken}', passwordToken);

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    if (parameters['data'] === undefined) {
      throw Error('Missing required  parameter: data');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Authorize Anonymous user.
   * @method
   * @param {object} parameters - method options and parameters
   */
  oauthAuthorizeGetAnonymousAction(parameters = {}) {
    let path = '/oauth/authorize/anonymous';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers['Accept'] = ['text/html'];
    headers['Content-Type'] = ['application/x-www-form-urlencoded'];

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Authorize Anonymous user.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.redirectUri -
   * @param {string} parameters.appId -
   * @param {array} parameters.scopes -
   * @param {string} parameters.username -
   * @param {string} parameters.password -
   * @param {string} parameters.state -
   */
  oauthAuthorizeAnonymousAction(parameters = {}) {
    let path = '/oauth/authorize/anonymous';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers['Accept'] = ['text/html'];
    headers['Content-Type'] = ['application/x-www-form-urlencoded'];

    if (parameters['redirectUri'] !== undefined) {
      form['redirect_uri'] = parameters['redirectUri'];
    }

    if (parameters['redirectUri'] === undefined) {
      throw Error('Missing required  parameter: redirectUri');
    }

    if (parameters['appId'] !== undefined) {
      form['app_id'] = parameters['appId'];
    }

    if (parameters['appId'] === undefined) {
      throw Error('Missing required  parameter: appId');
    }

    if (parameters['scopes'] !== undefined) {
      form['scopes'] = parameters['scopes'];
    }

    if (parameters['scopes'] === undefined) {
      throw Error('Missing required  parameter: scopes');
    }

    if (parameters['username'] !== undefined) {
      form['username'] = parameters['username'];
    }

    if (parameters['username'] === undefined) {
      throw Error('Missing required  parameter: username');
    }

    if (parameters['password'] !== undefined) {
      form['password'] = parameters['password'];
    }

    if (parameters['password'] === undefined) {
      throw Error('Missing required  parameter: password');
    }

    if (parameters['state'] !== undefined) {
      queryParameters['state'] = parameters['state'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
 * Get informations about Anonymous user.

 * @method
 * @param {object} parameters - method options and parameters
 */
  anonymousMeAction(parameters = {}) {
    let path = '/anonymous/me';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Get user acl list.
   * @method
   * @param {object} parameters - method options and parameters
   */
  getUserAcl(parameters = {}) {
    let path = '/anonymous/user-acl';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * List of customer contacts.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} customerId - Customer id for which must be data loaded
   */
  getPortalCustomerContactsAction(customerId, parameters = {}) {
    let path = '/anonymous/customers/{customerId}/contacts';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{customerId}', customerId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Create new contact.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} customerId - Customer id for which must be data loaded
   * @param {} parameters.data - The contact data.
   */
  postPortalCustomerContactAction(customerId, parameters = {}) {
    let path = '/anonymous/customers/{customerId}/contacts';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{customerId}', customerId);

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * List of contacts.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {string} parameters.contactType -
   * @param {boolean} parameters.active -
   * @param {boolean} parameters.onlyOwned -
   * @param {boolean} parameters.deleted -
   * @param {string} parameters.sorter -
   * @param {string} parameters.q -
   */
  getPortalContactsAction(parameters = {}) {
    let path = '/anonymous/contacts';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    /** set default value **/
    queryParameters['limit'] = '500';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    if (parameters['contactType'] !== undefined) {
      queryParameters['contactType'] = parameters['contactType'];
    }

    if (parameters['active'] !== undefined) {
      queryParameters['active'] = parameters['active'];
    }

    /** set default value **/
    queryParameters['onlyOwned'] = 'true';

    if (parameters['onlyOwned'] !== undefined) {
      queryParameters['onlyOwned'] = parameters['onlyOwned'];
    }

    if (parameters['deleted'] !== undefined) {
      queryParameters['deleted'] = parameters['deleted'];
    }

    if (parameters['sorter'] !== undefined) {
      queryParameters['sorter'] = parameters['sorter'];
    }

    if (parameters['q'] !== undefined) {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * List app-fields.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {string} parameters.sorter -
   * @param {boolean} parameters.onlyOwned -
   * @param {string} parameters.q -
   */
  getPortalAppFieldsAction(parameters = {}) {
    let path = '/anonymous/app-fields';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    /** set default value **/
    queryParameters['limit'] = '500';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    if (parameters['sorter'] !== undefined) {
      queryParameters['sorter'] = parameters['sorter'];
    }

    /** set default value **/
    queryParameters['onlyOwned'] = 'true';

    if (parameters['onlyOwned'] !== undefined) {
      queryParameters['onlyOwned'] = parameters['onlyOwned'];
    }

    if (parameters['q'] !== undefined) {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Details of app-fields.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} appFieldsId - ID of app fields
   */
  getPortalAppFieldAction(appFieldsId, parameters = {}) {
    let path = '/anonymous/app-fields/{appFieldsId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{appFieldsId}', appFieldsId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * List app-ui.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {string} parameters.sorter -
   * @param {boolean} parameters.onlyOwned -
   * @param {string} parameters.q -
   */
  getPortalAppUisAction(parameters = {}) {
    let path = '/anonymous/app-ui';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    /** set default value **/
    queryParameters['limit'] = '500';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    if (parameters['sorter'] !== undefined) {
      queryParameters['sorter'] = parameters['sorter'];
    }

    /** set default value **/
    queryParameters['onlyOwned'] = 'true';

    if (parameters['onlyOwned'] !== undefined) {
      queryParameters['onlyOwned'] = parameters['onlyOwned'];
    }

    if (parameters['q'] !== undefined) {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Details of app-ui.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} appUisId - ID of app uis
   */
  getPortalAppUiAction(appUisId, parameters = {}) {
    let path = '/anonymous/app-ui/{appUisId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{appUisId}', appUisId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Get published LayoutTemplates.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {string} parameters.q -
   * @param {string} parameters.filter -
   * @param {string} parameters.sorter -
   * @param {boolean} parameters.onlyOwned -
   */
  getPortalLayoutTemplateListAction(parameters = {}) {
    let path = '/anonymous/layout-templates';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    /** set default value **/
    queryParameters['limit'] = '500';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    if (parameters['q'] !== undefined) {
      queryParameters['q'] = parameters['q'];
    }

    if (parameters['filter'] !== undefined) {
      queryParameters['filter'] = parameters['filter'];
    }

    if (parameters['sorter'] !== undefined) {
      queryParameters['sorter'] = parameters['sorter'];
    }

    /** set default value **/
    queryParameters['onlyOwned'] = 'true';

    if (parameters['onlyOwned'] !== undefined) {
      queryParameters['onlyOwned'] = parameters['onlyOwned'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Get LayoutTemplates detail.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} layoutTemplateId - ID of layout template
   */
  getPortalLayoutTemplateAction(layoutTemplateId, parameters = {}) {
    let path = '/anonymous/layout-templates/{layoutTemplateId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{layoutTemplateId}', layoutTemplateId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Get published Content page.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {string} parameters.q -
   * @param {string} parameters.contentType -
   * @param {string} parameters.typeByYear -
   * @param {string} parameters.subType -
   * @param {string} parameters.subTypeTag -
   * @param {string} parameters.filter -
   * @param {string} parameters.sorter -
   * @param {boolean} parameters.onlyOwned -
   */
  getPortalContentPageListAction(parameters = {}) {
    let path = '/anonymous/content-page';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    /** set default value **/
    queryParameters['limit'] = '500';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    if (parameters['q'] !== undefined) {
      queryParameters['q'] = parameters['q'];
    }

    if (parameters['contentType'] !== undefined) {
      queryParameters['contentType'] = parameters['contentType'];
    }

    if (parameters['typeByYear'] !== undefined) {
      queryParameters['typeByYear'] = parameters['typeByYear'];
    }

    if (parameters['subType'] !== undefined) {
      queryParameters['subType'] = parameters['subType'];
    }

    if (parameters['subTypeTag'] !== undefined) {
      queryParameters['subTypeTag'] = parameters['subTypeTag'];
    }

    if (parameters['filter'] !== undefined) {
      queryParameters['filter'] = parameters['filter'];
    }

    if (parameters['sorter'] !== undefined) {
      queryParameters['sorter'] = parameters['sorter'];
    }

    /** set default value **/
    queryParameters['onlyOwned'] = 'true';

    if (parameters['onlyOwned'] !== undefined) {
      queryParameters['onlyOwned'] = parameters['onlyOwned'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Get Content page detail.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} contentPageId - ID of page
   */
  getPortalContentPageAction(contentPageId, parameters = {}) {
    let path = '/anonymous/content-page/{contentPageId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{contentPageId}', contentPageId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
}

export default new API();
