import React from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter } from 'react-router-dom';
import styled from 'styled-components';
import CookieConsent, { Cookies } from 'react-cookie-consent';
// Antd
import message from 'antd/es/message';
import Layout from 'antd/es/layout';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Spin from 'antd/es/spin';

import { enquireScreen } from 'enquire-js';

import { connect } from './Store';
import { __, translate } from './Utils';
import API from './API';
import Router from './Routes';
import StoreSynchorizer from './Routes/StoreSynchorizer';

// Layout
import LayoutFooter from './Layout/LayoutFooter';

const { Content } = Layout;

let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

const LoaderWrapper = styled.div`
  margin: 29% 0;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redirectTo: undefined,
    };
  }

  async componentDidMount() {
    // Layout
    this.fetchLayoutTemplate();
  }

  getLoggingOutContainer = () => {
    return (
      <LoaderWrapper>
        <Spin size="large" />
      </LoaderWrapper>
    );
  };

  // Layout
  fetchLayoutTemplate = (value = 'Layout') => {
    this.setState({
      onSearch: value,
      dataLayoutTemplate: [],
      loadingLayoutTemplate: true,
    });
    API.getPortalLayoutTemplateListAction({ q: value }).then((res) => {
      console.log('res', res);

      const dataLayoutTemplate = res.items.map((item) => ({
        // text: `${item.name} | ${item.description}`,
        name: item.name.translations[0].value,
        _id: item._id,
        layoutTemplateColor: item.color,
        contentLayout: item.contentLayout,
      }));

      console.log('dataLayoutTemplate', dataLayoutTemplate);

      //
      // Layout
      //
      // to Side prom props
      const { dataSourceToEditorSideVals } = dataLayoutTemplate.reduce(
        (a, i) => {
          const r = Object.assign({}, i);

          let contentLayoutDataParse = {};
          if (r.contentLayout) {
            //
            contentLayoutDataParse = { translations: [] };
            // JSON parse to all Translations
            r.contentLayout.translations.map((d) => {
              //
              if (d.lang && d.value.length > 0) {
                contentLayoutDataParse.translations.push({
                  lang: d.lang,
                  value: JSON.parse(d.value),
                });
              }
            });
          }
          if (r.name) {
            a.dataSourceToEditorSideVals.push({
              name: r.name,
              _id: r._id,
              contentLayout: contentLayoutDataParse,
            });
          }
          // if only object no push
          // a.push(r);
          return a;
        },
        {
          dataSourceToEditorSideVals: [],
        },
      );
      console.log('----dataSourceToEditorSideVals-----');
      console.log(dataSourceToEditorSideVals);

      this.setState({
        limit: res.limit,
        total: res.total,
        offset: res.offset,
        dataLayoutTemplate,
        loadingLayoutTemplate: false,
        // Layout
        contentLayout: dataSourceToEditorSideVals,
      });
    });
  };

  // Cookie Consent
  renderCookieConsent() {
    return (
      <CookieConsent
        location="bottom"
        buttonText="I understand"
        cookieName="cookieConsent"
        style={{ background: '#2B373B' }}
        buttonStyle={{ color: '#4e503b', fontSize: '13px' }}
        expires={150}
        onAccept={(acceptedByScrolling) => {
          if (acceptedByScrolling) {
            // triggered if user scrolls past threshold
            message.success('Accept was triggered by user scrolling');
          } else {
            message.success('Accept was triggered by clicking the Accept button');
          }
        }}
        /* enableDeclineButton
        onDecline={() => {
          alert('nay!');
        }} */
      >
        This website uses cookies to enhance the user experience.
      </CookieConsent>
    );
  }

  render() {
    const { loggingOut } = this.props;
    const { redirectTo, contentLayout } = this.state;

    return (
      <React.Fragment>
        {loggingOut ? (
          this.getLoggingOutContainer()
        ) : (
          <React.Fragment>
            <BrowserRouter>
              <StoreSynchorizer>
                <Wrapper>
                  <Layout style={{ position: 'relative', width: '100%' }}>
                    <Content
                      style={{
                        zIndex: 2,
                        padding: '0 0px',
                        marginTop: 0,
                        backgroundColor: '#fff',
                      }}
                    >
                      <div
                        style={{
                          background: '#fff',
                          padding: '0 0px',
                        }}
                      >
                        <Row
                          gutter={[0, 0]}
                          style={{
                            // maxWidth: '1340px',
                            width: '100%',
                            minHeight: '780px',
                            margin: '0px auto',
                          }}
                        >
                          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                            <Router redirect={redirectTo} />
                          </Col>
                        </Row>
                      </div>
                    </Content>

                    {contentLayout && contentLayout.length > 0
                      ? contentLayout.map((item, i) => {
                          const gi = i;
                          return (
                            <React.Fragment>
                              {item._id === '606c8828ef9106859239427a' ? (
                                <LayoutFooter
                                  id="Footer1_0"
                                  key="Footer1_0"
                                  dataSource={JSON.parse(
                                    translate(
                                      'EN',
                                      item.contentLayout.translations,
                                      item.contentLayout.translations[0].value,
                                    ).filter((d) => d._id === 'Footer1')[0].dataSourceStr,
                                  )}
                                  isMobile={isMobile}
                                  style={{ zIndex: 1 }}
                                />
                              ) : null}
                            </React.Fragment>
                          );
                        })
                      : null}
                  </Layout>
                </Wrapper>
              </StoreSynchorizer>
            </BrowserRouter>
          </React.Fragment>
        )}

        {/* Cookie Consent */}
        {/* {this.renderCookieConsent()} */}
      </React.Fragment>
    );
  }
}

App.propTypes = {
  loggingOut: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(App);
