import React from 'react';
import styled from 'styled-components';
// Antd
import Spin from 'antd/es/spin';

import cookie from 'react-cookies';
import { connect, actions } from '../Store';
import API from '../API';
import { __ } from '../Utils';
import cookieConfig from '../ContentConfig/cookie';

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 29% 0;
`;

class AclList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }
  async componentDidMount() {
    // await this.getAclList();
      console.log('----- aclList');
    this.setState({
      loading: false,
    });
  }
  async getAclList() {
    try {
      const aclList = await API.getUserAcl();
      actions.setAclList(aclList);
      this.setState({ loading: false });
    } catch (e) {
      this.aclNotFound();
    }
  }
  removeCookie() {
    cookie.remove(cookieConfig.name, {
      path: '/',
    });
  }
  aclNotFound() {
    this.removeCookie();
    window.location.href = '/';
    alert(__('Nepodarilo sa načítať potrebné práva.'));
  }
  render() {
    const { loading } = this.state;
    if (loading) {
      return (
        <LoaderWrapper>
          <Spin size="large" />
        </LoaderWrapper>
      );
    }
    const Component = this.props.component;
    return <Component />;
  }
}

export default connect(state => state)(AclList);
