import React from 'react';
import TweenOne from 'rc-tween-one';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import QueueAnim from 'rc-queue-anim';
import { Row, Col } from 'antd';
import { getChildrenToRender, isImg } from './utils';

// data source : ST
const FooterDataSourceST = {
  wrapper: { className: 'home-page-wrapper footer1-wrapper' },
  OverPack: { className: 'footer1', playScale: 0.2 },
  block: {
    className: 'home-page',
    gutter: 0,
    children: [
      {
        name: 'block0',
        xs: 24,
        md: 6,
        className: 'block',
        title: {
          type: 'image',
          className: 'logo',
          children: '/theme/red/images/layout/logo/footer/logo.png',
        },
        childWrapper: {
          className: 'slogan',
          children: [
            {
              name: 'content0',
              children: ' ',
            },
          ],
        },
      },
      {
        name: 'block1',
        xs: 24,
        md: 6,
        className: 'block',
        title: { children: 'Menu Title' },
        childWrapper: {
          children: [
            {
              name: 'link0',
              href: '/pages/...',
              children: 'Menu link',
            },
          ],
        },
      },
      {
        name: 'block2',
        xs: 24,
        md: 6,
        className: 'block',
        title: { children: 'Menu Title' },
        childWrapper: {
          children: [
            {
              href: '/pages/...',
              name: 'link0',
              children: 'Menu link',
            },
          ],
        },
      },
      {
        name: 'block3',
        xs: 24,
        md: 6,
        className: 'block',
        title: { children: 'Menu Title' },
        childWrapper: {
          children: [
            {
              href: '/pages/...',
              name: 'link0',
              children: 'Contact',
            },
          ],
        },
      },
    ],
  },
  copyrightWrapper: { className: 'copyright-wrapper' },
  copyrightPage: { className: 'home-page' },
  copyright: {
    className: 'copyright',
    children: '<span>© 2022 ST.</span>',
  },
};

// set dataSource
const FooterDataSource = FooterDataSourceST;

class Footer extends React.Component {
  static defaultProps = {
    className: 'footer1',
  };

  getLiChildren = (data) =>
    data.map((item, i) => {
      const { title, childWrapper, ...itemProps } = item;
      return (
        <Col key={i.toString()} {...itemProps} title={null} content={null}>
          <h2 {...title} style={{ marginBottom: '40px' }}>
            {typeof title.children === 'string' &&
            // title.children.match(isImg) &&
            title.type === 'image' ? (
              <img src={title.children} width="100%" alt="img" />
            ) : (
              title.children
            )}
          </h2>
          <div {...childWrapper}>{childWrapper.children.map(getChildrenToRender)}</div>
        </Col>
      );
    });

  render() {
    const { ...props } = this.props;
    const { dataSource } = props;
    delete props.dataSource;
    delete props.isMobile;
    const childrenToRender = this.getLiChildren(FooterDataSource.block.children);
    return (
      <div {...props} {...FooterDataSource.wrapper}>
        <OverPack {...FooterDataSource.OverPack}>
          <QueueAnim
            type="bottom"
            key="ul"
            leaveReverse
            component={Row}
            {...FooterDataSource.block}
          >
            {childrenToRender}
          </QueueAnim>
          <TweenOne
            animation={{ y: '+=30', opacity: 0, type: 'from' }}
            key="copyright"
            {...FooterDataSource.copyrightWrapper}
          >
            <div {...FooterDataSource.copyrightPage}>
              <div {...FooterDataSource.copyright}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: FooterDataSource.copyright.children,
                  }}
                />
                {/* FooterDataSource.copyright.children */}
              </div>
            </div>
          </TweenOne>
        </OverPack>
      </div>
    );
  }
}

export default Footer;
