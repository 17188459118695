export default {
  user: {},
  loggingOut: false,
  appLang: 'EN',
  year: new Date().getFullYear(),
  history: '/',
  aclList: {},
  locations: [],
  emptyLocations: false,
  settings: {},
  userHistory: [],
};
