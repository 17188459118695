import React from 'react';
import PropTypes from 'prop-types';
import { rem } from 'polished';
import styled from 'styled-components';
import debounce from 'lodash/debounce';
import cookie from 'react-cookies';
import MediaQuery from 'react-responsive';
// anim
import TweenOne from 'rc-tween-one';
// Antd
import Grid from 'antd/es/grid';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Menu from 'antd/es/menu';
import Dropdown from 'antd/es/dropdown';
import Typography from 'antd/es/typography';
import Carousel from 'antd/es/carousel';
import List from 'antd/es/list';
import Image from 'antd/es/image';
import Popconfirm from 'antd/es/popconfirm';
import Button from 'antd/es/button';
import Badge from 'antd/es/badge';
import Space from 'antd/es/space';
import Result from 'antd/es/result';
import Tabs from 'antd/es/tabs';
import Collapse from 'antd/es/collapse';
import Divider from 'antd/es/divider';
import Switch from 'antd/es/switch';
import InputNumber from 'antd/es/input-number';
import Input from 'antd/es/input';
// import Search from 'antd/es/input';
import Radio from 'antd/es/radio';
import Select from 'antd/es/select';
import Tooltip from 'antd/es/tooltip';
import Alert from 'antd/es/alert';
import Drawer from 'antd/es/drawer';
import Breadcrumb from 'antd/es/breadcrumb';
import Form from 'antd/es/form';

// icons
import {
  CaretUpOutlined,
  LoginOutlined,
  LogoutOutlined,
  UserOutlined,
  RightOutlined,
} from '@ant-design/icons';

import { connect, actions } from '../Store';
import { __, translate } from '../Utils';
import { getChildrenToRender } from './utils';
import CssThemeSwitcher from '../Components/CssThemeSwitcher';
import UseBreakpoint from '../Components/UseBreakpoint';
import { FlexBox } from '../Components/ReusableComponents';

const { Item, SubMenu } = Menu;
const { Title, Text, Paragraph } = Typography;
const { TextArea } = Input;
const { TabPane } = Tabs;
const { Panel } = Collapse;
const { Search } = Input;
const { Option } = Select;

// set Nav : ST
/* const activeNavDataST = {
  height: {
    // total : top + mainTop + bot
    total: 0 + 50 + 0, // props Nav
    //
    top: 0,
    mainTop: 50,
    bot: 0,
  },
  active: {
    top: false,
    mainTop: true,
    bot: false,
    //
    // blocks
    submenuBlock: true,
  },
  buttons: {
    top: {
      userLogin: false,
      userLogout: false,
    },
    mainTop: {
      userLogin: true,
      userLogout: true,
    },
    bot: {
      userLogin: false,
      userLogout: false,
    },
  },
  styles: {
    top: {
      background: '#2C2D2E',
    },
    mainTop: {
      color: '#eee',
      background: '#191A1B',
    },
    bot: {
      background: '#111',
    },
  },
}; */

// data source : ST
const headerDataSource = {
  headerWrapper: {
    className: 'header',
    component: 'header',
    animation: { opacity: 0, type: 'from' },
  },
  mobileMenu: {
    className: 'header-mobile-menu',
  },
  leftWrapper: {
    className: 'header-left-wrapper',
    logo: {
      className: 'header-logo',
      src: '/theme/red/images/layout/logo/nav/logo_wht.svg',
      preview: false,
      alt: 'ST-logo',
    },
    nav: {
      className: 'header-nav',
      separator: (
        <RightOutlined style={{ color: 'rgb(138, 138, 138)', position: 'relative', top: '3px' }} />
      ),
      item: {
        className: 'header-nav-item',
      },
    },
  },
  rightWrapper: {
    className: 'header-right-wrapper',
    userWrapper: {
      className: 'user-wrapper',
      userName: {
        className: 'user-name',
      },
      userAvatarWrapper: {
        className: 'user-avatar-wrapper',
        userAvatarRing: {
          className: 'user-avatar-ring',
        },
        userAvatarCover: {
          className: 'user-avatar-cover',
          src: '/theme/red/images/layout/icons/header-icons/avatar-cover.svg',
          alt: 'user avatar cover',
        },
        userAvatarBadge: {
          className: 'user-avatar-badge',
        },
        userAvatar: {
          className: 'user-avatar',
          src: '/theme/red/images/layout/icons/header-icons/avatar.png',
          alt: 'user avatar',
        },
      },
    },
    searchFormWrapper: {
      className: 'search-form-wrapper',
      searchForm: {
        className: 'search-form',
      },
      searchFormInput: {
        type: 'text',
        placeholder: 'Search',
      },
    },
    searchBtnWrapper: {
      className: 'search-btn-wrapper',
      searchBtn: {
        className: 'search-btn',
        href: '',
        icon: {
          src: '/theme/red/images/layout/icons/header-icons/search-icon.svg',
          alt: 'search button',
        },
      },
    },
    logoutBtnWrapper: {
      className: 'logout-btn-wrapper',
      logoutBtn: {
        className: 'logout-btn',
        href: '/logout',
        icon: {
          src: '/theme/red/images/layout/icons/header-icons/logout-icon.svg',
          alt: 'logout button',
        },
      },
    },
  },
};

class LayoutNav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchFormVisible: false,
    };
  }

  async componentDidMount() {
    const { location, ...props } = this.props;
    const { activeMenusData } = props;
    // console.log('----------- LayoutNav props');
    // console.log(this.props);

    console.log(location);

    // Set Menu Height
    // activeMenusData(activeNavData);
  }

  showSearchForm(e) {
    e.preventDefault();
    const { searchFormVisible } = this.state;

    if (searchFormVisible) {
      this.setState({
        searchFormVisible: false,
      });
    } else {
      this.setState({
        searchFormVisible: true,
      });
    }
  }

  search(e) {
    console.log(e.target.value);
  }

  render() {
    const { dataSource, isMobile, ...props } = this.props;
    const { history, user, match } = this.props;

    const { searchFormVisible } = this.state;

    const { leftWrapper, rightWrapper } = headerDataSource;
    const { userWrapper, searchFormWrapper, searchBtnWrapper, logoutBtnWrapper } = rightWrapper;
    const { userAvatarWrapper } = userWrapper;

    /* Set user avatar */
    userAvatarWrapper.userAvatar.src = user.avatar ? user.avatar : userAvatarWrapper.userAvatar.src;

    /* Set user name */
    const userName = user.email ? user.email : `${user.name} ${user.surname}`;

    return (
      <React.Fragment>
        <TweenOne {...headerDataSource.headerWrapper} {...props}>
          {/* HEADER LEFT SIDE */}
          <div className={leftWrapper.className}>
            <a href="/">
              <Image {...leftWrapper.logo} />
            </a>

            <Breadcrumb separator={leftWrapper.nav.separator} className={leftWrapper.nav.className}>
              <Breadcrumb.Item {...leftWrapper.nav.item}>Dashboard</Breadcrumb.Item>
              <Breadcrumb.Item {...leftWrapper.nav.item}>Create trust</Breadcrumb.Item>
            </Breadcrumb>
          </div>

          {/* HEADER RIGHT SIDE */}
          <div className={rightWrapper.className}>
            <div className={userWrapper.className}>
              <div className={userAvatarWrapper.className}>
                <span {...userAvatarWrapper.userAvatarBadge}>1</span>
                <img {...userAvatarWrapper.userAvatarCover} />
                <img {...userAvatarWrapper.userAvatar} />
                <div {...userAvatarWrapper.userAvatarRing} />
              </div>
              <a {...userWrapper.userName}>{userName}</a>
            </div>

            {/* SEARCH FORM */}
            <div
              className={
                searchFormVisible
                  ? searchFormWrapper.className
                  : `${searchFormWrapper.className} hidden`
              }
              // className={searchFormWrapper.className}
            >
              <form {...searchFormWrapper.searchForm}>
                <input {...searchFormWrapper.searchFormInput} onKeyUp={e => this.search(e)} />
              </form>
            </div>

            <div className={searchBtnWrapper.className}>
              <a
                href={searchBtnWrapper.searchBtn.href}
                className={searchBtnWrapper.searchBtn.className}
                onClick={e => this.showSearchForm(e)}
              >
                <img {...searchBtnWrapper.searchBtn.icon} />
              </a>
            </div>

            <div className={logoutBtnWrapper.className}>
              <a
                // href={logoutBtnWrapper.logoutBtn.href}
                onClick={() => actions.logout()}
                className={logoutBtnWrapper.logoutBtn.className}
              >
                <img {...logoutBtnWrapper.logoutBtn.icon} />
              </a>
            </div>

            {/* MOBILE MENU */}
            <div {...headerDataSource.mobileMenu}>
              <em />
              <em />
              <em />
            </div>
          </div>
        </TweenOne>
      </React.Fragment>
    );
  }
}

LayoutNav.propTypes = {
  history: PropTypes.objectOf({}).isRequired,
  location: PropTypes.objectOf({}).isRequired,
  match: PropTypes.objectOf({}).isRequired,
  id: PropTypes.string.isRequired,
};

LayoutNav.defaultProps = {};

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(LayoutNav);
