import React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
// Antd
import Input from 'antd/es/input';
import Button from 'antd/es/button';
// icons
import { LockOutlined, ProfileOutlined } from '@ant-design/icons';

import { __, getTokenFromProps } from '../../Utils';
import API from '../../API';
import { StyledLink } from '../../Components/ReusableComponents';

const InputWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: ${rem(10)};
  width: 100%;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const LoginWrapper = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: ${(props) => (props.height ? rem(props.height) : '100%')};
  width: 100%;
  padding: ${rem(20)};
`;

const Header = styled.div`
  margin-bottom: ${rem(10)};
`;

const HeaderImage = styled.img`
  max-width: ${rem(227)};
  ${({ theme }) => theme.media.m`
  `};
`;

const MainPart = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: white;
  width: 100%;
  border-radius: ${rem(8)};
  padding: ${rem(20)};
  max-width: ${rem(450)};
  ${({ theme }) => theme.media.m`
  min-width: ${rem(400)};
  `};
`;

const StyledFooter = styled.div`
  display: flex;
  width: 100%;
  color: #ccc;
  font-size: ${rem(12)};
  position: absolute;
  bottom: ${rem(200)};
  max-width: ${rem(600)};
`;

const StyledInput = styled(Input)`
  width: calc(100% - 22px);
`;

const ErrorInfo = styled.div`
  width: 100%;
  padding: ${rem(20)};
  max-width: ${rem(410)};
  padding-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffe6e7;
  border-radius: ${rem(7)};
  border: ${rem(1)} solid #e94141;
  font-size: ${rem(12)};
  margin-bottom: ${rem(10)};
`;

const SuccessInfo = styled.div`
  width: 100%;
  padding: ${rem(20)};
  max-width: ${rem(410)};
  padding-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #0ba14b4d;
  border-radius: ${rem(7)};
  border: ${rem(1)} solid #0ba14b;
  font-size: ${rem(12)};
  margin-bottom: ${rem(10)};
`;

const Link = styled(StyledLink)`
  margin-left: ${rem(2)};
  color: #23231f;
  font-size: ${rem(12)};
  :hover {
    text-decoration: underline;
  }
`;

class RestorePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password1: '',
      password: '',
      passwordError1: undefined,
      passwordError: undefined,
      token: 'sdsdsdsdasdsd',
      userName: '',
    };
  }

  async componentDidMount() {
    const token = getTokenFromProps(this.props);

    this.setState({
      error: false,
    });

    if (token) {
      try {
        const response = await API.oauthPortalRestorePasswordInfo(token);
        this.setState({
          token,
          userName: response.name,
        });
      } catch (e) {
        this.setState({
          error: __('The token has expired'),
        });
      }
    }
  }

  onInputChange(property, event) {
    if (property === 'password') {
      this.setState({
        password: event.target.value,
        passwordError: '',
        noSamePasswordError: undefined,
      });
    } else if (property === 'password1') {
      this.setState({
        password1: event.target.value,
        passwordError1: '',
        noSamePasswordError: undefined,
      });
    }
  }

  async onRestorePassword() {
    const { token, password } = this.state;

    this.setState({
      passwordError: undefined,
      passwordError1: undefined,
    });
    if (this.checkPasswords()) {
      try {
        await API.oauthPortalRestorePassword(token, {
          data: { password },
        });

        this.setState({
          success: __('Password successfully changed'),
        });
      } catch (e) {
        console.log(e);
        this.setState({
          error: __('The token has expired'),
        });
      }
    }
  }

  checkPasswords() {
    const { password, password1 } = this.state;
    let valid = true;

    if (password.length < 2) {
      valid = false;
      this.setState({
        passwordError: true,
      });
    }

    if (password1.length < 2) {
      valid = false;
      this.setState({
        passwordError1: true,
      });
    }

    if (password !== password1) {
      this.setState({
        passwordError: true,
        passwordError1: true,
        noSamePasswordError: true,
      });
      valid = false;
    }

    return valid;
  }

  render() {
    const {
      passwordError1,
      passwordError,
      password1,
      password,
      error,
      success,
      noSamePasswordError,
      userName,
    } = this.state;

    const { history } = this.props;

    if (error) {
      return (
        <LoginWrapper>
          <Header>
            <HeaderImage src="/theme/red/images/layout/logo/login/logo.png" />
          </Header>
          <MainPart>
            {error && <ErrorInfo>{error}</ErrorInfo>}
            <ButtonWrapper>
              <Link
                onClick={() => {
                  history.push('../');
                }}
              >
                {__('Go to login')}
              </Link>
            </ButtonWrapper>
          </MainPart>
        </LoginWrapper>
      );
    }

    if (success) {
      return (
        <LoginWrapper>
          <Header>
            <HeaderImage src="/theme/red/images/layout/logo/login/logo.png" />
          </Header>
          <MainPart>
            <SuccessInfo>{success}</SuccessInfo>

            <ButtonWrapper>
              <Link
                onClick={() => {
                  history.push('../');
                }}
              >
                {__('Go to login')}
              </Link>
            </ButtonWrapper>
          </MainPart>
        </LoginWrapper>
      );
    }

    return (
      <LoginWrapper>
        <Header>
          <HeaderImage src="/theme/red/images/layout/logo/login/logo.png" />
        </Header>
        <MainPart>
          {error && <ErrorInfo>{error}</ErrorInfo>}
          {noSamePasswordError && <ErrorInfo>{__('Passwords do not match')}</ErrorInfo>}
          <InputWrapper className="form-group">
            <StyledInput
              name="username"
              size="large"
              // long
              disabled
              value={userName}
              onChange={(e) => this.onInputChange('password1', e)}
            />
            <ProfileOutlined
              name="profile"
              style={{ position: 'relative', right: 0, color: '#ccc' }}
            />
          </InputWrapper>
          <InputWrapper>
            <StyledInput
              name="password"
              error={passwordError}
              size="large"
              // long
              type="password"
              placeholder={__('New password')}
              value={password}
              onChange={(e) => this.onInputChange('password', e)}
            />
            <LockOutlined name="lock" style={{ position: 'relative', right: 0, color: '#ccc' }} />
          </InputWrapper>
          <InputWrapper className="form-group">
            <StyledInput
              name="password1"
              error={passwordError1}
              size="large"
              // long
              placeholder={__('Repeat the password')}
              type="password"
              value={password1}
              onChange={(e) => this.onInputChange('password1', e)}
            />
            <LockOutlined name="lock" style={{ position: 'relative', right: 0, color: '#ccc' }} />
          </InputWrapper>
          <ButtonWrapper className="form-group">
            <Link
              onClick={() => {
                history.push('../');
              }}
            >
              {__('Go to login')}
            </Link>

            <Button
              type="primary"
              size="large"
              disabled={error}
              onClick={() => this.onRestorePassword()}
            >
              {__('Create a password')}
            </Button>
          </ButtonWrapper>
        </MainPart>
        <StyledFooter>{__('Copyright ©')}</StyledFooter>
      </LoginWrapper>
    );
  }
}

RestorePassword.propTypes = {};

RestorePassword.contextTypes = {};

export default RestorePassword;
